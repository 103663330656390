import React, { useContext, useEffect, useState } from 'react';
import AuthContext from '../../AuthProvider';
import WorkerProfileEducationUpdate from './WorkerProfileEducationUpdate';
import WorkerProfileEducationAdd from './WorkerProfileEducationAdd';
import LoadingSpinner from '../../../../common/components/LoadingSpinner';

const WorkerProfileEducationsList = () => {
  const [educations, setEducations] = useState([]);
  const [updatingEducation, setUpdatingEducation] = useState(null);
  const [showAddEducationForm, setShowAddEducationForm] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [loading, setLoading] = useState(true);
  const { userData, authTokens } = useContext(AuthContext); 

  useEffect(() => {
    const fetchEducations = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/worker-profile/${userData.profile_id}/educations/get-all/`, {
          headers: {
            'Authorization': `Bearer ${authTokens?.access}`,
          },
        });
        if (response.ok) {
          const data = await response.json();
          setEducations(data);
          setDataLoaded(true);
        } else {
          console.error('Failed to fetch worker educations');
        }
      } catch (error) {
        console.error('Error fetching worker educations:', error);
      } finally {
        setLoading(false);
      }
    };
    fetchEducations();
    return () => {
      setEducations([]);
    };
  }, [userData.profile_id, authTokens]);

  const handleDeleteEducation = async (educationId) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/worker-profile/education/${educationId}/delete/`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${authTokens?.access}`, 
        },
      });
      if (response.ok) {
        setEducations(educations.filter(education => education.id !== educationId));
      } else {
        console.error('Failed to delete worker education');
      }
    } catch (error) {
      console.error('Error deleting worker education:', error);
    }
  };

  const handleUpdateEducation = (education) => {
    setUpdatingEducation(education);
  };

  const handleCancelUpdate = () => {
    setUpdatingEducation(null);
  };

  const handleToggleAddEducationForm = () => {
    if (educations.length < 3) {
      setShowAddEducationForm(!showAddEducationForm);
    }
  };

  const onUpdate = (updatedEducation) => {
    const updatedEducations = educations.map((edu) => {
      if (edu.id === updatedEducation.id) {
        return updatedEducation;
      }
      return edu;
    });
    setEducations(updatedEducations);
    setUpdatingEducation(null);
  };

  const onAdd = (newEducation) => {
    setEducations([...educations, newEducation]);
    setShowAddEducationForm(false);
  };

  return (
    <div className="profile-edit-section">
      <h2 className="profile-edit-section-title">Educación</h2>
      <div className="profile-edit-section-content">
        {loading ? (
          <LoadingSpinner />
        ) : !dataLoaded ? (
          <p>Error al obtener datos.</p>
        ) : (
          <>
            {educations.length === 0 ? (
              <p className="empty-message">Aún no has agregado ninguna educación.</p>
            ) : (
              <ul className="education-list">
                {educations.map(education => (
                  <li key={education.id} className="education-item">
                    <p><strong>Título:</strong> {education.title}</p>
                    <p><strong>Institución:</strong> {education.place}</p>
                    <p><strong>Estado:</strong> {education.status_display}</p>
                    <p><strong>Disciplina:</strong> {education.type_display}</p>

                    <div className="buttons-container">
                      <button className="primary-btn" onClick={() => handleUpdateEducation(education)}>Modificar</button>
                      <button className="secondary-btn" onClick={() => handleDeleteEducation(education.id)}>Eliminar</button>
                    </div>
                  </li>
                ))}
              </ul>
            )}
          </>
        )}
      </div>
      {updatingEducation && <WorkerProfileEducationUpdate education={updatingEducation} onCancel={handleCancelUpdate} onUpdate={onUpdate} />}
      {showAddEducationForm && <WorkerProfileEducationAdd onClose={() => setShowAddEducationForm(false)} onAdd={onAdd} />}
      {dataLoaded && <button className="primary-btn" onClick={handleToggleAddEducationForm} disabled={educations.length >= 3}>Agregar</button>}
    </div>
  );
};

export default WorkerProfileEducationsList;
