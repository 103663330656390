import React, { useState, useEffect, useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import AuthContext from '../components/AuthProvider';

const ResetPasswordConfirm = () => {
  const { getCookie } = useContext(AuthContext);
  const { uidb64, token } = useParams();
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [message, setMessage] = useState('');
  const [tokenValid, setTokenValid] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const checkToken = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/reset/${uidb64}/${token}/`, {
          method: 'GET',
        });
        const result = await response.json();
        setTokenValid(result.success);
        if (!result.success) {
          setMessage(result.message);
        }
      } catch (error) {
        setMessage('An error occurred. Please try again.');
      }
    };

    checkToken();
  }, [uidb64, token]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    let errorMessage = '';

    const passwordRegex = /^(?=.*\d).{8,}$/;

    if (!password || !confirmPassword) {
      errorMessage = 'Los campos son obligatorios';
    } else if (!passwordRegex.test(password)) {
      errorMessage = 'La contraseña debe tener al menos 8 caracteres y contener al menos un número.';
    } else if (password !== confirmPassword) {
      errorMessage = 'Las contraseñas no coinciden';
    } else {
      try {
        const csrftoken = getCookie('csrftoken');
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/reset/${uidb64}/${token}/`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': csrftoken
          },
          credentials: 'include',
          body: JSON.stringify({ password }),
        });
        const result = await response.json();
        setMessage(result.message);
        if (result.success) {
          setTimeout(() => {
            navigate('/');
          }, 4000);
        }
      } catch (error) {
        setMessage('An error occurred. Please try again.');
      }
      return;
    }

    setMessage(errorMessage);
  };

  const handleCancel = () => {
    navigate('/');
  };

  return (
    <div className="reset-password-page-container">
      <div className="popup-background">
        <div className="popup">
          <div className="popup-content">
            <div className="popup-intro">
              <h2>Reestablecer contraseña</h2>
              <div className="popup-intro-description">
                <p>Ingresa la nueva contraseña para tu cuenta.</p>
              </div>
            </div>
            <div className="popup-auth-message">
              <p className="error-message">
                {!tokenValid ? 'Token no válido' : (message || '\u00A0')}
              </p>
            </div>

            <form onSubmit={handleSubmit} className="popup-form">
              <div className="popup-form-rows">
                <div className="form-group">
                  <input
                    type="password"
                    value={password}
                    placeholder="Nueva contraseña:"
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>
                <div className="form-group">
                  <input
                    type="password"
                    value={confirmPassword}
                    placeholder="Repite la nueva contraseña:"
                    onChange={(e) => setConfirmPassword(e.target.value)}
                  />
                </div>
              </div>
              <div className="buttons-container">
                <button className="primary-btn" type="submit">Guardar</button>
                <button className="secondary-btn" type="button" onClick={handleCancel}>
                  Cancelar
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPasswordConfirm;

