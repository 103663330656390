import React, { useState, useEffect } from 'react';

const InactivityWarning = ({ onRefresh }) => {
    const [showWarning, setShowWarning] = useState(false);
    const [activityTimeout, setActivityTimeout] = useState(null);

    useEffect(() => {
        const handleUserActivity = () => {
            if (activityTimeout) {
                clearTimeout(activityTimeout);
            }
            const newTimeout = setTimeout(() => {
                setShowWarning(true);
            }, 1000 * 60 * 15);
            setActivityTimeout(newTimeout);
        };

        window.addEventListener('mousemove', handleUserActivity);
        window.addEventListener('keydown', handleUserActivity);

        const initialTimeout = setTimeout(() => {
            setShowWarning(true);
        }, 1000 * 60 * 15);
        
        return () => {
            window.removeEventListener('mousemove', handleUserActivity);
            window.removeEventListener('keydown', handleUserActivity);
            clearTimeout(initialTimeout);
            if (activityTimeout) {
                clearTimeout(activityTimeout);
            }
        };
    }, [activityTimeout]);

    return showWarning ? (
        <div className="inactivity-warning">
            <div className="popup-background">
                <div className="popup">
                    <div className="popup-content">
                        <div className="popup-intro">
                            <h3 className="popup-title">¡Bienvenido/a de nuevo!</h3>
                            <div className="popup-intro-description">
                                <p>Haz clic en "Recargar Página" para continuar navegando en HappyHome.</p>
                            </div>
                        </div>
                        <div className="buttons-container">
                            <button className="primary-btn" onClick={onRefresh}>Recargar Página</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    ) : null;
};

export default InactivityWarning;
