import React from 'react';
import { Helmet } from 'react-helmet';
import HeroSectionImage from '../../images/HomePage/HeroSection/HappyHome_HeroSectionImage.png'

const PrivacyPolicyPage = () => {
  return (
    <div className="terms-page">
      <Helmet>
        <title>HappyHome - Política de privacidad</title>
        <meta name="description" content="La forma más fácil, rápida y económica de encontrar ayuda integral para tu hogar en Argentina. Ofrecemos servicios de empleada doméstica, niñeras, cuidadores de personas mayores, cuidadores de mascotas y cocineros." />
        <meta name="keywords" content="ayuda en el hogar, empleada doméstica, niñera, cuidador de personas mayores, cuidador de mascotas, cocinera" />
        <meta property="og:title" content="HappyHome - Política de privacidad" />
        <meta property="og:description" content="La forma más fácil, rápida y económica de encontrar ayuda integral para tu hogar." />
        <meta property="og:image" content={HeroSectionImage} />
        <meta property="og:url" content="http://www.happyhome.com.ar" />
      </Helmet>

      <h2>Política de Privacidad</h2>
      
      <h3>Recopilación de Información:</h3>
      <p>
        En HappyHome, respetamos y valoramos tu privacidad. Esta Política de Privacidad describe cómo recopilamos, utilizamos y protegemos la información personal que nos proporcionas.
      </p>
      
      <h3>Uso de la Información:</h3>
      <p>
        La información que recopilamos puede ser utilizada para personalizar tu experiencia, procesar transacciones, mejorar nuestros servicios y enviarte información relevante.
      </p>
      
      <h3>Protección de la Información:</h3>
      <p>
        Tomamos medidas de seguridad razonables para proteger tu información personal contra pérdida, uso indebido y acceso no autorizado.
      </p>
      
      <h3>Divulgación a Terceros:</h3>
      <p>
        No vendemos, intercambiamos ni transferimos tu información personal a terceros sin tu consentimiento, excepto cuando sea necesario para cumplir con la ley o proteger nuestros derechos, propiedad o seguridad.
      </p>
      
      <h3>Cookies:</h3>
      <p>
        Utilizamos cookies para mejorar la experiencia del usuario en nuestro sitio web. Al utilizar nuestro sitio, aceptas el uso de cookies de acuerdo con nuestra política.
      </p>
      
      <h3>Consentimiento:</h3>
      <p>
        Al utilizar nuestro sitio web, aceptas nuestra Política de Privacidad y nos autorizas a recopilar, utilizar y proteger tu información personal de acuerdo con lo establecido en esta política.
      </p>
      
      <h3>Contacto:</h3>
      <p>
        Si tienes alguna pregunta o inquietud sobre nuestra Política de Privacidad, por favor contáctanos a través de info@happyhome.com.ar.
      </p>
    </div>
  );
};

export default PrivacyPolicyPage;
