import React, { useState, useEffect, useContext } from 'react';
import AuthContext from '../AuthProvider';

const UserAccountDetailsUpdate = ({ userData, onUpdate, onClose }) => {
  const { authTokens } = useContext(AuthContext);

  const [formData, setFormData] = useState({
    first_name: userData ? userData.first_name : '',
    last_name: userData ? userData.last_name : '',
    email: userData ? userData.email : '',
  });
  const [errors, setErrors] = useState({});
  const [isModified, setIsModified] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  useEffect(() => {
    const checkIfModified = () => {
      return JSON.stringify(formData) !== JSON.stringify({
        first_name: userData.first_name,
        last_name: userData.last_name,
        email: userData.email,
      });
    };
    setIsModified(checkIfModified());
  }, [formData, userData]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formErrors = {};

    if (!formData.first_name.trim()) {
      formErrors.first_name = 'El nombre es obligatorio.';
    }
    if (!formData.last_name.trim()) {
      formErrors.last_name = 'El apellido es obligatorio.';
    }
    if (formData.first_name.trim().length > 30) {
      formErrors.first_name = 'El nombre debe tener menos de 30 caracteres.';
    }
    if (formData.first_name.trim().length < 2) {
      formErrors.first_name = 'El nombre debe tener más de 2 caracteres.';
    }
    if (formData.last_name.trim().length > 30) {
      formErrors.last_name = 'El apellido debe tener menos de 30 caracteres.';
    }
    if (formData.last_name.trim().length < 2) {
      formErrors.last_name = 'El apellido debe tener más de 2 caracteres.';
    }
    if (formData.email.trim().length > 150) {
      formErrors.email = 'El correo electrónico debe tener menos de 150 caracteres.';
    }

    setErrors(formErrors);
    
    if (Object.keys(formErrors).length > 0) {
      return;
    }

    try {
      const { email, ...updateData } = formData;
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/user/${userData.id}/update/`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${authTokens?.access}`,
        },
        body: JSON.stringify(updateData),
      });

      if (response.ok) {
        const data = await response.json();
        onUpdate(data);
        onClose();
      } else {
        console.error('Error al actualizar los datos:', response.statusText);
      }
    } catch (error) {
      console.error('Error al enviar la solicitud:', error);
    }
  };

  return (
    <div className="popup-background">
      <div className="popup">
        <div className="popup-content">
          <div className="popup-intro">
            <h3 className="popup-title">Modificar datos de la cuenta</h3>
            <div className="popup-intro-description">
              <p>Tu apellido y correo electrónico no se muestra en el perfil</p>
            </div>
          </div>
          <form className="popup-form" onSubmit={handleSubmit}>
            <div className="popup-form-rows">
              <div className="popup-form-double-col">
                <div className={`popup-basic-form-row ${errors.first_name ? 'missing-field' : ''}`}>
                  <label>Nombre</label>
                  <input type="text" name="first_name" value={formData.first_name} onChange={handleChange} />
                  <p className="error-message">{errors.first_name || '\u00A0'}</p>
                </div>
                <div className={`popup-basic-form-row ${errors.last_name ? 'missing-field' : ''}`}>
                  <label>Apellido (Privado)</label>
                  <input type="text" name="last_name" value={formData.last_name} onChange={handleChange} />
                  <p className="error-message">{errors.last_name || '\u00A0'}</p>
                </div>
              </div>
              <div className={`popup-basic-form-row ${errors.email ? 'missing-field' : ''}`}>
                <label>Correo electrónico (No se puede cambiar)</label>
                <input type="email" name="email" value={formData.email} readOnly disabled />
                <p className="error-message">{errors.email || '\u00A0'}</p>
              </div>
              <div className="buttons-container">
                <button type="submit" className="primary-btn" disabled={!isModified}>
                  Guardar cambios
                </button>
                <button type="button" className="secondary-btn" onClick={onClose}>Cancelar</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default UserAccountDetailsUpdate;
