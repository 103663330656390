import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import AuthContext from '../../../base/components/AuthProvider';

const ParentProfileStartConversationView = ({ parentId, setSignUpVisible }) => {
  const { userData, authTokens } = useContext(AuthContext);
  const navigate = useNavigate();

  const handleStartConversation = async () => {
    if (userData) {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/chat/conversation/start-chat/`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authTokens.access}`,
          },
          body: JSON.stringify({ workerId: userData.profile_id, parentId: parentId }),
        });

        if (response.ok) {
          const newConversation = await response.json();
          navigate(`/conversations/chat/${newConversation.id}`);
        } else {
          console.error('Error al iniciar la conversación');
        }
      } catch (error) {
        console.error('Error al iniciar la conversación:', error);
      }
    } else {
      setSignUpVisible(true);
    }
  };

  if (userData && userData.profile_type === 'Parent') {
    return null;
  }

  return (
    <div className="start-conversation-container">
      <button className="primary-btn" onClick={handleStartConversation}>
        Chatear
      </button>
    </div>
  );
};

export default ParentProfileStartConversationView;
