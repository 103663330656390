import React from 'react';

const JobOfferDescriptionAdd = ({ description, setDescription, error }) => {

  const handleChange = (e) => {
    const { value } = e.target;
    setDescription(value);
  };

  return (
    <div className="edit-job-offer-section">
      <div className="edit-job-offer-intro">
        <h3 className="edit-job-offer-title">Presenta tu hogar</h3>
        <div className="edit-job-offer-description">
          <p>Compartí información sobre tu hogar, tu familia y las responsabilidades del trabajo.</p>
        </div>
      </div>

      <div className="popup-form-rows">
        <textarea className="popup-select-form-row"placeholder="¿A quién debería cuidar la persona contratada? ¿Qué cualidades o habilidades buscas en el candidato?..." name="description" value={description} maxLength="500" onChange={handleChange}/>
        <p className="error-message">{error || '\u00A0'}</p>
      </div>
    </div>
  );
};

export default JobOfferDescriptionAdd;

