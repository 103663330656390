import React, { useState, useContext, useEffect, useCallback } from 'react';
import AuthContext from '../../AuthProvider';

const WorkerProfileEducationAdd = ({ onClose, onAdd }) => {
  const { userData, authTokens } = useContext(AuthContext);

  const [formData, setFormData] = useState({
    title: '',
    place: '',
    status: '',
    type: ''
  });

  const [formSubmitted, setFormSubmitted] = useState(false);
  const [errors, setErrors] = useState({});
  const [statusOptions, setStatusOptions] = useState([]);
  const [typeOptions, setTypeOptions] = useState([]);
  const [isFormValid, setIsFormValid] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const statusResponse = await fetch(`${process.env.REACT_APP_API_BASE_URL}/worker-profile/education/get-status-choices/`);
        if (statusResponse.ok) {
          const statusData = await statusResponse.json();
          setStatusOptions(statusData.status_choices);
        } else {
          console.error('Failed to fetch status options');
        }

        const typeResponse = await fetch(`${process.env.REACT_APP_API_BASE_URL}/worker-profile/education/get-type-choices/`);
        if (typeResponse.ok) {
          const typeData = await typeResponse.json();
          setTypeOptions(typeData.type_choices);
        } else {
          console.error('Failed to fetch type options');
        }
      } catch (error) {
        console.error('Error fetching options:', error);
      }
    };
    fetchData();
  }, []);

  const validate = useCallback(() => {
    const newErrors = {};
    const requiredFields = ['title', 'place', 'status', 'type'];

    for (const field of requiredFields) {
      const value = formData[field].trim();
      if (!value) {
        newErrors[field] = 'Este campo es obligatorio';
      } else {
        switch (field) {
          case 'title':
            if (value.length < 3) {
              newErrors[field] = 'El título debe tener al menos 3 caracteres';
            } else if (value.length > 80) {
              newErrors[field] = 'El título debe tener menos de 80 caracteres';
            }
            break;
          case 'place':
            if (value.length < 2) {
              newErrors[field] = 'El lugar debe tener al menos 2 caracteres';
            } else if (value.length > 50) {
              newErrors[field] = 'El lugar debe tener menos de 50 caracteres';
            }
            break;
          default:
            break;
        }
      }
    }
    return newErrors;
  }, [formData]);

  useEffect(() => {
    const newErrors = validate();
    setIsFormValid(Object.keys(newErrors).length === 0);
  }, [formData, validate]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setFormSubmitted(true);

    const newErrors = validate();

    if (Object.keys(newErrors).length === 0) {
      try {
        const finalFormData = { ...formData };
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/worker-profile/${userData.profile_id}/education/add/`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authTokens?.access}`,
          },
          body: JSON.stringify(finalFormData),
        });

        if (response.ok) {
          const data = await response.json();
          onAdd(data);
          onClose();
        } else {
          const responseData = await response.json();
          setErrors(responseData);
        }
      } catch (error) {
        console.error('Error al agregar educación:', error.message);
      }
    } else {
      setErrors(newErrors);
    }
  };

  return (
    <div className="popup-background">
      <div className="popup">
        <div className="popup-content">
          <div className="popup-intro">
            <h3 className="popup-title">Agregar educación</h3>
            <div className="popup-intro-description">
              <p>Contanos si estudiaste algo o lo estás haciendo actualmente</p>
            </div>
          </div>

          <form className="popup-form" onSubmit={handleSubmit}>
            <div className="popup-form-rows">
              <div className="popup-form-double-col">
                <div className={`popup-basic-form-row ${formSubmitted && errors.title ? 'missing-field' : ''}`}>
                  <label>Título:</label>
                  <input type="text" name="title" value={formData.title} onChange={handleChange} />
                  <p className="error-message">{errors.title || '\u00A0'}</p>
                </div>

                <div className={`popup-basic-form-row ${formSubmitted && errors.place ? 'missing-field' : ''}`}>
                  <label>Institución:</label>
                  <input type="text" name="place" value={formData.place} onChange={handleChange} />
                  <p className="error-message">{errors.place || '\u00A0'}</p>
                </div>
              </div>

              <div className="popup-form-double-col">
                <div className={`popup-basic-form-row ${formSubmitted && errors.status ? 'missing-field' : ''}`}>
                  <label>Estado:</label>
                  <select name="status" value={formData.status} onChange={handleChange}>
                    <option value="">Seleccionar estado</option>
                    {statusOptions.map(option => (
                      <option key={option[0]} value={option[0]}>{option[1]}</option>
                    ))}
                  </select>
                  <p className="error-message">{errors.status || '\u00A0'}</p>
                </div>

                <div className={`popup-basic-form-row ${formSubmitted && errors.type ? 'missing-field' : ''}`}>
                  <label>Disciplina:</label>
                  <select name="type" value={formData.type} onChange={handleChange}>
                    <option value="">Seleccionar disciplina</option>
                    {Object.entries(typeOptions).map(([value, label]) => (
                      <option key={value} value={value}>{label}</option>
                    ))}
                  </select>
                  <p className="error-message">{errors.type || '\u00A0'}</p>
                </div>
              </div>
            </div>
            <div className="buttons-container">
              <button className="primary-btn" type="submit" disabled={!isFormValid}>Guardar</button>
              <button className="secondary-btn" onClick={onClose}>Cancelar</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default WorkerProfileEducationAdd;
