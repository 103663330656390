import React, { useState, useEffect, useContext } from 'react';
import AuthContext from '../../AuthProvider';
import WorkerProfileInfoUpdate from './WorkerProfileInfoUpdate';
import LoadingSpinner from '../../../../common/components/LoadingSpinner';

const WorkerProfileInfo = () => {
  const { authTokens, userData } = useContext(AuthContext);
  const [workerInfo, setWorkerInfo] = useState(null);
  const [isUpdating, setIsUpdating] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const isProfileHidden = workerInfo?.user?.profile_visibility === false;
  
  useEffect(() => {
    const fetchWorkerInfo = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/worker-profile/${userData.profile_id}/get-info/`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${authTokens.access}`,
          },
        });

        if (response.ok) {
          const workerInfo = await response.json();
          setWorkerInfo(workerInfo);
        } else {
          console.error('Failed to fetch worker profile:', response.statusText);
        }
      } catch (error) {
        console.error('Error fetching worker profile:', error.message);
      } finally {
        setDataLoaded(true);
      }
    };

    fetchWorkerInfo();
  }, [authTokens.access, userData.profile_id]);

  const handleUpdateData = () => {
    setIsUpdating(true);
  };

  const handleProfileVisibility = async () => {
    if (workerInfo?.user?.profile_visibility !== undefined) {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/worker-profile/${userData.user_id}/hide-profile/`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${authTokens.access}`,
          },
        });

        if (response.ok) {
          const result = await response.json();
          setWorkerInfo(prevInfo => ({
            ...prevInfo,
            user: {
              ...prevInfo.user,
              profile_visibility: result.profile_visibility,
            }
          }));
          window.location.reload();
        } else {
          console.error('Error al cambiar la visibilidad del perfil:', response.statusText);
        }
      } catch (error) {
        console.error('Error al cambiar la visibilidad del perfil:', error.message);
      }
    }
  };

  const onUpdate = async (updatedData) => {
    setWorkerInfo(updatedData);
    setIsUpdating(false);
  };



  return (
    <div className="profile-edit-section">
      <h2 className="profile-edit-section-title">Información personal</h2>
      <div className="profile-edit-section-content">
        {!dataLoaded ? (
          <LoadingSpinner />
        ) : (
          <div className="profile-edit-section-info">
            {workerInfo ? (
              <>
                <p><strong>Nacimiento:</strong> {workerInfo.date_of_birth || 'Sin asignar'}</p>
                {workerInfo.location ? (
                  <>
                    <p><strong>Barrio:</strong> {workerInfo.location.neighborhood || 'Sin asignar'}</p>
                    <p><strong>Partido:</strong> {workerInfo.location.municipality?.name || 'Sin asignar'}</p>
                    <p><strong>Provincia:</strong> {workerInfo.location.province?.name || 'Sin asignar'}</p>
                  </>
                ) : (
                  <>
                    <p><strong>Barrio:</strong> Sin asignar</p>
                    <p><strong>Partido:</strong> Sin asignar</p>
                    <p><strong>Provincia:</strong> Sin asignar</p>
                  </>
                )}
                <p><strong>Género:</strong> {workerInfo.gender_display || 'Sin asignar'}</p>
                <p><strong>Nacionalidad:</strong> {workerInfo.nationality || 'Sin asignar'}</p>
              </>
            ) : (
              <p>Error al obtener datos.</p>
            )}
          </div>
        )}
      </div>
      <div className="buttons-container">
        {workerInfo && <button className="primary-btn" onClick={handleUpdateData}>Modificar</button>}
        <button 
          className="secondary-btn" 
          onClick={handleProfileVisibility} 
          disabled={isProfileHidden}
        >
          Ocultar perfil
        </button>
      </div>
      {isUpdating && (
        <WorkerProfileInfoUpdate
          workerInfo={workerInfo}
          onCancel={() => setIsUpdating(false)}
          onUpdate={onUpdate}
        />
      )}
    </div>
  );
};

export default WorkerProfileInfo;
