import React, { useState, useContext } from 'react';
import AuthContext from './AuthProvider';

const ResendVerificationButton = ({ email }) => {
    const { getCookie } = useContext(AuthContext);
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState('');
    const [disabled, setDisabled] = useState(false);

    const handleResendVerification = async () => {
        setLoading(true);
        setMessage('');
        try {
            const csrftoken = getCookie('csrftoken');
            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/resend-verification-email/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRFToken': csrftoken,
                },
                body: JSON.stringify({ email }),
            });

            const data = await response.json();
            if (response.ok) {
                setMessage('Enviamos un email a su correo electrónico con un enlace para verificar su cuenta. Si aun no lo recibiste, porfavor espera unos minutos y vuelve a intentar.');
                setDisabled(true);

                setTimeout(() => {
                    setDisabled(false);
                    setMessage('');
                }, 2 * 60 * 1000);
            } else {
                setMessage(data.error || 'Ocurrió un error al enviar el correo.');
            }
        } catch (error) {
            setMessage('Ocurrió un error: ' + error.message);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="verify-email-container">
            <span 
                className="verify-email" 
                onClick={!loading && !disabled ? handleResendVerification : null} 
                role="button" 
                style={{ 
                    cursor: loading || disabled ? 'default' : 'pointer', 
                    color: loading || disabled ? '#E8AAC9' : '#26A282', 
                    textDecoration: loading || disabled ? 'none' : 'underline' 
                }}
                aria-disabled={loading || disabled}
            >
                {loading ? 'Cargando...' : disabled ? 'Email enviado. Podras reintentar en 2 minutos.' : 'Verificar email'}
            </span>
            <div className="verify-email-message">
                {message && <p>{message}</p>}
            </div>

        </div>
    );
};

export default ResendVerificationButton;
