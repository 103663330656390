import React, { useState, useEffect, useContext } from 'react';
import checkIcon from '../../../../../../images/Vectors/HappyHome_CheckIcon.svg';
import crossIcon from '../../../../../../images/Vectors/HappyHome_CrossIcon.svg';
import AuthContext from '../../../../AuthProvider';

const JobOfferShiftsUpdate = ({ initialWorkShifts, onUpdate }) => {
  const { authTokens } = useContext(AuthContext);
  const [allShifts, setAllShifts] = useState([]);
  const [selectedCells, setSelectedCells] = useState([]);
  const daysOfWeek = ['lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado', 'domingo'];
  const shiftsOfDay = ['mañana', 'tarde', 'noche'];

  useEffect(() => {
    const fetchAllShifts = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/shifts/get-choices/`, {
          headers: {
            'Authorization': `Bearer ${authTokens.access}`,
          },
        });
        if (response.ok) {
          const data = await response.json();
          setAllShifts(data);
        } else {
          console.error('Error fetching all shifts:', response.statusText);
        }
      } catch (error) {
        console.error('Error fetching all shifts:', error.message);
      }
    };
    fetchAllShifts();
  }, [authTokens]);

  useEffect(() => {
    setSelectedCells(initialWorkShifts);
  }, [initialWorkShifts]);

  const isCellSelected = (shiftObject) => {
    return selectedCells.some(cell => cell.id === shiftObject.id);
  };

  const handleCellClick = (shiftObject) => {
    setSelectedCells(prevSelectedCells => {
      const isSelected = isCellSelected(shiftObject);
      const updatedCells = isSelected 
        ? prevSelectedCells.filter(cell => cell.id !== shiftObject.id)
        : [...prevSelectedCells, shiftObject];
      onUpdate({ work_shifts: updatedCells });
      return updatedCells;
    });
  };

  return (
    <div className="edit-job-offer-section">
      <div className="edit-job-offer-intro">
        <h3 className="edit-job-offer-title">Disponibilidad</h3>
        <div className="edit-job-offer-description">
          <p>¿En qué días y horarios debería estar disponible?</p>
        </div>
      </div>
      <div className="worker-profile-shift-update">
        <div className="update-calendar-container">
          <table className="update-calendar">
            <thead>
              <tr>
                <th className="update-calendar-col"></th>
                {shiftsOfDay.map(shift => (
                  <th className="update-calendar-col" key={shift}>{shift}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              <tr className="spacer-row"></tr>
              {daysOfWeek.map(day => (
                <tr className="update-calendar-tr" key={day}>
                  <td className="update-calendar-row-day">{day.charAt(0).toUpperCase() + day.slice(1)}</td>
                  {shiftsOfDay.map(shift => {
                    const shiftObject = allShifts.find(s => s.day === day && s.shift === shift);
                    if (!shiftObject) return <td key={`${day}-${shift}`}>N/A</td>;
                    const id = shiftObject.id;
                    return (
                      <td className="update-calendar-row" key={id} onClick={() => handleCellClick(shiftObject)}>
                        <div className="update-calendar-row-cell">
                          <div className="update-calendar-row-icon"> 
                            {isCellSelected(shiftObject) ? (
                              <img src={checkIcon} alt="Check Icon" className="icon-svg" />
                            ) : (
                              <img src={crossIcon} alt="Cross Icon" className="icon-svg" />
                            )}
                          </div>
                        </div>
                      </td>
                    );
                  })}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default JobOfferShiftsUpdate;
