import React, { useState, useEffect } from 'react';
import checkIcon from '../../../images/Vectors/HappyHome_CheckIcon.svg';
import crossIcon from '../../../images/Vectors/HappyHome_CrossIcon.svg';

const ServiceFilter = ({ services, handleServiceChange, clearFilter }) => {
  const [selectedServices, setSelectedServices] = useState([]);

  useEffect(() => {
    if (clearFilter) {
      setSelectedServices([]);
    }
  }, [clearFilter]);

  const handleChange = (value) => {
    const isSelected = selectedServices.includes(value);
    handleServiceChange(!isSelected, value);
    setSelectedServices(prev => {
      if (isSelected) {
        return prev.filter(service => service !== value);
      } else {
        return [...prev, value];
      }
    });
  };

  return (
    <div className="filter-container">
      {services.map(service => (
        <div key={service.id} className="filter-option" onClick={() => handleChange(service.title_display)}>
          <div className="filter-option-label">
            <label>{service.title_display}</label>
          </div>
          <div className="filter-option-icon">
            <img
              src={selectedServices.includes(service.title_display) ? checkIcon : crossIcon}
              alt={selectedServices.includes(service.title_display) ? 'Check Icon' : 'Cross Icon'}
              className="icon-svg"
            />
          </div>
        </div>
      ))}
    </div>
  );
};

export default ServiceFilter;
