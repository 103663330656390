import React, { useState, useContext, useEffect } from 'react';
import AuthContext from '../../AuthProvider';

const WorkerProfileInfoUpdate = ({ workerInfo, onCancel, onUpdate }) => {
  const { authTokens, userData } = useContext(AuthContext);
  const [formData, setFormData] = useState({
    date_of_birth: workerInfo?.date_of_birth || '',
    neighborhood: workerInfo?.location?.neighborhood || '',
    gender: workerInfo?.gender || '',
    nationality: workerInfo?.nationality || '',
    municipality: workerInfo?.location?.municipality?.id || '',
    province: workerInfo?.location?.province?.id || '',
  });
  const [errors, setErrors] = useState({});
  const [provinces, setProvinces] = useState([]);
  const [municipalities, setMunicipalities] = useState([]);
  const [filteredMunicipalities, setFilteredMunicipalities] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isModified, setIsModified] = useState(false);

  useEffect(() => {
    const fetchProvincesAndMunicipalities = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/provinces-and-municipalities/`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${authTokens.access}`,
          },
        });
        if (response.ok) {
          const { provinces, municipalities } = await response.json();
          setProvinces(provinces);
          setMunicipalities(municipalities);
          setFilteredMunicipalities(municipalities);
          setLoading(false);
        } else {
          console.error('Failed to fetch provinces and municipalities:', response.statusText);
          setLoading(false);
        }
      } catch (error) {
        console.error('Error fetching provinces and municipalities:', error.message);
        setLoading(false);
      }
    };
    fetchProvincesAndMunicipalities();
  }, [authTokens.access]);

  useEffect(() => {
    if (formData.province) {
      const filteredMunicipalities = municipalities.filter(municipality => municipality.province.id === formData.province);
      setFilteredMunicipalities(filteredMunicipalities);
    }
  }, [formData.province, municipalities]);

  useEffect(() => {
    const isModified = JSON.stringify(formData) !== JSON.stringify({
      date_of_birth: workerInfo.date_of_birth || '',
      neighborhood: workerInfo.location?.neighborhood || '',
      gender: workerInfo.gender || '',
      nationality: workerInfo.nationality || '',
      municipality: workerInfo.location?.municipality?.id || '',
      province: workerInfo.location?.province?.id || '',
    });
    setIsModified(isModified);
  }, [formData, workerInfo]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    const parsedValue = name === 'province' ? parseInt(value, 10) : value;
    setFormData((prevData) => ({
      ...prevData,
      [name]: parsedValue,
      ...(name === 'province' && { municipality: '' }),
    }));
  };

  const handleSave = async () => {
    const formErrors = {};
    const currentDate = new Date();
    const birthDate = new Date(formData.date_of_birth);
    const age = currentDate.getFullYear() - birthDate.getFullYear();
    const isUnderAge = age < 18;
    const isOverAge = age > 120;
    if (!formData.date_of_birth.trim()) {
      formErrors.date_of_birth = 'La fecha de nacimiento es obligatoria.';
    } else if (isUnderAge) {
      formErrors.date_of_birth = 'Debe ser mayor de 18 años.';
    } else if (isOverAge) {
      formErrors.date_of_birth = 'Debe ser menor de 120 años.';
    }
    if (!formData.date_of_birth.trim()) {
      formErrors.date_of_birth = 'La fecha de nacimiento es obligatoria.';
    }
    if (!formData.neighborhood.trim()) {
      formErrors.neighborhood = 'El barrio es obligatorio.';
    }
    if (!formData.province) {
      formErrors.province = 'La provincia es obligatoria.';
    }
    if (!formData.municipality) {
      formErrors.municipality = 'El partido es obligatorio.';
    }
    if (!formData.gender) {
      formErrors.gender = 'El género es obligatorio.';
    }
    if (!formData.nationality.trim()) {
      formErrors.nationality = 'La nacionalidad es obligatoria.';
    }
    if (formData.neighborhood.trim().length > 100) {
      formErrors.neighborhood = 'El barrio debe tener menos de 100 caracteres.';
    }
    if (formData.neighborhood.trim().length < 3) {
      formErrors.neighborhood = 'El barrio debe tener más de 3 caracteres.';
    }
    if (formData.nationality.trim().length > 100) {
      formErrors.nationality = 'La nacionalidad debe tener menos de 100 caracteres.';
    }
    if (formData.nationality.trim().length < 3) {
      formErrors.nationality = 'La nacionalidad debe tener más de 3 caracteres.';
    }
    setErrors(formErrors);
    if (Object.keys(formErrors).length > 0) {
      return;
    }
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/worker-profile/${userData.profile_id}/info/update/`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authTokens.access}`,
        },
        body: JSON.stringify(formData),
      });
      if (response.ok) {
        const updatedData = await response.json();
        onUpdate(updatedData);
      } else {
        console.error('Error al actualizar el perfil:', response.statusText);
      }
    } catch (error) {
      console.error('Error al actualizar el perfil:', error.message);
    }
  };

  return (
    <div className="popup-background">
      <div className="popup">
        <div className="popup-content">
          <div className="popup-intro">
            <h3 className="popup-title">Modificar información personal</h3>
            <div className="popup-intro-description">
              <p>Todos los campos son obligatorios</p>
            </div>
          </div>
          {loading ? (
            <div className="loading-message">
              <p>Cargando...</p>
            </div>
          ) : (
            <form className="popup-form" onSubmit={(e) => e.preventDefault()}>
              <div className="popup-form-rows">
                <div className="popup-form-double-col">
                  <div className={`popup-basic-form-row ${errors.date_of_birth ? 'missing-field' : ''}`}>
                    <label>Fecha de Nacimiento:</label>
                    <input type="date" name="date_of_birth" value={formData.date_of_birth} onChange={handleChange} />
                    <p className="error-message">{errors.date_of_birth || '\u00A0'}</p>
                  </div>
                  <div className={`popup-basic-form-row ${errors.gender ? 'missing-field' : ''}`}>
                    <label>Género:</label>
                    <select name="gender" value={formData.gender} onChange={handleChange} className={errors.gender ? 'missing-field' : ''}>
                      <option value="">Seleccionar</option>
                      <option value="male">Masculino</option>
                      <option value="female">Femenino</option>
                    </select>
                    <p className="error-message">{errors.gender || '\u00A0'}</p>
                  </div>
                </div>
                <div className="popup-form-double-col">
                  <div className={`popup-basic-form-row ${errors.province ? 'missing-field' : ''}`}>
                    <label>Provincia:</label>
                    <select name="province" value={formData.province} onChange={handleChange}>
                      <option value="">Seleccionar</option>
                      {provinces.map((province) => (
                        <option key={province.id} value={province.id}>
                          {province.name}
                        </option>
                      ))}
                    </select>
                    <p className="error-message">{errors.province || '\u00A0'}</p>
                  </div>
                  <div className={`popup-basic-form-row ${errors.municipality ? 'missing-field' : ''}`}>
                    <label>Partido:</label>
                    <select name="municipality" value={formData.municipality} onChange={handleChange} disabled={!formData.province}>
                      <option value="">Seleccionar</option>
                      {filteredMunicipalities.map((municipality) => (
                        <option key={municipality.id} value={municipality.id}>
                          {municipality.name}
                        </option>
                      ))}
                    </select>
                    <p className="error-message">{errors.municipality || '\u00A0'}</p>
                  </div>
                </div>
                <div className="popup-form-double-col">
                  <div className={`popup-basic-form-row ${errors.neighborhood ? 'missing-field' : ''}`}>
                    <label>Barrio:</label>
                    <input type="text" name="neighborhood" value={formData.neighborhood} onChange={handleChange} />
                    <p className="error-message">{errors.neighborhood || '\u00A0'}</p>
                  </div>
                  <div className={`popup-basic-form-row ${errors.nationality ? 'missing-field' : ''}`}>
                    <label>Nacionalidad:</label>
                    <input type="text" name="nationality" value={formData.nationality} onChange={handleChange} />
                    <p className="error-message">{errors.nationality || '\u00A0'}</p>
                  </div>
                </div>
              </div>
              <div className="buttons-container">
                <button className="primary-btn" onClick={handleSave} disabled={!isModified}>Guardar</button>
                <button className="secondary-btn" onClick={onCancel}>Cerrar</button>
              </div>
            </form>
          )}
        </div>
      </div>
    </div>
  );
};

export default WorkerProfileInfoUpdate;
