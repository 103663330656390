import React, { useState } from 'react';
import AdminPanelJobOffersList from '../components/AdminPanelJobOffer/AdminPanelJobOffersList';
import AdminPanelJobOffersSearchBar from '../components/AdminPanelJobOffer/AdminPanelJobOffersSearchBar';

const AdminPanelJobOffersPage = () => {
  const [searchTerm, setSearchTerm] = useState('');

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  return (
    <div className="admin-panel-job-offers-page">
      <div className="admin-panel-intro">
        <h2>Ofertas laborales</h2>
      </div>
      <div className="admin-panel-data">
        <AdminPanelJobOffersSearchBar searchTerm={searchTerm} handleSearchChange={handleSearchChange} />
        <AdminPanelJobOffersList searchTerm={searchTerm} />
      </div>
    </div>
  );
};

export default AdminPanelJobOffersPage;
