import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';
import JobOfferServicesView from '../views/JobOfferView/JobOfferServicesView';
import JobOfferTasksView from '../views/JobOfferView/JobOfferTasksView';
import JobOfferWorkArrangementView from '../views/JobOfferView/JobOfferWorkArrangementView';
import JobOfferMonthlyPriceView from '../views/JobOfferView/JobOfferMonthlyPriceView';
import JobOfferShiftsView from '../views/JobOfferView/JobOfferShiftsView';
import JobOfferIntroductionView from '../views/JobOfferView/JobOfferIntroductionView';
import JobOfferEducationView from '../views/JobOfferView/JobOfferEducationView';
import JobOfferExperienceView from '../views/JobOfferView/JobOfferExperienceView';
import JobOfferLanguageView from '../views/JobOfferView/JobOfferLanguageView';
import JobOfferInfoView from '../views/JobOfferView/JobOfferInfoView';
import ParentProfileImageView from '../views/JobOfferView/ParentProfileImageView';
import ParentProfileInfoView from '../views/JobOfferView/ParentProfileInfoView';
import ParentProfileStartConversationView from '../views/JobOfferView/ParentProfileStartConversationView';
import LoadingSpinner from '../../common/components/LoadingSpinner';
import HeroSectionImage from '../../images/HomePage/HeroSection/HappyHome_HeroSectionImage.png'


const JobOfferPage = ({ setSignUpVisible }) => {
  const { jobOfferId } = useParams();
  const [jobOffer, setJobOffer] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchJobOffer = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/job-offer/${jobOfferId}/get/`);
        if (response.ok) {
          const data = await response.json();
          setJobOffer(data);
        } else {
          console.error('Failed to fetch job offer:', response.statusText);
        }
      } catch (error) {
        console.error('Error fetching job offer:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchJobOffer();
  }, [jobOfferId]);

  if (isLoading) {
    return <div className="job-offer-view"><div className="job-offer-view-container"><LoadingSpinner /></div></div>;
  }

  if (!jobOffer) {
    return <div>Error loading job offer.</div>;
  }

  return (
    
    <div className="job-offer-view">
      <Helmet>
        <title>HappyHome - Empleo "{jobOffer.title}"</title>
        <meta name="description" content="Oferta de empleo en Argentina. Requisitos, responsabilidades y detalles sobre la oferta laboral disponibles para consulta." />
        <meta name="keywords" content="oferta laboral, trabajo en Argentina, empleo, trabajo doméstico, niñera, empleada doméstica, cocinero, cuidador de personas mayores, cuidador de mascotas." />
        <meta property="og:title" content="HappyHome - Empleo en Argentina" />
        <meta property="og:description" content="La forma más fácil, rápida y económica de encontrar ayuda integral para tu hogar." />
        <meta property="og:image" content={HeroSectionImage} />
        <meta property="og:url" content="http://www.happyhome.com.ar" />
      </Helmet>

      <div className="job-offer-view-container">

        <div className="job-offer-view-intro">
          <div className="view-column-container profile-image">
            <ParentProfileImageView profileId={jobOffer.parent_profile.id} />
          </div>
          
          <div className="view-column-container info">
            <JobOfferInfoView location={jobOffer.location} title={jobOffer.title}/>
            <ParentProfileInfoView profileId={jobOffer.parent_profile.id} />
            <ParentProfileStartConversationView parentId={jobOffer.parent_profile.id} setSignUpVisible={setSignUpVisible}/>
          </div>
        </div>

        <div className="job-offer-view-section">
          <div className='view-column-container'>
            <JobOfferServicesView services={jobOffer.services} />
            <JobOfferTasksView tasks={jobOffer.tasks} />
            <JobOfferWorkArrangementView workArrangements={jobOffer.work_arrangements} />
            <JobOfferMonthlyPriceView monthlyPrice={jobOffer.monthly_price} />
            <JobOfferShiftsView workShifts={jobOffer.work_shifts} />
          </div>

          <div className='view-column-container'>
            <JobOfferIntroductionView description={jobOffer.description} />
            <JobOfferEducationView educationTypes={jobOffer.education_types} />
            <JobOfferExperienceView experienceRequired={jobOffer.work_experience_required} referencesRequired={jobOffer.work_references_required} />
            <JobOfferLanguageView languages={jobOffer.languages} nativeLanguage={jobOffer.native_language} />
          </div>
        </div>
      </div>

    </div>
  );
};

export default JobOfferPage;