import React, { useState, useEffect } from 'react';
import checkIcon from '../../../images/Vectors/HappyHome_CheckIcon.svg';
import crossIcon from '../../../images/Vectors/HappyHome_CrossIcon.svg';

const ShiftsFilter = ({ workShifts, onFilterChange, clearFilter }) => {
  const uniqueDays = [...new Set(workShifts.map(shift => shift.day_display))];
  const uniqueShifts = [...new Set(workShifts.map(shift => shift.shift_display))];

  const [selectedShifts, setSelectedShifts] = useState([]);

  const handleShiftChange = (day, shift) => {
    const isSelected = selectedShifts.some(s => s.day === day && s.shift === shift);
    if (isSelected) {
      setSelectedShifts(prevSelected => prevSelected.filter(s => !(s.day === day && s.shift === shift)));
    } else {
      setSelectedShifts(prevSelected => [...prevSelected, { day, shift }]);
    }
  };

  const toggleColumnSelection = (shift) => {
    const allShiftsInColumn = uniqueDays.map(day => ({ day, shift }));
    const isSelected = allShiftsInColumn.every(cell => selectedShifts.some(s => s.day === cell.day && s.shift === cell.shift));
    if (isSelected) {
      setSelectedShifts(prevSelected => prevSelected.filter(s => !allShiftsInColumn.some(cell => cell.day === s.day && cell.shift === s.shift)));
    } else {
      setSelectedShifts(prevSelected => [...prevSelected, ...allShiftsInColumn.filter(cell => !prevSelected.some(s => s.day === cell.day && s.shift === cell.shift))]);
    }
  };

  const toggleRowSelection = (day) => {
    const allShiftsInRow = uniqueShifts.map(shift => ({ day, shift }));
    const isSelected = allShiftsInRow.every(cell => selectedShifts.some(s => s.day === cell.day && s.shift === cell.shift));
    if (isSelected) {
      setSelectedShifts(prevSelected => prevSelected.filter(s => !allShiftsInRow.some(cell => cell.day === s.day && cell.shift === s.shift)));
    } else {
      setSelectedShifts(prevSelected => [...prevSelected, ...allShiftsInRow.filter(cell => !prevSelected.some(s => s.day === cell.day && s.shift === cell.shift))]);
    }
  };

  useEffect(() => {
    if (clearFilter) {
      setSelectedShifts([]);
    }
  }, [clearFilter]);

  useEffect(() => {
    onFilterChange(selectedShifts);
  }, [selectedShifts, onFilterChange]);

  return (
    <div className="filter-container">
      <div className="shifts-filter-content">
        <div className="shifts-filter-calendar">
          <table className="shifts-filter-calendar-table">
            <thead>
              <tr>
                <th></th>
                {uniqueShifts.map(shift => (
                  <th className="shift-calendar-col" key={shift} onClick={() => toggleColumnSelection(shift)}>{shift}</th>
                ))}
              </tr>
            </thead>
            <tbody>
            <tr className="spacer-row"></tr>
              {uniqueDays.map(day => (
                <tr key={day}>
                  <td className="shift-calendar-row" onClick={() => toggleRowSelection(day)}>{day}</td>
                  {uniqueShifts.map(shift => (
                    <td  key={`${day}-${shift}`} onClick={() => handleShiftChange(day, shift)} className={selectedShifts.some(s => s.day === day && s.shift === shift) ? 'selected' : ''}>
                      {selectedShifts.some(s => s.day === day && s.shift === shift) ? <div className="shift-calendar-icon"><img src={checkIcon} alt="Check Icon" className="icon-svg" /></div> : <div className="shift-calendar-icon"><img src={crossIcon} alt="Cross Icon" className="icon-svg" /></div>}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default ShiftsFilter;
