import React from 'react';

const JobOfferExperienceView = ({ experienceRequired, referencesRequired }) => {
  return (
    <div className='profile-view-data-section'>
      <div className="profile-view-data-section experience">
        <div className="profile-view-data-section-intro">
          <h2>Experiencia laboral</h2>
        </div>
        <p><strong>Experiencia requerida:</strong> {experienceRequired ? 'Sí' : 'No'}</p>
        <p><strong>Referencias requeridas:</strong> {referencesRequired ? 'Sí' : 'No'}</p>
      </div>
    </div>
  );
};

export default JobOfferExperienceView;
