import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import AuthContext from '../../AuthProvider';
import ParentProfileJobOfferUpdate from '../ParentProfileJobOffer/ParentProfileJobOfferUpdate';
import ParentProfileJobOfferAdd from '../ParentProfileJobOffer/ParentProfileJobOfferAdd';
import checkIcon from '../../../../images/Vectors/HappyHome_CheckIcon.svg';
import crossIcon from '../../../../images/Vectors/HappyHome_CrossIcon.svg';
import LoadingSpinner from '../../../../common/components/LoadingSpinner';

const ParentProfileJobOffersList = () => {
  const [jobOffers, setJobOffers] = useState([]);
  const [editingJobOffer, setEditingJobOffer] = useState(null);
  const [showAddJobOfferForm, setShowAddJobOfferForm] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [loading, setLoading] = useState(true);
  const { userData, authTokens } = useContext(AuthContext);

  useEffect(() => {
    const fetchJobOffers = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/parent-profile/${userData.profile_id}/job-offers/get-all/`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authTokens.access}`,
          },
        });
        if (response.ok) {
          const data = await response.json();
          setJobOffers(data);
          setDataLoaded(true);
        } else {
          console.error('Failed to fetch parent job offers');
        }
      } catch (error) {
        console.error('Error fetching parent job offers:', error);
      } finally {
        setLoading(false);
      }
    };
    fetchJobOffers();
    return () => {
      setJobOffers([]);
    };
  }, [userData.profile_id, authTokens.access]);

  const handleDeleteJobOffer = async (jobOfferId) => {
    try {
      const confirmDelete = window.confirm('¿Estás seguro que deseas eliminar esta oferta laboral?');
      if (confirmDelete) {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/parent-profile/${userData.profile_id}/job-offer/${jobOfferId}/delete/`, {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authTokens.access}`,
          },
        });
        if (response.ok) {
          setJobOffers(jobOffers.filter(jobOffer => jobOffer.id !== jobOfferId));
        } else {
          console.error('Failed to delete parent job offer');
        }
      }
    } catch (error) {
      console.error('Error deleting parent job offer:', error);
    }
  };

  const handleUpdateJobOffer = (jobOffer) => {
    setEditingJobOffer(jobOffer);
  };

  const handleCancelUpdate = () => {
    setEditingJobOffer(null);
  };

  const handleToggleAddJobOfferForm = () => {
    if (jobOffers.length < 2) {
      setShowAddJobOfferForm(!showAddJobOfferForm);
    }
  };

  const handleToggleStatus = async (jobOffer) => {
    try {
      const newStatus = !jobOffer.status;
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/parent-profile/${userData.profile_id}/job-offer/${jobOffer.id}/update-status/`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${authTokens.access}`,
        },
        body: JSON.stringify({ status: newStatus }),
      });
      if (response.ok) {
        const updatedJobOffer = await response.json();
        setJobOffers(jobOffers.map(offer => offer.id === updatedJobOffer.id ? updatedJobOffer : offer));
      } else {
        console.error('Failed to update job offer status');
      }
    } catch (error) {
      console.error('Error updating job offer status:', error);
    }
  };

  const onUpdate = (updatedJobOffer) => {
    const updatedJobOffers = jobOffers.map((offer) => {
      if (offer.id === updatedJobOffer.id) {
        return updatedJobOffer;
      }
      return offer;
    });
    setJobOffers(updatedJobOffers);
    setEditingJobOffer(null);
  };

  const onAdd = (newJobOffer) => {
    setJobOffers([...jobOffers, newJobOffer]);
    setShowAddJobOfferForm(false);
  };

  const hasMembership = userData.membership_type;
  const isMembershipActive = userData.membership_status;

  return (
    <div className="profile-edit-section job-offers">
      <div className="user-account-actions">
        <div className="user-account-actions-left">
          <h2>Mis ofertas</h2>
        </div>
        <div className="user-account-actions-right">
          {dataLoaded && (
            <button
              className="primary-btn"
              onClick={handleToggleAddJobOfferForm}
              disabled={!hasMembership || jobOffers.length >= 2 || !isMembershipActive}
            >
              Crear
            </button>
          )}
        </div>
      </div>
      <div className="profile-edit-section-content">
        {loading ? (
          <LoadingSpinner />
        ) : !dataLoaded ? (
          <p>Error al obtener datos.</p>
        ) : (
          <>
            {jobOffers.length === 0 ? (
              <div className="job-offer-list-empty">
                <h2 className="profile-edit-section-title">Sin ofertas laborales</h2>
                <div className="popup-intro-description">
                  {hasMembership ? (
                    <p>Con una membresía activa puedes crear un máximo de 2 publicaciones y estarán visibles en la página de "Empleos".</p>
                  ) : (
                    <p>Debes tener una membresía activa para crear ofertas laborales.</p>
                  )}
                </div>
              </div>
            ) : (
              <ul className="job-offer-list">
                {jobOffers.map(offer => (
                  <li key={offer.id} className="job-offer-item">
                    <div className="job-offer-item-left">
                      <div>
                        <h3>{offer.title || "Sin asignar"}</h3>
                      </div>
                    </div>
                    <div className="job-offer-item-right">
                      <div className="buttons-container">
                        <button
                          className="primary-btn"
                          onClick={() => handleUpdateJobOffer(offer)}
                          disabled={!isMembershipActive}
                        >
                          Editar
                        </button>
                        <button
                          className="secondary-btn"
                          onClick={() => handleDeleteJobOffer(offer.id)}
                          disabled={!isMembershipActive}
                        >
                          Borrar
                        </button>
                        <Link to={`/job-offer/${offer.id}`} className="offer-link">
                          <button className="secondary-btn">Ver oferta</button>
                        </Link>
                        <div className="job-offer-status-toggle">
                          <span>Activa</span>
                          <button
                            className={`status-toggle-btn ${offer.status ? 'active' : 'inactive'}`}
                            onClick={() => handleToggleStatus(offer)}
                            title={offer.status ? 'Inactivar' : 'Activar'}
                            disabled={!isMembershipActive}
                          >
                            <div className="job-offer-status-icon">
                              {offer.status ? (
                                <img src={checkIcon} alt="Check Icon" />
                              ) : (
                                <img src={crossIcon} alt="Cross Icon" />
                              )}
                            </div>
                          </button>
                        </div>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            )}
            {editingJobOffer && (
              <ParentProfileJobOfferUpdate
                jobOffer={editingJobOffer}
                onCancel={handleCancelUpdate}
                onUpdate={onUpdate}
              />
            )}
          </>
        )}
        {showAddJobOfferForm && (
          <ParentProfileJobOfferAdd
            onClose={() => setShowAddJobOfferForm(false)}
            onAdd={onAdd}
          />
        )}
      </div>
    </div>
  );
};

export default ParentProfileJobOffersList;
