import React, { useState, useEffect, useContext } from 'react';
import AuthContext from '../../AuthProvider';
import WorkerProfileWorkArrangementsUpdate from './WorkerProfileWorkArrangementsUpdate';
import LoadingSpinner from '../../../../common/components/LoadingSpinner';

const WorkerProfileWorkArrangementsList = () => {
  const { userData, authTokens } = useContext(AuthContext);
  const [workArrangements, setWorkArrangements] = useState([]);
  const [showUpdateArrangementForm, setShowUpdateArrangementForm] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);

  useEffect(() => {
    const fetchWorkArrangements = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/worker-profile/${userData.profile_id}/work-arrangements/get-all/`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${authTokens.access}`,
          },
        });

        if (response.ok) {
          const data = await response.json();
          setWorkArrangements(data);
          setDataLoaded(true);
        } else {
          console.error('Failed to fetch worker profile:', response.statusText);
        }
      } catch (error) {
        console.error('Error fetching worker profile:', error.message);
      }
    };

    fetchWorkArrangements();
  }, [authTokens.access, userData.profile_id]);

  const onUpdateArrangement = (updatedWorkArrangements) => {
    setWorkArrangements(updatedWorkArrangements);
    setShowUpdateArrangementForm(false);
  };

  return (
    <div className="profile-edit-section">
      <h2 className="profile-edit-section-title">Modalidad de trabajo</h2>
      <div className="profile-edit-section-content">
        {!dataLoaded ? (
          <LoadingSpinner />
        ) : (
          <>
            {workArrangements.length === 0 ? (
              <p className="empty-message">Debes agregar una <strong>modalidad</strong> como mínimo para que tu perfil sea visible.</p>
            ) : (
              <ul className="profile-edit-section-list">
                {workArrangements.map((workArrangement, index) => (
                  <li key={index} className="profile-edit-section-list-item">
                    <p>{workArrangement.value_display}</p>
                  </li>
                ))}
              </ul>
            )}
          </>
        )}
      </div>
      {showUpdateArrangementForm && <WorkerProfileWorkArrangementsUpdate onClose={() => setShowUpdateArrangementForm(false)} onUpdate={onUpdateArrangement} preselectedWorkArrangement={workArrangements} />}
      {dataLoaded && <button className="primary-btn" onClick={() => setShowUpdateArrangementForm(true)}>Modificar</button>}
    </div>
  );
};

export default WorkerProfileWorkArrangementsList;
