import React from 'react';
import { Helmet } from 'react-helmet';
import HeroSectionImage from '../../images/HomePage/HeroSection/HappyHome_HeroSectionImage.png'

const TermsPage = () => {
  return (
    <div className="terms-page">
      <Helmet>
        <title>HappyHome - Términos y condiciones</title>
        <meta name="description" content="La forma más fácil, rápida y económica de encontrar ayuda integral para tu hogar en Argentina. Ofrecemos servicios de empleada doméstica, niñeras, cuidadores de personas mayores, cuidadores de mascotas y cocineros." />
        <meta name="keywords" content="ayuda en el hogar, empleada doméstica, niñera, cuidador de personas mayores, cuidador de mascotas, cocinera" />
        <meta property="og:title" content="HappyHome - Términos y condiciones" />
        <meta property="og:description" content="La forma más fácil, rápida y económica de encontrar ayuda integral para tu hogar." />
        <meta property="og:image" content={HeroSectionImage} />
        <meta property="og:url" content="http://www.happyhome.com.ar" />
      </Helmet>
      <h2>Términos y Condiciones</h2>
      
      <h3>1. Aceptación de los Términos y Condiciones:</h3>
      <p>
        Al registrarse y utilizar nuestra plataforma de búsqueda laboral, usted acepta cumplir con los siguientes términos y condiciones. Estos términos se aplican a todos los usuarios de la plataforma, incluidos, entre otros, los empleadores y los candidatos (empleados). Si no está de acuerdo con estos términos, no utilice la plataforma.
      </p>
      
      <h3>2. Descripción del Servicio:</h3>
      <p>
        Nuestra plataforma ofrece servicios de búsqueda laboral para empleadores que buscan contratar niñeras, cocineros, empleadas domésticas, cuidadores de mascotas y cuidadores de personas mayores. Proporcionamos un espacio donde los empleadores pueden publicar ofertas laborales y los candidatos pueden postularse a dichas ofertas.
      </p>
      
      <h3>3. Acceso y Uso del Servicio:</h3>
      <p>
        <strong>a. Empleados:</strong> El acceso a la plataforma es completamente gratuito para los empleados. Los empleados pueden registrarse, crear un perfil profesional, buscar ofertas laborales y postularse a las mismas sin ningún costo.
      </p>
      <p>
        <strong>b. Empleadores:</strong> Ofrecemos tres tipos de membresias para empleadores, cada una con diferentes beneficios:
        <ul>
          <li><strong>Membresía PLUS:</strong> Por $35,000, los empleadores pueden acceder a toda la base de datos de candidatos y tienen la capacidad de enviar y recibir mensajes de chat de manera ilimitada.</li>
          <li><strong>Membresía PREMIUM:</strong> Por $60,000, los empleadores acceden a todas las funcionalidades de la membresía PLUS y además reciben servicios adicionales de nuestras terapeutas, quienes realizan una entrevista final a la persona elegida, solicitan antecedentes penales y verifican referencias laborales.</li>
          <li><strong>Membresía ELITE:</strong> Incluye todos los beneficios de la membresía PREMIUM, pero además nuestras terapeutas seleccionan tres perfiles adecuados para el empleador, eliminando la necesidad de que este busque activamente entre los candidatos.</li>
        </ul>
      </p>
      
      <h3>4. Registro y Creación de Perfil:</h3>
      <p>
        Todos los usuarios deben registrarse para utilizar la plataforma. Durante el proceso de registro, se les pedirá que proporcionen información precisa y completa. Los empleadores deben detallar claramente las ofertas laborales, incluyendo responsabilidades del puesto, requisitos, expectativas y cualquier otro detalle relevante. Los empleados deben proporcionar información verdadera sobre su experiencia, habilidades y disponibilidad.
      </p>
      
      <h3>5. Publicación de Ofertas Laborales:</h3>
      <p>
        Los empleadores pueden publicar ofertas laborales detalladas en la plataforma. Las ofertas deben incluir una descripción clara del puesto, los requisitos necesarios, el salario ofrecido y cualquier otro detalle relevante. Nos reservamos el derecho de revisar y eliminar cualquier oferta que no cumpla con nuestros estándares o políticas.
      </p>
      
      <h3>6. Uso de la Plataforma:</h3>
      <p>
        Los usuarios se comprometen a utilizar la plataforma de manera ética y legal. Está prohibido:
        <ul>
          <li>Publicar información falsa o engañosa.</li>
          <li>Utilizar la plataforma para fines no relacionados con la búsqueda de empleo o contratación de personal.</li>
          <li>Participar en actividades fraudulentas, abusivas o que infrinjan los derechos de otros usuarios.</li>
          <li>Usar lenguaje ofensivo o inapropiado en las comunicaciones dentro de la plataforma.</li>
        </ul>
      </p>
      
      <h3>7. Responsabilidad y Garantías:</h3>
      <p>
        La plataforma actúa como un intermediario entre empleadores y empleados, facilitando la conexión entre ambas partes. No garantizamos la idoneidad, capacidad o desempeño de los candidatos ni la disponibilidad de ofertas laborales específicas. Los empleadores son responsables de realizar las verificaciones necesarias antes de contratar a un candidato, incluyendo entrevistas, revisión de antecedentes y verificación de referencias.
      </p>
      
      <h3>8. Confidencialidad y Privacidad:</h3>
      <p>
        Nos comprometemos a proteger la privacidad de nuestros usuarios. Los datos personales serán tratados de acuerdo con nuestra Política de Privacidad, que está disponible en la plataforma. No compartiremos información personal sin el consentimiento del usuario, excepto cuando sea necesario para proporcionar el servicio o cumplir con la ley.
      </p>
      
      <h3>9. Membresias y Pagos:</h3>
      <p>
        Las membresias PLUS, PREMIUM y ELITE tienen costos asociados, que se deben pagar al momento de suscribirse. Los pagos no son reembolsables, excepto en los casos donde la ley lo requiera. Nos reservamos el derecho de cambiar los precios de las membresias, notificando a los usuarios con antelación razonable.
      </p>
      
      <h3>10. Modificaciones del Servicio:</h3>
      <p>
        Nos reservamos el derecho de modificar, suspender o discontinuar temporal o permanentemente el servicio con o sin previo aviso. Los cambios en los términos y condiciones serán comunicados a través de la plataforma y entrarán en vigencia al momento de su publicación.
      </p>
      
      <h3>11. Terminación de la Cuenta:</h3>
      <p>
        Podemos, a nuestra discreción, suspender o terminar el acceso a la plataforma a cualquier usuario que viole estos términos y condiciones, que realice actividades fraudulentas o que no cumpla con nuestras políticas. La terminación de la cuenta no exime al usuario de las obligaciones de pago pendientes.
      </p>
      
      <h3>12. Limitación de Responsabilidad:</h3>
      <p>
        En la máxima medida permitida por la ley, la plataforma y sus afiliados no serán responsables por daños indirectos, incidentales, especiales, consecuentes o punitivos, o por pérdida de beneficios o ingresos, ya sea incurridos directa o indirectamente, o por cualquier pérdida de datos, uso, buena voluntad, u otras pérdidas intangibles, resultantes de (i) su uso o incapacidad para usar la plataforma; (ii) cualquier conducta o contenido de terceros en la plataforma; (iii) cualquier contenido obtenido de la plataforma; y (iv) el acceso, uso o alteración no autorizado de sus transmisiones o contenido.
      </p>
      
      <h3>13. Ley Aplicable:</h3>
      <p>
        Estos términos y condiciones se rigen por las leyes del país donde se opera la plataforma. Cualquier disputa o reclamación relacionada con estos términos será resuelta por los tribunales competentes de dicha jurisdicción.
      </p>
      
      <h3>14. Contacto:</h3>
      <p>
        Para cualquier pregunta o duda sobre estos términos y condiciones, por favor contacte con nuestro equipo de soporte a través de la información proporcionada en la plataforma.
      </p>
    </div>
  );
};

export default TermsPage;
