import React from 'react';

const JobOfferInfoView = ({ location, title }) => {
  return (
    <div className='profile-view-data-section'>
      <div className="profile-view-data-section info">
        <ul>
          <li className="profile-view-data-section-name">
            <h2>{title || 'Sin asignar'}</h2>
          </li>
          {location ? (
            <>
              <li>
                <p><strong>Barrio: </strong>{location.neighborhood || 'Sin asignar'}</p>
              </li>
              <li>
                <p><strong>Partido: </strong>{location.municipality?.name || 'Sin asignar'}</p>
              </li>
              <li>
                <p><strong>Provincia: </strong>{location.province?.name || 'Sin asignar'}</p>
              </li>
            </>
          ) : (
            <>
              <li>
                <p><strong>Barrio: </strong>Sin asignar</p>
              </li>
              <li>
                <p><strong>Partido: </strong>Sin asignar</p>
              </li>
              <li>
                <p><strong>Provincia: </strong>Sin asignar</p>
              </li>
            </>
          )}
        </ul>
      </div>
    </div>
  );
};

export default JobOfferInfoView;

