import React from 'react';
import BrandLogo from '../../images/Logo/HappyHomeLogo.png';

const PageLoader = () => (
  <div className="preloader-overlay">
    <div className="preloader-content">
      <img src={BrandLogo} alt="Loading..." />
    </div>
  </div>
);

export default PageLoader;
