import React, { useState, useEffect, useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import LoadingSpinner from '../../../common/components/LoadingSpinner';
import HeroSectionImage from '../../../images/HomePage/HeroSection/HappyHome_HeroSectionImage.png';
import AuthContext from '../../../base/components/AuthProvider';
import ConversationsSidebar from '../Conversation/ConversationsSidebar';
import Conversation from '../Conversation/Conversation';
import { fetchUserData, fetchConversations, fetchConversationDetails } from './ChatUtils';
import NoConversationsMessage from './NoConversationsMessage';

const UserChatPage = () => {
  const { userData, authTokens } = useContext(AuthContext);
  const { conversationId } = useParams();
  const navigate = useNavigate();
  const [conversations, setConversations] = useState([]);
  const [selectedConversation, setSelectedConversation] = useState(null);
  const [currentUser, setCurrentUser] = useState({});
  const [loadingData, setLoadingData] = useState(true);
  const [loadingConversations, setLoadingConversations] = useState(true);
  const [loadingConversation, setLoadingConversation] = useState(false);
  const [error, setError] = useState(null);
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);

  useEffect(() => {
    const loadUserData = async () => {
      try {
        const userData = await fetchUserData(authTokens);
        setCurrentUser(userData);
      } catch (error) {
        console.error('Error fetching user details:', error);
        setError('Error al cargar los detalles del usuario');
      } finally {
        setLoadingData(false);
      }
    };

    loadUserData();
  }, [authTokens]);

  useEffect(() => {
    const loadConversations = async () => {
      try {
        setLoadingConversations(true);
        const data = await fetchConversations(authTokens, userData.user_id);
        setConversations(data);
      } catch (error) {
        console.error('Error fetching conversations:', error);
      } finally {
        setLoadingConversations(false);
      }
    };

    if (userData.user_id) {
      loadConversations();
    }
  }, [userData.user_id, authTokens]);

  useEffect(() => {
    if (conversationId) {
      handleSelectConversation(parseInt(conversationId, 10));
    }
  }, [conversationId]);

  const handleSelectConversation = async (conversationId) => {
    if (selectedConversation && selectedConversation.conversation.id === conversationId) {
      return;
    }
    try {
      setLoadingConversation(true);
      const data = await fetchConversationDetails(authTokens, conversationId);
      setSelectedConversation(data);
      navigate(`/conversations/chat/${conversationId}`);
      setIsSidebarOpen(false); // Cerrar el sidebar al seleccionar una conversación
    } catch (error) {
      console.error(`Error fetching conversation details for ${conversationId}:`, error);
    } finally {
      setLoadingConversation(false);
    }
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  if (loadingData || loadingConversations) {
    return <div className="user-chat-page"><div className="chat-container"><LoadingSpinner /></div></div>
  }

  if (error) {
    return <div>{error}</div>;
  }

  let message = '';
  let link = '';
  if (userData.profile_type === 'Worker') {
    message = 'Para comenzar a chatear puedes escribirle al trabajador/a que quieras visitando su perfil en la página de ';
    link = '/job-offers';
  } else {
    message = 'Para comenzar a chatear puedes escribirle al trabajador/a que quieras visitando su perfil en la página de ';
    link = '/workers';
  }

  if (conversations.length === 0) {
    return (
      <div className="user-chat-page">
        <div className="chat-container">
          <NoConversationsMessage 
            message={message} 
            link={link} 
            linkText={userData.profile_type === 'Worker' ? 'Empleos' : 'Perfiles'} 
          />
        </div>
      </div>
    );
  }

  return (
    <div className="user-chat-page">
      <Helmet>
        <title>HappyHome - Chat</title>
        <meta property="og:title" content="HappyHome - Chat" />
        <meta property="og:image" content={HeroSectionImage} />
        <meta property="og:url" content="http://www.happyhome.com.ar" />
      </Helmet>
      <div className="chat-container">
        <div className={`chat-sidebar-container ${isSidebarOpen ? 'open' : 'closed'}`}>
          <ConversationsSidebar 
            onSelectConversation={handleSelectConversation} 
            conversations={conversations} 
            currentUser={currentUser}
            isSidebarOpen={isSidebarOpen} 
            toggleSidebar={toggleSidebar}
            activeConversationId={parseInt(conversationId, 10)}
          />
        </div>
        <div className="chat-conversation-container">
          {loadingConversation ? (
            <LoadingSpinner />
          ) : selectedConversation ? (
            <Conversation conversation={selectedConversation.conversation} messages={selectedConversation.messages} currentUser={currentUser} />
          ) : (
            <div className="choose-conversation">
              <p>Selecciona una conversación</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default UserChatPage;
