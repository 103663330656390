import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import HeroSectionImage from '../../images/HomePage/HeroSection/HappyHome_HeroSectionImage.png'

const ContactPage = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });
  const [errors, setErrors] = useState({});
  const [statusMessage, setStatusMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const validateForm = () => {
    const formErrors = {};
    const { name, email, message } = formData;

    if (!name.trim()) {
      formErrors.name = 'El nombre es obligatorio.';
    } else if (name.length > 20) {
      formErrors.name = 'El nombre no puede tener más de 20 caracteres.';
    }

    if (!email.trim()) {
      formErrors.email = 'El correo electrónico es obligatorio.';
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      formErrors.email = 'El correo electrónico es inválido.';
    }

    if (!message.trim()) {
      formErrors.message = 'El mensaje es obligatorio.';
    } else if (message.length > 700) {
      formErrors.message = 'El mensaje no puede tener más de 700 caracteres.';
    }

    setErrors(formErrors);
    return Object.keys(formErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      setIsSubmitting(true);
      setStatusMessage('');

      fetch(`${process.env.REACT_APP_API_BASE_URL}/contact-form/`, { 
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      })
      .then(response => response.json())
      .then(data => {
        if (data.message) {
          setStatusMessage(data.message);
          setFormData({ name: '', email: '', message: '' });
        } else if (data.errors) {
          setErrors(data.errors);
        } else {
          setStatusMessage('Error al enviar el mensaje. Por favor, inténtalo de nuevo.');
        }
      })
      .catch(error => {
        setStatusMessage('Error al enviar el mensaje. Por favor, inténtalo de nuevo.');
      })
      .finally(() => {
        setIsSubmitting(false);
      });
    }
  };

  const handleClear = () => {
    setFormData({
      name: '',
      email: '',
      message: '',
    });
    setErrors({});
    setStatusMessage('');
  };

  return (
    <div className="contact-page-container">
        <Helmet>
        <title>HappyHome - Contacto</title>
        <meta name="description" content="La forma más fácil, rápida y económica de encontrar ayuda integral para tu hogar en Argentina. Ofrecemos servicios de empleada doméstica, niñeras, cuidadores de personas mayores, cuidadores de mascotas y cocineros." />
        <meta name="keywords" content="contacto, asistencia, servicio al cliente, soporte, Happy Home, contacto en Argentina, ayuda." />
        <meta property="og:title" content="HappyHome - Contacto" />
        <meta property="og:description" content="Ponete en contacto con el equipo de Happy Home para resolver tus dudas o recibir asistencia sobre nuestra plataforma y servicios en Argentina." />
        <meta property="og:image" content={HeroSectionImage} />
        <meta property="og:url" content="http://www.happyhome.com.ar" />
      </Helmet>
      <div className="section-title">
        <h2>Contáctanos</h2>
        <p>Puedes despejar tus dudas enviándonos un correo electrónico.</p>
      </div>
      <div className="contact-form">
        <form onSubmit={handleSubmit}>
          <div className="contact-form-rows">
            <div className={`popup-basic-form-row ${errors.name ? 'missing-field' : ''}`}>
              <input 
                type="text" 
                name="name" 
                placeholder="Nombre"
                value={formData.name} 
                onChange={handleChange} 
                disabled={isSubmitting}
              />
              <p className="error-message">{errors.name || '\u00A0'}</p>
            </div>
            <div className={`popup-basic-form-row ${errors.email ? 'missing-field' : ''}`}>
              <input 
                type="email" 
                name="email" 
                placeholder="Correo electrónico"
                value={formData.email} 
                onChange={handleChange} 
                disabled={isSubmitting}
              />
              <p className="error-message">{errors.email || '\u00A0'}</p>
            </div>
            <div className={`popup-basic-form-row ${errors.message ? 'missing-field' : ''}`}>
              <textarea 
                name="message" 
                placeholder="Mensaje"
                value={formData.message} 
                onChange={handleChange}
                rows="5"
                disabled={isSubmitting}
              />
              <p className="error-message">{errors.message || '\u00A0'}</p>
            </div>
          </div>
          <div className="status-message">
            <p>{statusMessage}</p>
          </div>
          <div className="buttons-container">
            <button 
              className="primary-btn" 
              type="submit"
              disabled={isSubmitting}
            >
              Enviar
            </button>
            <button 
              type="button" 
              className="secondary-btn"
              onClick={handleClear}
              disabled={isSubmitting}
            >
              Limpiar
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ContactPage;
