import React, { useState } from 'react';

const JobOfferTitleUpdate = ({ titleOptions, onUpdate, error }) => {
  const [title, setTitle] = useState(titleOptions);

  const handleChange = (e) => {
    setTitle(e.target.value);
    onUpdate({ title: e.target.value });
  };

  return (
    <div className="edit-job-offer-section">
      <div className="edit-job-offer-intro">
        <h3 className="edit-job-offer-title">Título de la oferta</h3>
        <div className="edit-job-offer-description">
          <p>Introduce un título para la oferta de trabajo.</p>
        </div>
      </div>

      <div className="popup-form-rows">
        <input
          type="text"
          className="popup-select-form-row"
          placeholder="Introduce el título del trabajo..."
          value={title}
          maxLength="35"
          onChange={handleChange}
        />
      </div>
      <p className="error-message">{error || '\u00A0'}</p>
    </div>
  );
};

export default JobOfferTitleUpdate;
