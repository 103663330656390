import React from 'react';

const AdminPanelParentsSearchBar = ({ searchTerm, handleSearchChange }) => {
  return (
    <div className="admin-panel-filter">
      <input
        type="text"
        placeholder="Buscar por nombre"
        value={searchTerm}
        onChange={handleSearchChange}
      />
    </div>
  );
};

export default AdminPanelParentsSearchBar;
