import React, { useState } from 'react';

const JobOfferDescriptionUpdate = ({ descriptionOptions, onUpdate, error }) => {
  const [description, setDescription] = useState(descriptionOptions);
  const [localError, setLocalError] = useState(null);

  const handleChange = (e) => {
    const { value } = e.target;
    setDescription(value);
    onUpdate({ description: value });
  };

  const validateForm = () => {
    if (description.trim() === '') {
      setLocalError('El campo de descripción no puede estar vacío.');
      return false;
    }
    if (description.trim().length > 500) {
      setLocalError('La descripción debe tener menos de 500 caracteres.');
      return false;
    }
    setLocalError(null);
    return true;
  };

  return (
    <div className="edit-job-offer-section">
      <div className="edit-job-offer-intro">
        <h3 className="edit-job-offer-title">Presenta tu hogar</h3>
        <div className="edit-job-offer-description">
          <p>Compartí información sobre tu hogar, tu familia y las responsabilidades del trabajo.</p>
        </div>
      </div>

      <div className="popup-form-rows">
        <textarea 
          className="popup-select-form-row" 
          placeholder="¿A quién debería cuidar la persona contratada? ¿Qué cualidades o habilidades buscas en el candidato?..." 
          value={description} 
          maxLength="500"
          onChange={handleChange} 
          onBlur={validateForm}
        />
        <p className="error-message">{localError || error || '\u00A0'}</p>
      </div>
    </div>
  );
};

export default JobOfferDescriptionUpdate;
