import React, { useEffect, useState } from 'react';
import LoadingSpinner from '../../../common/components/LoadingSpinner';

const WorkerProfileLanguagesView = ({ profileId }) => {
  const [languages, setLanguages] = useState([]);
  const [isLoading, setIsLoading] = useState(true);


  useEffect(() => {
    const fetchLanguages = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/worker-profile/${profileId}/languages/get-all/`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json'
          },
        });

        if (response.ok) {
          const workerLanguages = await response.json();
          setLanguages(workerLanguages);
        } else {
          console.error('Failed to fetch worker languages:', response.statusText);
        }
      } catch (error) {
        console.error('Error fetching worker languages:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchLanguages();
  }, [profileId]);

  return (
    <div className='profile-view-data-section'>
      <div className="profile-view-data-section language">
        <div className="profile-view-data-section-intro">
          <h2>Idiomas</h2>
        </div>
        {isLoading ? (
          <LoadingSpinner />
        ) : languages && languages.length > 0 ? (
          <ul>
            {languages.map((language, index) => (
              <li key={index}>
                <p>{language.language_name_display} {language.native ? "(Nativo)" : ""}</p>
              </li>
            ))}
          </ul>
        ) : (
          <p>No hay idiomas registrados.</p>
        )}
      </div>
    </div>
  );
};

export default WorkerProfileLanguagesView;
