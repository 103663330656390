import React, { useState, useEffect } from 'react';
import checkIcon from '../../../images/Vectors/HappyHome_CheckIcon.svg';
import crossIcon from '../../../images/Vectors/HappyHome_CrossIcon.svg';

const TaskFilter = ({ tasks, handleTaskChange, clearFilter }) => {
  const [selectedTasks, setSelectedTasks] = useState([]);

  useEffect(() => {
    if (clearFilter) {
      setSelectedTasks([]);
    }
  }, [clearFilter]);

  const handleChange = (value) => {
    const isSelected = selectedTasks.includes(value);
    handleTaskChange(!isSelected, value);
    setSelectedTasks(prev => {
      if (isSelected) {
        return prev.filter(task => task !== value);
      } else {
        return [...prev, value];
      }
    });
  };

  return (
    <div className="filter-container">
      {tasks.map(task => (
        <div key={task.id} className="filter-option" onClick={() => handleChange(task.title_display)}>
          <div className="filter-option-label">
            <label>{task.title_display}</label>
          </div>
          <div className="filter-option-icon">
            <img
              src={selectedTasks.includes(task.title_display) ? checkIcon : crossIcon}
              alt={selectedTasks.includes(task.title_display) ? 'Check Icon' : 'Cross Icon'}
              className="icon-svg"
            />
          </div>
        </div>
      ))}
    </div>
  );
};

export default TaskFilter;
