import React, { useEffect } from 'react';

const LocationMunicipalityFilter = ({ municipalities, handleMunicipalityChange, setSelectedMunicipality, selectedProvince, selectedMunicipality }) => {
  useEffect(() => {
    setSelectedMunicipality('');
  }, [selectedProvince, setSelectedMunicipality]);

  const filteredMunicipalities = selectedProvince
    ? municipalities.filter(municipality => municipality.province.id === selectedProvince)
    : [];

  const handleMunicipalitySelect = (event) => {
    const municipalityId = event.target.value;
    setSelectedMunicipality(municipalityId);
    handleMunicipalityChange(municipalityId);
  };

  return (
    <div className="location-municipality-filter">
      <div className="filter-option">
        <div className="filter-option-label">
          <label>Municipio</label>
        </div>
        <div className="filter-option-select">
          <select onChange={handleMunicipalitySelect} value={selectedMunicipality || ''} disabled={!selectedProvince}>
            <option value="" disabled>Seleccionar</option>
            {filteredMunicipalities.map((municipality) => (
              <option key={municipality.id} value={municipality.id}>{municipality.name}</option>
            ))}
          </select>
        </div>
      </div>
    </div>
  );
};

export default LocationMunicipalityFilter;



