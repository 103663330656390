import React from 'react';

const JobOfferWorkArrangementView = ({ workArrangements }) => {
  return (
    <div className='profile-view-data-section'>
      <div className="profile-view-data-section work-arrangements">
        <div className="profile-view-data-section-intro">
          <h2>Modalidad de trabajo</h2>
        </div>
        {workArrangements.length > 0 ? (
          <ul>
            {workArrangements.map((work_arrangement, index) => (
              <li key={index}>
                <p>{work_arrangement.value_display}</p>
              </li>
            ))}
          </ul>
        ) : (
          <p>No hay modalidad de trabajo registrada.</p>
        )}
      </div>
    </div>
  );
};

export default JobOfferWorkArrangementView;
