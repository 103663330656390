import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AuthContext from '../../../base/components/AuthProvider';
import ActiveMembershipRequiredPopup from '../../components/ActiveMembershipRequiredPopup';

const WorkerProfileStartConversationView = ({ workerId, setSignUpVisible }) => {
  const { userData, authTokens } = useContext(AuthContext);
  const navigate = useNavigate();
  const [showMembershipPopup, setShowMembershipPopup] = useState(false);

  const handleStartConversation = async () => {
    if (userData) {
      if (!userData.membership_status) {
        alert(userData.membership_status)
        setShowMembershipPopup(true);
        return;
      }

      try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/chat/conversation/start-chat/`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authTokens.access}`,
          },
          body: JSON.stringify({ workerId: workerId, parentId: userData.profile_id }),
        });
            
        if (response.ok) {
          const newConversation = await response.json();
          navigate(`/conversations/chat/${newConversation.id}`);
        } else {
          console.error('Error al iniciar la conversación');
        }
      } catch (error) {
        console.error('Error al iniciar la conversación:', error);
      }
    } else {
      setSignUpVisible(true);
    }
  };

  if (userData && userData.profile_type === 'Worker') {
    return null;
  }

  return (
    <div className="start-conversation-container">
      <button className="primary-btn" onClick={handleStartConversation}>
        Chatear
      </button>
      {showMembershipPopup && (
        <ActiveMembershipRequiredPopup onClose={() => setShowMembershipPopup(false)} />
      )}
    </div>
  );
};

export default WorkerProfileStartConversationView;
