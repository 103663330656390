import React from 'react';

const Message = ({ message, currentUser }) => {
  const isOwnMessage = message.sender.id === currentUser.id;
  const messageTime = new Date(message.timestamp).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });

  return (
    <div className={`message ${isOwnMessage ? 'own-message' : 'other-message'}`}>
      <div className="message-content">
        <p className="message-sender">{message.sender.first_name}</p>
        <p>{message.content}</p>
        <span className="message-timestamp">{messageTime}</span>
      </div>
    </div>
  );
};

export default Message;
