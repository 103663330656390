import React, { useState, useContext } from 'react';
import AuthContext from '../components/AuthProvider';

const ForgotPassword = ({ onClose }) => {
  const { getCookie } = useContext(AuthContext);
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isEmailSent, setIsEmailSent] = useState(false);
  console.log("ForgotPassword")

  const handleResetPassword = async () => {
    setLoading(true);
    try {
      console.log("handleResetPassword")
      const csrftoken = getCookie('csrftoken');
      console.log("csrftoken")
      console.log(csrftoken)
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/reset_password/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRFToken': csrftoken
        },
        credentials: 'include',
        body: JSON.stringify({ email }),
      });
      const result = await response.json();

      if (result.success) {
        setSuccessMessage(result.message);
        setIsEmailSent(true);
      } else {
        setErrorMessage(result.message);
      }
    } catch (error) {
      console.error('Error durante la solicitud de restablecimiento de contraseña:', error);
      setErrorMessage('Error al enviar la solicitud. Por favor, inténtalo de nuevo.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="forgot-password-container">
      <div className="popup-background">
        <div className="popup">
          <div className="popup-content">
            <div className="popup-intro">
              <h2>Recuperar contraseña</h2>
              <div className="popup-intro-description">
                <p>Enviaremos un link de recuperación al correo electrónico que utilizaste al crear la cuenta. Si no lo recibes, verifica que te hayas registrado con el correo correcto.</p>
              </div>
            </div>
            <div className="popup-auth-message">
              <p className="error-message">{errorMessage || '\u00A0'}</p>
              <p style={{color: "green", fontSize: "14px"}} className="error-message">{successMessage || '\u00A0'}</p>
            </div>
            <form className="popup-form">
              <div className="popup-form-rows">
                <div className="form-group">
                  
                  <input
                    id="id_email"
                    name="email"
                    type="email"
                    placeholder="Correo electrónico:"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    disabled={loading || isEmailSent}
                  />
                </div>
              </div>
              <div className="buttons-container">
                <button className="primary-btn" type="button" onClick={handleResetPassword} disabled={loading || isEmailSent}>
                  {loading ? 'Enviando...' : 'Enviar correo'}
                </button>
                <button className="secondary-btn" type="button" onClick={onClose} disabled={loading}>
                  Cerrar
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
