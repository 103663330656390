import React, { useState, useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import AuthContext from '../../../base/components/AuthProvider';

const MessageInput = ({ conversationId, currentUser, chatSocket }) => {
  const { authTokens } = useContext(AuthContext);
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (message.trim() === '') {
      setError('Message cannot be empty');
      return;
    }

    const newMessage = {
      content: message,
      conversation_id: conversationId,
      sender: {
        id: currentUser.id,
        username: currentUser.username,
      },
      timestamp: new Date().toISOString(),
    };

    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/chat/conversations/${conversationId}/messages/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${authTokens.access}`,
        },
        body: JSON.stringify(newMessage),
      });

      if (!response.ok) {
        throw new Error('Failed to send message');
      }

      const data = await response.json();
      console.log('Message submitted:', data);

      if (chatSocket) {
        chatSocket.sendMessage(data);
        console.log('Message sent through WebSocket:', data);
      }

      setMessage('');
      setError('');
    } catch (error) {
      console.error('Error sending message:', error);
      setError('Failed to send message');
    }
  };

  const handleChange = (e) => {
    setMessage(e.target.value);
    if (e.target.value.trim() !== '') {
      setError('');
    }
  };

  return (
    <form className="message-input-form" onSubmit={handleSubmit}>
      <input
        type="text"
        value={message}
        onChange={handleChange}
        placeholder="Escribe tu mensaje..."
        required
      />
      <button className="send-btn" type="submit" disabled={message.trim() === ''}>
        <FontAwesomeIcon icon={faPaperPlane} />
      </button>
      {error && <p className="error-message">{error}</p>}
    </form>
  );
};

export default MessageInput;
