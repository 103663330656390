const JobOfferTitleAdd = ({ title, setTitle, error }) => {

  const handleChange = (e) => {
    const { value } = e.target;
    setTitle(value);
  };

  return (
    <div className="edit-job-offer-section">
      <div className="edit-job-offer-intro">
        <h3 className="edit-job-offer-title">Título de la oferta</h3>
        <div className="edit-job-offer-description">
          <p>Introduce un título para la oferta de trabajo.</p>
        </div>
      </div>

      <div className="popup-form-rows">
        <input
          type="text"
          className="popup-select-form-row"
          placeholder="Introduce el título del trabajo..."
          name="title"
          value={title}
          maxLength="35"
          onChange={handleChange}
        />
        <p className="error-message">{error || '\u00A0'}</p>
      </div>
    </div>
  );
};

export default JobOfferTitleAdd;
