import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AuthContext from '../components/AuthProvider';
import WorkerProfile from '../components/WorkerProfile/WorkerProfile';
import ParentProfile from '../components/ParentProfile/ParentProfile';
import { Helmet } from 'react-helmet';
import HeroSectionImage from '../../images/HomePage/HeroSection/HappyHome_HeroSectionImage.png'

const UserAccountPage = () => {
  const { userData } = useContext(AuthContext);
  const [profileType, setProfileType] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (userData.profile_type === 'Worker') {
      setProfileType('Worker');
    } else if (userData.profile_type === 'Parent') {
      setProfileType('Parent');
    } else {
      setProfileType('Error');
    }
  }, [userData]);

  const handleViewProfile = () => {
    const profileTypeName = userData.profile_type.toLowerCase();
    if (profileTypeName === 'worker' || profileTypeName === 'parent') {
      navigate(`/${profileTypeName}-profile/${userData.profile_id}`);
    } else {
      console.error('Invalid profile type:', userData.profile_type);
    }
  };

  return (
    <div className="user-account-container">
      <Helmet>
        <title>HappyHome - Mi cuenta</title>
        <meta property="og:title" content="HappyHome - Mi cuenta" />
        <meta property="og:image" content={HeroSectionImage} />
        <meta property="og:url" content="http://www.happyhome.com.ar" />
      </Helmet>
      <div className="user-account-content">
        <div className="user-account-actions">
          <div className="user-account-actions-left">
            <h2>Mi perfil</h2>
          </div>
          <div className="user-account-actions-right">
            {profileType === 'Worker' && (<button className="primary-btn" onClick={handleViewProfile}>Ver perfil público</button>)}
            
          </div>
        </div>
        <div className="user-profile-container">
          {profileType === 'Worker' && <WorkerProfile />}
          {profileType === 'Parent' && <ParentProfile />}
          {profileType === 'Error' && (
            <div className="error-message-container">
              <p>Error: Tipo de perfil no reconocido.</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default UserAccountPage;

