import React, { useEffect, useState} from 'react';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

const EmailVerificationSuccess = () => {
    const { uidb64, token } = useParams();
    const [message, setMessage] = useState('');
    const [isVerified, setIsVerified] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const verifyEmail = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/verify-email/${uidb64}/${token}/`, {
                    method: 'GET',
                });
                const data = await response.json();
                
                if (response.ok) {
                    setIsVerified(true);
                }
                
                setMessage(data.message || data.error || 'Ocurrió un error inesperado.');
            } catch (error) {
                setMessage('Ocurrió un error: ' + error.message);
            }
        };

        if (!isVerified) {
            verifyEmail();
        }
    }, [uidb64, token, isVerified]);

    const handleRedirect = () => {
      navigate('/');
  };

    return (
        <div className="reset-password-page-container">
          <div className="popup-background">
            <div className="popup">
              <div className="popup-content">
                <div className="popup-intro">
                  <h2>Verificación de cuenta</h2>
                  <div className="popup-intro-description">
                    <p>{message}</p>
                    <button onClick={handleRedirect} className="primary-btn">
                        Ir a Inicio
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    );
};

export default EmailVerificationSuccess;
