import React, { useState, useEffect, useContext } from 'react';
import AuthContext from '../../../base/components/AuthProvider';
import MembershipStatusToggle from './MembershipStatusToggle';
import LoadingSpinner from '../../../common/components/LoadingSpinner';

const AdminPanelMembershipsList = ({ searchTerm }) => {
  const { authTokens, getCookie } = useContext(AuthContext);
  const [memberships, setMemberships] = useState([]);
  const [loadingData, setLoadingData] = useState(true);

  useEffect(() => {
    const fetchMemberships = async () => {
      try {
        setLoadingData(true);
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/admin/memberships/get-all/`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${authTokens.access}`,
          },
        });
        if (response.ok) {
          const data = await response.json();
          setMemberships(data);
        } else {
          console.error('Error al obtener las membresías:', response.statusText);
        }
      } catch (error) {
        console.error('Error al obtener las membresías:', error.message);
      } finally {
        setLoadingData(false);
      }
    };

    fetchMemberships();
  }, [authTokens.access]);

  const handleDelete = async (membershipId) => {
    const confirmDelete = window.confirm('¿Estás seguro de que quieres eliminar esta membresía?');
    if (confirmDelete) {
      try {
        const csrftoken = getCookie('csrftoken');
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/admin/membership/${membershipId}/delete/`, {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${authTokens.access}`,
            'X-CSRFToken': csrftoken
          },
        });
        if (response.ok) {
          setMemberships(memberships.filter(membership => membership.id !== membershipId));
        } else {
          console.error('Error al eliminar la membresía:', response.statusText);
        }
      } catch (error) {
        console.error('Error al eliminar la membresía:', error.message);
      }
    }
  };

  const filteredMemberships = searchTerm === ''
    ? memberships
    : memberships.filter(membership =>
        typeof membership.payment.user.full_name === 'string' &&
        membership.payment.user.full_name.toLowerCase().includes(searchTerm.toLowerCase())
      );

  return (
    <div className="admin-panel-container">
      {loadingData ? (
        <LoadingSpinner />
      ) : (
        <table className="admin-panel-table">
          <thead>
            <tr>
              <th>Usuario</th>
              <th>Tipo</th>
              <th>Fecha de inicio</th>
              <th>Fecha de expiración</th>
              <th>Acciones</th>
              <th className="last-column">Activa</th>
            </tr>
          </thead>
          <tbody>
            <tr className="space-row">{'\u00A0'}</tr>
            {filteredMemberships.map(membership => (
              <tr key={membership.id}>
                <td>{membership.payment?.user.full_name}</td>
                <td>{membership.membership_type.name}</td>
                <td>{membership.start_date}</td>
                <td>{membership.end_date}</td>
                <td>
                  <div className="buttons-container">
                    <button className="primary-btn" onClick={() => handleDelete(membership.id)}>Eliminar</button>
                  </div>
                </td>
                <td>
                  <MembershipStatusToggle
                    membershipId={membership.id}
                    initialValue={membership.status}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default AdminPanelMembershipsList;
