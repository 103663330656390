import React, { useState, useEffect, useContext } from 'react';
import AuthContext from '../../AuthProvider';
import WorkerProfileExperienceUpdate from '../WorkerProfileExperience/WorkerProfileExperienceUpdate';
import WorkerProfileExperienceAdd from '../WorkerProfileExperience/WorkerProfileExperienceAdd';
import LoadingSpinner from '../../../../common/components/LoadingSpinner';

const WorkerProfileExperiencesList = () => {
  const [experiences, setExperiences] = useState([]);
  const [editingExperience, setEditingExperience] = useState(null);
  const [showAddExperienceForm, setShowAddExperienceForm] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [loading, setLoading] = useState(true);
  const { authTokens, userData } = useContext(AuthContext);

  useEffect(() => {
    const fetchExperiences = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/worker-profile/${userData.profile_id}/experiences/get-all/`, {
          headers: {
            'Authorization': `Bearer ${authTokens?.access}`,
          }
        });
        if (response.ok) {
          const data = await response.json();
          setExperiences(data);
          setDataLoaded(true);
        } else {
          console.error('Failed to fetch worker experiences');
        }
      } catch (error) {
        console.error('Error fetching worker experiences:', error);
      } finally {
        setLoading(false);
      }
    };
    fetchExperiences();
    return () => {
      setExperiences([]);
    };
  }, [userData.profile_id, authTokens]);

  const handleDeleteExperience = async (experienceId) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/worker-profile/experience/${experienceId}/delete/`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${authTokens?.access}`,
        }
      });
      if (response.ok) {
        setExperiences(experiences.filter(experience => experience.id !== experienceId));
      } else {
        console.error('Failed to delete worker experience');
      }
    } catch (error) {
      console.error('Error deleting worker experience:', error);
    }
  };

  const handleUpdateExperience = (experience) => {
    setEditingExperience(experience);
  };

  const handleCancelUpdate = () => {
    setEditingExperience(null);
  };

  const handleToggleAddExperienceForm = () => {
    if (experiences.length < 3) {
      setShowAddExperienceForm(!showAddExperienceForm);
    }
  };

  const onUpdate = (updatedExperience) => {
    const updatedExperiences = experiences.map((exp) => {
      if (exp.id === updatedExperience.id) {
        return updatedExperience;
      }
      return exp;
    });
    setExperiences(updatedExperiences);
    setEditingExperience(null);
  };

  const onAdd = (newExperience) => {
    setExperiences([...experiences, newExperience]);
    setShowAddExperienceForm(false);
  };

  return (
    <div className="profile-edit-section">
      <h2 className="profile-edit-section-title">Experiencia laboral</h2>

      <div className="profile-edit-section-content">
        {loading ? (
          <LoadingSpinner />
        ) : !dataLoaded ? (
          <p>Error al obtener datos.</p>
        ) : (
          <>
            {experiences.length === 0 ? (
              <p className="empty-message">Aún no has agregado experiencias laborales.</p>
            ) : (
              <ul className="experience-list">
                {experiences.map(experience => (
                  <li key={experience.id} className="experience-item">
                    <p><strong>Título:</strong> {experience.title}</p>
                    <p><strong>Tareas:</strong> {experience.description}</p>
                    <p><strong>Referencia:</strong> {experience.reference}</p>
                    <p><strong>Trabajando actualmente:</strong> {experience.currently_working ? 'Sí' : 'No'}</p>
                    <div className="buttons-container">
                      <button className="primary-btn" onClick={() => handleUpdateExperience(experience)}>Modificar</button>
                      <button className="secondary-btn" onClick={() => handleDeleteExperience(experience.id)}>Eliminar</button>
                    </div>
                  </li>
                ))}
              </ul>
            )}
          </>
        )}
      </div>
      {editingExperience && <WorkerProfileExperienceUpdate experience={editingExperience} onCancel={handleCancelUpdate} onUpdate={onUpdate}/>}
      {showAddExperienceForm && <WorkerProfileExperienceAdd onClose={() => setShowAddExperienceForm(false)} onAdd={onAdd} />}
      {dataLoaded && <button className="primary-btn" onClick={handleToggleAddExperienceForm} disabled={experiences.length >= 3}>Agregar</button>}
    </div>
  );
};

export default WorkerProfileExperiencesList;
