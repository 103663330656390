import React from 'react';

const JobOffersPagination = ({ totalJobOffers, jobOffersPerPage, paginate, showAll }) => {
  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(totalJobOffers / jobOffersPerPage); i++) {
    pageNumbers.push(i);
  }

  return (
    <div className='pagination-container'>
        <ul className='pagination'>
            <li className='page-item'>
            <button onClick={() => showAll()} className='page-link clear'>
                Mostrar todo
            </button>
            </li>
            {pageNumbers.map(number => (
            <li key={number} className='page-item'>
                <button onClick={() => paginate(number)} className='page-link'>
                {number}
                </button>
            </li>
            ))}
        </ul>
    </div>
  );
};

export default JobOffersPagination;
