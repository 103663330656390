import React, { useState } from 'react';
import JobOffersList from '../../base/components/JobOffersPage/JobOffersList';
import JobOffersSearchBar from '../../base/components/JobOffersPage/JobOffersSearchBar';
import { Helmet } from 'react-helmet';
import HeroSectionImage from '../../images/HomePage/HeroSection/HappyHome_HeroSectionImage.png'

const JobOffersPage = () => {
  const [filter, setFilter] = useState('');

  const handleFilterChange = (newFilter) => {
    setFilter(newFilter);
  };

  return (
    <div className="job-offers-page-container">
      <Helmet>
        <title>HappyHome - Empleos en Argentina</title>
        <meta name="description" content="Encontrá las mejores oportunidades laborales en el sector doméstico en Argentina. Buscá empleos como niñera, empleada doméstica, cocinero, cuidador de personas mayores y más." />
        <meta name="keywords" content="empleos en Argentina, trabajo doméstico, niñera, empleada doméstica, cuidador de personas mayores, cuidador de mascotas, chef, mucama, cocinero a domicilio, oportunidades laborales" />
        <meta property="og:title" content="HappyHome - Empleos en Argentina" />
        <meta property="og:description" content="La forma más fácil, rápida y económica de encontrar ayuda integral para tu hogar." />
        <meta property="og:image" content={HeroSectionImage} />
        <meta property="og:url" content="http://www.happyhome.com.ar" />
      </Helmet>
      <JobOffersSearchBar onFilterChange={handleFilterChange} />
      <JobOffersList filter={filter} />
    </div>
  );
};

export default JobOffersPage;
