import React, { useEffect, useState } from 'react';
import LoadingSpinner from '../../../common/components/LoadingSpinner';

const WorkerProfileHourPriceView = ({ profileId }) => {
  const [hourPrice, setHourPrice] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchHourPrice = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/worker-profile/${profileId}/hour-price/get/`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json'
          },
        });

        if (response.ok) {
          const data = await response.json();
          setHourPrice(data.hour_price);
        } else {
          console.error('Failed to fetch worker hour price:', response.statusText);
        }
      } catch (error) {
        console.error('Error fetching worker hour price:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchHourPrice();
  }, [profileId]);

  return (
    <div className='profile-view-data-section'>
      <div className="profile-view-data-section hour-price">
        <div className="profile-view-data-section-intro">
          <h2>Valor por hora</h2>
        </div>
        {isLoading ? (
          <LoadingSpinner />
        ) : hourPrice !== null ? (
          <p>${hourPrice}</p>
        ) : (
          <p>No hay precio por hora registrado.</p>
        )}
      </div>
    </div>
  );
};

export default WorkerProfileHourPriceView;
