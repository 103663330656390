import React from 'react';
import { useNavigate } from 'react-router-dom';

const PaymentSuccess = ({ paymentDetails }) => {
  const navigate = useNavigate()
  const {
    membership_type,
    membership_start_date,
    membership_end_date,
    payment_amount,
    payment_method,
    transaction_id
  } = paymentDetails;

  return (
    <div className="payment-method-container">
      <div className="section-title">
        <h2>Membresía adquirida</h2>
        <p>Activaremos la membresía una vez recibamos el dinero en la cuenta. Enviamos los detalles a tu correo electrónico.</p>
      </div>
      <div className="payment-details-section">
        <h2 className="profile-edit-section-title">Detalles de la Membresía</h2>
        <div className="payment-details-container">
          <p><strong>Membresía:</strong> {membership_type}</p>
          <p><strong>Fecha de inicio:</strong> {new Date(membership_start_date).toLocaleDateString()}</p>
          <p><strong>Fecha de expiración:</strong> {new Date(membership_end_date).toLocaleDateString()}</p>
          <p><strong>Importe:</strong> ${payment_amount}</p>
          {transaction_id && (<p><strong>ID de transacción:</strong> {transaction_id}</p>)}
        </div>
        <div className="buttons-container">
          <button className="primary-btn" onClick={() => navigate('/user-account')}>Ver mi cuenta</button>
          <button className="secondary-btn" onClick={() => navigate('/')}>Volver a inicio</button>
        </div>
      </div>

    </div>
  );
};

export default PaymentSuccess;
