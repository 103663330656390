import React from 'react';

const JobOfferLanguageView = ({ languages, nativeLanguage }) => {
  return (
    <div className='profile-view-data-section'>
      <div className="profile-view-data-section languages">
        <div className="profile-view-data-section-intro">
          <h2>Idiomas</h2>
        </div>
        {languages.length > 0 ? (
          <ul>
            {languages.map((language, index) => (
              <li key={index}>
                <p>{language.name_display} {language.id === nativeLanguage ? "(Nativo)" : ""}</p>
              </li>
            ))}
          </ul>
        ) : (
          <p>No hay idiomas registrados.</p>
        )}
      </div>
    </div>
  );
};

export default JobOfferLanguageView;

