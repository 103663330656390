import React, { useState, useEffect, useContext } from 'react';
import AuthContext from '../../AuthProvider';
import WorkerProfileHourPriceUpdate from './WorkerProfileHourPriceUpdate';
import LoadingSpinner from '../../../../common/components/LoadingSpinner';

const WorkerProfileHourPrice = () => {
  const { userData, authTokens } = useContext(AuthContext);
  const [hourPrice, setHourPrice] = useState('');
  const [isUpdating, setIsUpdating] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);

  useEffect(() => {
    const fetchHourPrice = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/worker-profile/${userData.profile_id}/hour-price/get/`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authTokens?.access}`
          },
        });
        
        if (response.ok) {
          const data = await response.json();
          setHourPrice(data.hour_price);
          setDataLoaded(true);
        } else {
          console.error('Error fetching hour price:', response.statusText);
        }
      } catch (error) {
        console.error('Error fetching hour price:', error.message);
      }
    };

    fetchHourPrice();
  }, [userData.profile_id, authTokens?.access]);

  const onUpdate = (updatedHourPrice) => {
    setHourPrice(updatedHourPrice);
    setIsUpdating(false);
  };

  const handleUpdateClick = () => {
    setIsUpdating(true);
  };

  return (
    <div className="profile-edit-section">
      <h2 className="profile-edit-section-title">Valor por hora</h2>
      <div className="profile-edit-section-content">
        {!dataLoaded ? (
          <LoadingSpinner />
        ) : hourPrice ? (
          <p>${hourPrice}</p>
        ) : (
          <p className="empty-message">Aún no has establecido un valor por hora</p>
        )}
      </div>
      {isUpdating && <WorkerProfileHourPriceUpdate hourPrice={hourPrice} onUpdate={onUpdate} onCancel={() => setIsUpdating(false)} />}
      {dataLoaded && <button className="primary-btn" onClick={handleUpdateClick}>Modificar</button>}
    </div>
  );
};

export default WorkerProfileHourPrice;
