import React from 'react';

const LoadingSpinner = () => {
  return (
    <div className="loading-spinner">
      
      <div className="spinner-border" role="status">
        <span className="visually-hidden">Cargando...</span>
      </div>
      <p className="loading-spinner-message">Cargando...</p>
    </div>
  );
};

export default LoadingSpinner;
