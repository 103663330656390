import React, { useState, useEffect, useContext } from 'react';
import AuthContext from '../../AuthProvider';
import WorkerProfileTasksUpdate from './WorkerProfileTasksUpdate';
import LoadingSpinner from '../../../../common/components/LoadingSpinner';

const WorkerProfileTasksList = () => {
  const [tasks, setTasks] = useState([]);
  const [showUpdateTaskForm, setShowUpdateTaskForm] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const { userData, authTokens } = useContext(AuthContext);

  useEffect(() => {
    const fetchTasks = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/worker-profile/${userData.profile_id}/tasks/get-all/`, {
          headers: {
            'Authorization': `Bearer ${authTokens?.access}`, 
          },
        });
        if (response.ok) {
          const data = await response.json();
          setTasks(data);
          setDataLoaded(true);
        } else {
          console.error('Failed to fetch worker tasks');
        }
      } catch (error) {
        console.error('Error fetching worker tasks:', error);
      }
    };
    fetchTasks();
    return () => {
      setTasks([]);
    };
  }, [userData.profile_id, authTokens]);

  const handleToggleUpdateTaskForm = () => {
    setShowUpdateTaskForm(!showUpdateTaskForm);
  };

  const onAdd = (newTasks) => {
    setTasks(newTasks);
  };

  return (
    <div className="profile-edit-section">
      <h2 className="profile-edit-section-title">Tareas</h2>
      <div className="profile-edit-section-content">
        {!dataLoaded ? (
          <LoadingSpinner />
        ) : (
          <>
            {tasks.length === 0 ? (
              <p className="empty-message">Aún no has agregado ninguna tarea</p>
            ) : (
              <ul className="profile-edit-section-list">
                {tasks.map(task => (
                  <li key={task.id} className="profile-edit-section-list-item">
                    <p>{task.title_display}</p>
                  </li>
                ))}
              </ul>
            )}
          </>
        )}
      </div>
      {showUpdateTaskForm && <WorkerProfileTasksUpdate onClose={() => setShowUpdateTaskForm(false)} onAdd={onAdd} preselectedTasks={tasks} />}
      {dataLoaded && <button className="primary-btn" onClick={handleToggleUpdateTaskForm}>Modificar</button>}
    </div>
  );
};

export default WorkerProfileTasksList;
