import React, { useEffect } from 'react';

const LocationProvinceFilter = ({ provinces, handleProvinceChange, selectedProvince, setSelectedProvince, clearFilter }) => {
  useEffect(() => {
    if (clearFilter) {
      setSelectedProvince('');
    }
  }, [clearFilter, setSelectedProvince]);

  const handleProvinceSelect = (event) => {
    const provinceId = event.target.value;
    setSelectedProvince(provinceId);
    handleProvinceChange(provinceId);
  };

  return (
    <div className="location-province-filter">
      <div className="filter-option">
        <div className="filter-option-label">
          <label className="filter-label">Provincia</label>
        </div>
        <div className="filter-option-select">
          <select onChange={handleProvinceSelect} value={selectedProvince || ''}>
            <option value="" disabled>Seleccionar</option>
            {provinces.map((province) => (
              <option key={province.id} value={province.id}>{province.name}</option>
            ))}
            </select>
        </div>

      </div>
    </div>
  );
};

export default LocationProvinceFilter;
