import React from 'react';


const JobOfferTasksView = ({ tasks }) => {
  return (
    <div className='profile-view-data-section'>
      <div className="profile-view-data-section tasks">
        <div className="profile-view-data-section-intro">
          <h2>Tareas a realizar</h2>
        </div>
        {tasks.length > 0 ? (
          <ul>
            {tasks.map((task, index) => (
              <li key={index}>
                <p>{task.title_display}</p>
              </li>
            ))}
          </ul>
        ) : (
          <p>No hay tareas registradas.</p>
        )}
      </div>
    </div>
  );
};

export default JobOfferTasksView;

