import React, { useContext } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import AuthContext from '../base/components/AuthProvider';

const ProtectedRouteUser = () => {
  const { userData } = useContext(AuthContext);

  if (!userData) {
    return <Navigate to="/" />;
  }
  return <Outlet />;
};

export default ProtectedRouteUser;
