import React, { useState } from 'react';

const JobOfferMonthlyPriceAdd = ({ monthlyPrice, setMonthlyPrice }) => {
  const [error, setError] = useState('');

  const handleChange = (e) => {
    const value = e.target.value;
    if (/^\d*$/.test(value)) {
      const parsedValue = parseInt(value, 10);
      if (parsedValue > 10000000) {
        setError('El valor mensual no puede superar los 10,000,000.');
      } else {
        setMonthlyPrice(value);
        setError('');
      }
    } else {
      setError('Por favor, ingrese un número entero válido.');
    }
  };

  return (
    <div className="edit-job-offer-section">
      <div className="edit-job-offer-intro">
        <h3 className="edit-job-offer-title">Presupuesto</h3>
        <div className="edit-job-offer-description">
          <p>¿Cuánto estarías dispuesto/a a pagar mensualmente?</p>
        </div>
      </div>

      <div className="popup-form-rows">
        <input
          type="text"
          className="popup-select-form-row"
          value={monthlyPrice}
          onChange={handleChange}
          placeholder="$"
        />
      </div>
      <div>
        <p className="error-message">{error || '\u00A0'}</p>
      </div>
    </div>
  );
};

export default JobOfferMonthlyPriceAdd;
