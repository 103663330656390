import React, { useState, useEffect, useContext } from 'react';
import AuthContext from '../../base/components/AuthProvider';
import LoadingSpinner from '../../common/components/LoadingSpinner';

const AdminPanelStatsPage = () => {
  const { authTokens } = useContext(AuthContext);
  const [loading, setLoading] = useState(true);
  const [stats, setStats] = useState({
    users: 0,
    workers: 0,
    employers: 0,
    jobOffers: 0,
    memberships: 0,
  });

  useEffect(() => {
    const fetchStats = async () => {
      setLoading(true);
      try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/admin/stats/get-all/`, {
          headers: {
            Authorization: `Bearer ${authTokens.access}`,
          },
        });
        const data = await response.json();
        setStats({
          users: data.users,
          workers: data.workers,
          employers: data.parents,
          jobOffers: data.job_offers,
          memberships: data.memberships,
        });
      } catch (error) {
        console.error('Error fetching stats:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchStats();
  }, [authTokens.access]);

  return (
    <div className="admin-panel-stats-page">
      <div className="admin-panel-intro">
        <h2>Estadísticas</h2>
      </div>
      <div className="admin-panel-data">
        <div className="admin-panel-container">
          {loading ? (
            <LoadingSpinner />
          ) : (
            <table className="admin-panel-table">
              <thead>
                <tr>
                  <th>Usuarios</th>
                  <th>Trabajadores</th>
                  <th>Empleadores</th>
                  <th>Ofertas Laborales</th>
                  <th>Membresias</th>
                </tr>
              </thead>
              <tbody>
                <tr className="space-row">{'\u00A0'}</tr>
                <tr>
                  <td>{stats.users}</td>
                  <td>{stats.workers}</td>
                  <td>{stats.employers}</td>
                  <td>{stats.jobOffers}</td>
                  <td>{stats.memberships}</td>
                </tr>
              </tbody>
            </table>
          )}
        </div>
      </div>
    </div>
  );
};

export default AdminPanelStatsPage;
