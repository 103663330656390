import React, { useState, useEffect, useContext } from 'react';
import checkIcon from '../../../../../../images/Vectors/HappyHome_CheckIcon.svg';
import crossIcon from '../../../../../../images/Vectors/HappyHome_CrossIcon.svg';
import AuthContext from '../../../../AuthProvider';

const JobOfferLanguagesAdd = ({ languages, setLanguages, nativeLanguage, setNativeLanguage }) => {
  const { authTokens } = useContext(AuthContext);
  const [languageChoices, setLanguageChoices] = useState([]);

  useEffect(() => {
    const fetchLanguageChoices = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/languages/get-choices/`, {
          headers: {
            'Authorization': `Bearer ${authTokens.access}`,
          },
        });
        if (response.ok) {
          const data = await response.json();
          setLanguageChoices(data);
        } else {
          console.error('Error fetching language choices:', response.statusText);
        }
      } catch (error) {
        console.error('Error fetching language choices:', error.message);
      }
    };
    fetchLanguageChoices();
  }, [authTokens]);

  const handleLanguageChange = (languageId) => {
    const updatedLanguages = [...languages];
    const isSelected = updatedLanguages.includes(languageId);

    if (isSelected) {
      setLanguages(updatedLanguages.filter(id => id !== languageId));
      if (nativeLanguage === languageId) {
        setNativeLanguage(null);
      }
    } else {
      setLanguages([...updatedLanguages, languageId]);
    }
  };

  const handleNativeLanguageChange = (e) => {
    const selectedNativeLanguage = parseInt(e.target.value, 10);
    setNativeLanguage(selectedNativeLanguage);
  };
  
  const nativeLanguageOptions = languageChoices.filter(language =>
    languages.includes(language.id)
  );

  return (
    <div className="edit-job-offer-section">
      <div className="edit-job-offer-intro">
        <h3 className="edit-job-offer-title">Idiomas</h3>
        <div className="edit-job-offer-description">
          <p>Selecciona los idiomas deseados</p>
        </div>
      </div>

      <div className="popup-form-rows">
        {languageChoices.map(language => (
          <div key={language.id} className="popup-select-form-row" onClick={() => handleLanguageChange(language.id)}>
            <div className="popup-form-label">
              <label>{language.name_display}</label>
            </div>
            <div className="popup-form-icon">
              <img
                src={languages.includes(language.id) ? checkIcon : crossIcon}
                alt="Icon"
                className="icon-svg"
              />
            </div>
          </div>
        ))}
      </div>
      <div className="edit-job-offer-native-language">
        <label htmlFor="nativeLanguage">¿Cual es el idioma nativo?</label>
        <div className="popup-select-form-row">
          <select id="nativeLanguage" value={nativeLanguage || ''} onChange={handleNativeLanguageChange}>
            <option value="">Selecciona...</option>
            {nativeLanguageOptions.map(language => (
              <option key={language.id} value={language.id}>{language.name_display}</option>
            ))}
          </select>
        </div>
      </div>
    </div>
  );
};

export default JobOfferLanguagesAdd;
