import React, { useState, useContext } from 'react';
import AuthContext from '../../AuthProvider';

const WorkerProfileIntroductionUpdate = ({ introduction, onUpdate, onCancel }) => {
  const [formData, setFormData] = useState(introduction || '');
  const [error, setError] = useState(null);
  const { authTokens, userData } = useContext(AuthContext);

  const handleChange = (e) => {
    setFormData(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (formData.trim() === '') {
      setError('El campo de introducción no puede estar vacío.');
      return;
    }
    if (formData.trim().length < 15) {
      setError('La introducción debe tener como mínimo 15 caracteres.');
      return;
    }
    if (formData.trim().length > 500) {
      setError('La introducción debe tener menos de 500 caracteres.');
      return;
    }
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/worker-profile/${userData.profile_id}/introduction/update/`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authTokens.access}`,
        },
        body: JSON.stringify({ introduction: formData }),
      });

      if (response.ok) {
        onUpdate(formData);
      } else {
        console.error('Error updating introduction:', response.statusText);
      }
    } catch (error) {
      console.error('Error updating introduction:', error.message);
    }
  };

  return (
    <div className="popup-background">
      <div className="popup">
        <div className="popup-content">
          <div className="popup-intro">
              <h3 className="popup-title">Modificar presentación</h3>
              <div className="popup-intro-description">
                <p>Aca podes hablar sobre vos: por qué te gustaría trabajar, cuales son tus habilidades y cualidades o lo que consideres importante para que avances en el proceso de selección.</p>
              </div>
          </div>
          

          <form className="popup-form" onSubmit={handleSubmit}>
            <div className="popup-form-rows">
              <div className="popup-basic-form-row">
                <textarea value={formData} onChange={handleChange} />
                <p className="error-message">{error || '\u00A0'}</p>
                
              </div>
            </div>
            <div className="buttons-container">
              <button className="primary-btn" type="submit">Guardar</button>
              <button className="secondary-btn" onClick={onCancel}>Cancelar</button>
            </div>
          </form>

        </div>
      </div>
    </div>
  );
};

export default WorkerProfileIntroductionUpdate;

