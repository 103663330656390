import React from 'react';
import { useParams } from 'react-router-dom';
import WorkerProfileExperienceView from '../views/WorkerProfileView/WorkerProfileExperienceView';
import WorkerProfileEducationView from '../views/WorkerProfileView/WorkerProfileEducationView';
import WorkerProfileInfoView from '../views/WorkerProfileView/WorkerProfileInfoView';
import WorkerProfileIntroductionView from '../views/WorkerProfileView/WorkerProfileIntroductionView';
import WorkerProfileServicesView from '../views/WorkerProfileView/WorkerProfileServicesView';
import WorkerProfileLanguagesView from '../views/WorkerProfileView/WorkerProfileLanguagesView';
import WorkerProfileHourPriceView from '../views/WorkerProfileView/WorkerProfileHourPriceView';
import WorkerProfileWorkArrangementView from '../views/WorkerProfileView/WorkerProfileWorkArrangementView';
import WorkerProfileTasksView from '../views/WorkerProfileView/WorkerProfileTasksView';
import WorkerProfileShiftsView from '../views/WorkerProfileView/WorkerProfileShiftsView';
import WorkerProfileImageView from '../views/WorkerProfileView/WorkerProfileImageView';
import WorkerProfileStartConversationView from '../views/WorkerProfileView/WorkerProfileStartConversationView';
import { Helmet } from 'react-helmet';
import HeroSectionImage from '../../images/HomePage/HeroSection/HappyHome_HeroSectionImage.png'

const WorkerProfilePage = ({ setSignUpVisible }) => {
  const { profileId } = useParams();

  return (
    <div className="worker-profile-view">
      <Helmet>
        <title>HappyHome - Perfil de trabajador/a</title>
        <meta name="description" content="Conocé a un profesional calificado en Argentina disponible para trabajar. Verificá su experiencia, habilidades y referencias en su perfil público." />
        <meta name="keywords" content="perfil de trabajador, experiencia laboral, habilidades, referencias, niñera, empleada doméstica, cocinero, cuidador de personas mayores, cuidador de mascotas" />
        <meta property="og:title" content="HappyHome - Perfil de trabajador/a" />
        <meta property="og:description" content="La forma más fácil, rápida y económica de encontrar ayuda integral para tu hogar." />
        <meta property="og:image" content={HeroSectionImage} />
        <meta property="og:url" content="http://www.happyhome.com.ar" />
      </Helmet>
      <div className="worker-profile-view-container">

        <div className="worker-profile-view-section-intro">
          <div className='view-column-container profile-image'>
            <WorkerProfileImageView profileId={profileId} />
          </div>
          <div className='view-column-container info'>
            <WorkerProfileInfoView profileId={profileId} />
            <WorkerProfileIntroductionView profileId={profileId} />
            <WorkerProfileStartConversationView workerId={profileId} setSignUpVisible={setSignUpVisible} />
          </div>
        </div>

        <div className="worker-profile-view-section">
          <div className='view-column-container'>
            <WorkerProfileServicesView profileId={profileId} />
            <WorkerProfileTasksView profileId={profileId} />
            <WorkerProfileWorkArrangementView profileId={profileId} />
            <WorkerProfileHourPriceView profileId={profileId} />
            <WorkerProfileShiftsView profileId={profileId} />
          </div>

          <div className='view-column-container'>
            <WorkerProfileEducationView profileId={profileId} />
            <WorkerProfileExperienceView profileId={profileId} />
            <WorkerProfileLanguagesView profileId={profileId} />
          </div>
        </div>

      </div>
    </div>
  );
};

export default WorkerProfilePage;
