import React, { useEffect, useState } from 'react';
import LoadingSpinner from '../../../common/components/LoadingSpinner';

const ParentProfileInfoView = ({ profileId }) => {
  const [parentInfo, setParentInfo] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const calculateAge = (dateOfBirth) => {
    const today = new Date();
    const birthDate = new Date(dateOfBirth);
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();
    
    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    
    return age;
  };

  useEffect(() => {
    const fetchParentInfo = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/parent-profile/${profileId}/get-info/`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json'
          },
        });

        if (response.ok) {
          const parentProfileData = await response.json();
          setParentInfo(parentProfileData);
        } else {
          console.error('Failed to fetch parent data:', response.statusText);
        }
      } catch (error) {
        console.error('Error fetching parent data:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchParentInfo();
  }, [profileId]); 

  return (
    <div className='profile-view-data-section'>
      <div className="profile-view-data-section info">
        {isLoading ? (
          <LoadingSpinner />
        ) : parentInfo ? (
          <ul>
            <li className="profile-view-data-section-name">
                <div className="profile-view-data-section-intro">
                    <h2>Publicado por {parentInfo.user?.first_name || 'Sin asignar'}</h2>
                </div>
            </li>
            <li>
              <p><strong>Edad: </strong>{parentInfo.date_of_birth ? `${calculateAge(parentInfo.date_of_birth)} años` : 'Sin asignar'}</p>
            </li>
            <li>
              <p><strong>Género: </strong>{parentInfo.gender_display || 'Sin asignar'}</p>
            </li>
            <li>
              <p><strong>Nacionalidad: </strong>{parentInfo.nationality || 'Sin asignar'}</p>
            </li>
          </ul>
        ) : (
          <p>Error al obtener datos.</p>
        )}
      </div>
    </div>
  );
};

export default ParentProfileInfoView;
