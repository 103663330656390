import React, { useContext } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import AuthContext from '../base/components/AuthProvider';
import ActiveMembershipRequiredPopup from '../base/components/ActiveMembershipRequiredPopup';

const ProtectedRouteActiveMembership = () => {
  const { userData } = useContext(AuthContext);

  if (!userData) {
    return <Navigate to="/" />;
  }

  if (userData.profile_type === 'Parent' && !userData.membership_status) {
    return <ActiveMembershipRequiredPopup />;
  }

  return <Outlet />;
};

export default ProtectedRouteActiveMembership;
