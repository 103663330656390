import React, { useState, useEffect, useRef, useCallback } from 'react';
import ServiceFilter from '../SearchFilter/ServiceFilter';
import WorkArrangementFilter from '../SearchFilter/WorkArrangementFilter';
import TaskFilter from '../SearchFilter/TaskFilter';
import LanguageFilter from '../SearchFilter/LanguageFilter';
import ShiftsFilter from '../SearchFilter/ShiftsFilter';
import LocationFilter from '../SearchFilter/LocationFilter';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faFilter } from '@fortawesome/free-solid-svg-icons';

const JobOffersSearchBar = ({ onFilterChange }) => {
  const [filterVisibility, setFilterVisibility] = useState({
    service: false,
    work_arrangement: false,
    task: false,
    language: false,
    work_shifts: false,
    location: false
  });
  const [showFilters, setShowFilters] = useState(false);

  const toggleFilters = () => {
    setShowFilters((prevShow) => !prevShow);
  };

  const toggleFilterVisibility = (filterType) => {
    setFilterVisibility((prevVisibility) => ({
      ...prevVisibility,
      [filterType]: !prevVisibility[filterType]
    }));
  };

  const clearAllFilters = () => {
    setServiceFilters([]);
    setWorkArrangementFilters([]);
    setTaskFilters([]);
    setLanguageFilters([]);
    setSelectedShifts([]);
    setProvinceFilter('');
    setMunicipalityFilter('');
    setFilterVisibility({
      service: false,
      work_arrangement: false,
      task: false,
      language: false,
      work_shifts: false,
      location: false
    });
  };

  const [serviceFilters, setServiceFilters] = useState([]);
  const [workArrangementFilters, setWorkArrangementFilters] = useState([]);
  const [taskFilters, setTaskFilters] = useState([]);
  const [languageFilters, setLanguageFilters] = useState([]);
  const [selectedShifts, setSelectedShifts] = useState([]);
  const [provinceFilter, setProvinceFilter] = useState('');
  const [municipalityFilter, setMunicipalityFilter] = useState('');
  const [filterChoices, setFilterChoices] = useState({
    services: [],
    work_arrangements: [],
    tasks: [],
    languages: [],
    work_shifts: [],
    provinces: [],
    municipalities: []
  });

  useEffect(() => {
    const fetchFilterChoices = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/job-offers/get-filter-choices/`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setFilterChoices(data);
      } catch (error) {
        console.error('Error fetching filter choices:', error);
      }
    };
    fetchFilterChoices();
  }, []);

  const handleServiceChange = (checked, value) => {
    if (checked) {
      setServiceFilters([...serviceFilters, value]);
    } else {
      setServiceFilters(serviceFilters.filter(service => service !== value));
    }
  };
  const handleWorkArrangementChange = (checked, value) => {
    if (checked) {
      setWorkArrangementFilters([...workArrangementFilters, value]);
    } else {
      setWorkArrangementFilters(workArrangementFilters.filter(work_arrangement => work_arrangement !== value));
    }
  };
  const handleTaskChange = (checked, value) => {
    if (checked) {
      setTaskFilters([...taskFilters, value]);
    } else {
      setTaskFilters(taskFilters.filter(task => task !== value));
    }
  };
  const handleLanguageChange = (checked, value) => {
    if (checked) {
      setLanguageFilters([...languageFilters, value]);
    } else {
      setLanguageFilters(languageFilters.filter(language => language !== value));
    }
  };
  const handleShiftsFilterChange = (selectedShifts) => {
    setSelectedShifts(selectedShifts);
  };
  const handleProvinceChange = (provinceId) => {
    setProvinceFilter(provinceId);
  };
  const handleMunicipalityChange = (municipalityId) => {
    setMunicipalityFilter(municipalityId);
  };

  const updateFilter = useCallback(() => {
    const filters = [];
    if (serviceFilters.length > 0) {
      filters.push(`services=${serviceFilters.join(',')}`);
    }
    if (workArrangementFilters.length > 0) {
      filters.push(`work_arrangements=${workArrangementFilters.join(',')}`);
    }
    if (taskFilters.length > 0) {
      filters.push(`tasks=${taskFilters.join(',')}`);
    }
    if (languageFilters.length > 0) {
      filters.push(`languages=${languageFilters.join(',')}`);
    }
    if (selectedShifts.length > 0) {
      const shiftFilters = selectedShifts.map(shift => `${shift.day}:${shift.shift}`).join(',');
      filters.push(`work_shifts=${shiftFilters}`);
    }
    if (provinceFilter !== '') {
      filters.push(`province=${provinceFilter}`);
    }
    if (municipalityFilter !== '') {
      filters.push(`municipality=${municipalityFilter}`);
    }
    const queryString = filters.join('&');
    onFilterChange(queryString);
  }, [serviceFilters, workArrangementFilters, taskFilters, languageFilters, selectedShifts, provinceFilter, municipalityFilter, onFilterChange]);

  useEffect(() => {
    updateFilter();
  }, [updateFilter]);

  const filterContainerRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (filterContainerRef.current && !filterContainerRef.current.contains(event.target)) {
        setFilterVisibility({
          service: false,
          work_arrangement: false,
          task: false,
          language: false,
          work_shifts: false,
          location: false
        });
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="job-offers-search-container">
      <div className="mobile-filter-button">
        <button className="secondary-btn" onClick={toggleFilters}>
          Filtros <FontAwesomeIcon icon={faFilter} />
        </button>
      </div>
      <div className={`filter-buttons ${showFilters ? 'show' : 'hide'}`} ref={filterContainerRef}>
        <div className={`filter-button ${serviceFilters.length > 0 ? 'active' : ''}`}>
          <button onClick={() => toggleFilterVisibility('service')}>
            Servicios <FontAwesomeIcon icon={faChevronDown} />
          </button>
          <div className={`filter-options ${filterVisibility.service ? 'visible' : 'hidden'}`}>
            <ServiceFilter services={filterChoices.services} handleServiceChange={handleServiceChange} clearFilter={serviceFilters.length === 0} />
          </div>
        </div>
        <div className={`filter-button ${workArrangementFilters.length > 0 ? 'active' : ''}`}>
          <button onClick={() => toggleFilterVisibility('work_arrangement')}>
            Modalidad <FontAwesomeIcon icon={faChevronDown} />
          </button>
          <div className={`filter-options ${filterVisibility.work_arrangement ? 'visible' : 'hidden'}`}>
            <WorkArrangementFilter work_arrangements={filterChoices.work_arrangements} handleWorkArrangementChange={handleWorkArrangementChange} clearFilter={workArrangementFilters.length === 0} />
          </div>
        </div>
        <div className={`filter-button ${taskFilters.length > 0 ? 'active' : ''}`}>
          <button onClick={() => toggleFilterVisibility('task')}>
            Tareas <FontAwesomeIcon icon={faChevronDown} />
          </button>
          <div className={`filter-options ${filterVisibility.task ? 'visible' : 'hidden'}`}>
            <TaskFilter tasks={filterChoices.tasks} handleTaskChange={handleTaskChange} clearFilter={taskFilters.length === 0} />
          </div>
        </div>
        <div className={`filter-button ${languageFilters.length > 0 ? 'active' : ''}`}>
          <button onClick={() => toggleFilterVisibility('language')}>
            Idiomas <FontAwesomeIcon icon={faChevronDown} />
          </button>
          <div className={`filter-options ${filterVisibility.language ? 'visible' : 'hidden'}`}>
            <LanguageFilter languages={filterChoices.languages} handleLanguageChange={handleLanguageChange} clearFilter={languageFilters.length === 0} />
          </div>
        </div>
        <div className={`filter-button ${selectedShifts.length > 0 ? 'active' : ''}`}>
          <button onClick={() => toggleFilterVisibility('work_shifts')}>
            Disponibilidad <FontAwesomeIcon icon={faChevronDown} />
          </button>
          <div className={`filter-options ${filterVisibility.work_shifts ? 'visible' : 'hidden'}`}>
            <ShiftsFilter workShifts={filterChoices.work_shifts} onFilterChange={handleShiftsFilterChange} clearFilter={selectedShifts.length === 0} />
          </div>
        </div>
        <div className={`filter-button ${provinceFilter || municipalityFilter ? 'active' : ''}`}>
          <button onClick={() => toggleFilterVisibility('location')}>
            Ubicación <FontAwesomeIcon icon={faChevronDown} />
          </button>
          <div className={`filter-options ${filterVisibility.location ? 'visible' : 'hidden'}`}>
            <LocationFilter provinces={filterChoices.provinces} municipalities={filterChoices.municipalities} handleProvinceChange={handleProvinceChange} handleMunicipalityChange={handleMunicipalityChange} clearFilter={!provinceFilter && !municipalityFilter} />
          </div>
        </div>
        <div className="filter-button">
          <button className="clear" onClick={clearAllFilters}>Limpiar filtros</button>
      </div>
      </div>
    </div>
  );
};

export default JobOffersSearchBar;
