import React, { useState, useContext } from 'react';
import checkIcon from '../../../images/Vectors/HappyHome_CheckIcon.svg';
import crossIcon from '../../../images/Vectors/HappyHome_CrossIcon.svg';
import AuthContext from '../../../base/components/AuthProvider';
import LoadingSpinner from '../../../common/components/LoadingSpinner';

const MembershipStatusToggle = ({ membershipId, initialValue, onUpdateStatus }) => {
  const { authTokens } = useContext(AuthContext);
  const [active, setActive] = useState(initialValue);
  const [isLoading, setIsLoading] = useState(false);

  const toggleStatus = async () => {
    setIsLoading(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/admin/membership/${membershipId}/toggle-status/`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authTokens.access}`,
        },
        body: JSON.stringify({ active: !active }),
      });
      if (response.ok) {
        setActive(!active);
        if (onUpdateStatus) {
          onUpdateStatus(membershipId, !active);
        }
      } else {
        console.error('Error al cambiar el estado de la membresía:', response.statusText);
      }
    } catch (error) {
      console.error('Error al cambiar el estado de la membresía:', error.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="membership-status-toggle">
      <div className="filter-option-icon" onClick={toggleStatus} style={{ cursor: 'pointer' }}>
        {isLoading ? (
          <LoadingSpinner />
        ) : active ? (
          <img src={checkIcon} alt="Check Icon" className="icon-svg" />
        ) : (
          <img src={crossIcon} alt="Cross Icon" className="icon-svg" />
        )}
      </div>
    </div>
  );
};

export default MembershipStatusToggle;
