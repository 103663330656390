export const fetchUserData = async (authTokens) => {
  if (authTokens && authTokens.access) {
      const userResponse = await fetch(`${process.env.REACT_APP_API_BASE_URL}/user/get-data/`, {
          method: 'GET',
          headers: {
              'Authorization': `Bearer ${authTokens.access}`,
          },
      });
      if (userResponse.ok) {
          return await userResponse.json();
      } else {
          throw new Error('Failed to fetch user details');
      }
  } else {
      throw new Error('Access token is missing');
  }
};

export const fetchConversations = async (authTokens, userId) => {
  if (authTokens && authTokens.access) {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/chat/${userId}/conversations/get-all/`, {
          method: 'GET',
          headers: {
              'Authorization': `Bearer ${authTokens.access}`,
          },
      });
      if (!response.ok) {
          throw new Error('Failed to fetch conversations');
      }
      return await response.json();
  } else {
      throw new Error('Access token is missing');
  }
};

export const fetchConversationDetails = async (authTokens, conversationId) => {
  if (authTokens && authTokens.access) {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/chat/conversations/${conversationId}/`, {
          method: 'GET',
          headers: {
              'Authorization': `Bearer ${authTokens.access}`,
          },
      });
      if (!response.ok) {
          throw new Error('Failed to fetch conversation details');
      }
      return await response.json();
  } else {
      throw new Error('Access token is missing');
  }
};
