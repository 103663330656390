import React, { useState } from 'react';
import WorkersList from '../../base/components/WorkersPage/WorkersList';
import WorkersSearchBar from '../../base/components/WorkersPage/WorkersSearchBar';
import { Helmet } from 'react-helmet';
import HeroSectionImage from '../../images/HomePage/HeroSection/HappyHome_HeroSectionImage.png'

const WorkersPage = () => {
  const [filter, setFilter] = useState('');

  const handleFilterChange = (newFilter) => {
    setFilter(newFilter);
  };

  return (
    <div className="workers-page-container">
      <Helmet>
        <title>HappyHome - Perfiles de trabajadores en Argentina</title>
        <meta name="description" content="Explorá perfiles de trabajadores confiables y calificados en Argentina. Encuentra niñeras, empleadas domésticas, cocineros, cuidadores de personas mayores y más." />
        <meta name="keywords" content="perfiles de trabajadores, niñeras, empleadas domésticas, cocineros, cuidadores de personas mayores, cuidadores de mascotas, personal doméstico, trabajadores en Argentina" />
        <meta property="og:title" content="HappyHome - Perfiles de trabajador/a" />
        <meta property="og:description" content="La forma más fácil, rápida y económica de encontrar ayuda integral para tu hogar." />
        <meta property="og:image" content={HeroSectionImage} />
        <meta property="og:url" content="http://www.happyhome.com.ar" />
      </Helmet>
      <WorkersSearchBar onFilterChange={handleFilterChange} />
      <WorkersList filter={filter} />
    </div>

  );
};

export default WorkersPage;
