import React from 'react';
import checkIcon from '../../../../../../images/Vectors/HappyHome_CheckIcon.svg';
import crossIcon from '../../../../../../images/Vectors/HappyHome_CrossIcon.svg';

const JobOfferExperienceAdd = ({ workExperience, setWorkExperience }) => {

  const handleExperienceChange = () => {
    const updatedExperience = {
      ...workExperience,
      experience_required: !workExperience.experience_required,
    };
    if (workExperience.experience_required !== updatedExperience.experience_required) {
      setWorkExperience(updatedExperience);
    }
  };

  const handleReferencesChange = () => {
    const updatedReferences = {
      ...workExperience,
      references_required: !workExperience.references_required,
    };
    if (workExperience.references_required !== updatedReferences.references_required) {
      setWorkExperience(updatedReferences);
    }
  };

  return (
    <div className="edit-job-offer-section">
      <div className="edit-job-offer-intro">
        <h3 className="edit-job-offer-title">Experiencia laboral</h3>
        <div className="edit-job-offer-description">
          <p>¿Es importante que tenga experiencia laboral?</p>
        </div>
      </div>
      <div className="popup-form-rows">
        <div className="popup-select-form-row" onClick={handleExperienceChange}>
          <div className="popup-form-label">
            <label>¿Es necesario que tenga experiencia laboral?</label>
          </div>
          <div className="popup-form-icon">
            <img src={workExperience.experience_required ? checkIcon : crossIcon} alt="Icon" className="icon-svg" />
          </div>
        </div>
        <div className="popup-select-form-row" onClick={handleReferencesChange}>
          <div className="popup-form-label">
            <label>¿Es necesario que tenga referencias laborales?</label>
          </div>
          <div className="popup-form-icon">
            <img src={workExperience.references_required ? checkIcon : crossIcon} alt="Icon" className="icon-svg" />
          </div>

        </div>
      </div>
    </div>
  );
};

export default JobOfferExperienceAdd;
