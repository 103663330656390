import React, { useEffect, useState } from 'react';
import LoadingSpinner from '../../../common/components/LoadingSpinner';

const WorkerProfileEducationView = ({ profileId }) => {
  const [educations, setEducations] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchEducations = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/worker-profile/${profileId}/educations/get-all/`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json'
          },
        });

        if (response.ok) {
          const workerEducations = await response.json();
          setEducations(workerEducations);
        } else {
          console.error('Failed to fetch worker educations:', response.statusText);
        }
      } catch (error) {
        console.error('Error fetching worker educations:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchEducations();
  }, [profileId]);
  return (
    <div className='profile-view-data-section'>
      <div className="profile-view-data-section education">
        <div className="profile-view-data-section-intro">
          <h2>Educación</h2>
        </div>
        {isLoading ? (
          <LoadingSpinner />
        ) : educations.length > 0 ? (
          <ul>
            {educations.map((education, index) => (
              <li key={index}>
                <p><strong>Título:</strong> {education.title}</p>
                <p><strong>Institución:</strong> {education.place}</p>
                <p><strong>Estado:</strong> {education.status_display}</p>
                <p><strong>Disciplina:</strong> {education.type_display}</p>
              </li>
            ))}
          </ul>
        ) : (
          <p>No hay educación registrada.</p>
        )}
      </div>
    </div>
  );
};

export default WorkerProfileEducationView;
