import React, { useState, useContext, useEffect } from 'react';
import AuthContext from '../../AuthProvider';
import checkIcon from '../../../../images/Vectors/HappyHome_CheckIcon.svg';
import crossIcon from '../../../../images/Vectors/HappyHome_CrossIcon.svg';
import LoadingSpinner from '../../../../common/components/LoadingSpinner';

const WorkerProfileWorkArrangementsUpdate = ({ onClose, onUpdate, preselectedWorkArrangement }) => {
  const { authTokens, userData } = useContext(AuthContext);
  const [selectedWorkArrangements, setSelectedWorkArrangements] = useState(preselectedWorkArrangement.map(workArrangement => workArrangement.id));
  const [workArrangementChoices, setWorkArrangementChoices] = useState([]);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isChanged, setIsChanged] = useState(false);

  useEffect(() => {
    setSelectedWorkArrangements(preselectedWorkArrangement.map(workArrangement => workArrangement.id));
  }, [preselectedWorkArrangement]);

  useEffect(() => {
    const fetchWorkArrangements = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/worker-profile/work-arrangements/get-choices/`, {
          headers: {
            'Authorization': `Bearer ${authTokens?.access}`,
          },
        });
        if (response.ok) {
          const data = await response.json();
          setWorkArrangementChoices(data.work_arrangements);
        } else {
          console.error('Error al obtener las modalidades de trabajo:', response.statusText);
        }
      } catch (error) {
        console.error('Error al obtener las modalidades de trabajo:', error.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchWorkArrangements();
  }, [authTokens]);

  const handleWorkArrangementChange = (workArrangementId) => {
    setSelectedWorkArrangements(prevSelectedWorkArrangements => {
      const newSelectedWorkArrangements = prevSelectedWorkArrangements.includes(workArrangementId)
        ? prevSelectedWorkArrangements.filter(id => id !== workArrangementId)
        : [...prevSelectedWorkArrangements, workArrangementId];
      
      setIsChanged(
        newSelectedWorkArrangements.length !== preselectedWorkArrangement.length ||
        newSelectedWorkArrangements.some(id => !preselectedWorkArrangement.some(workArrangement => workArrangement.id === id))
      );

      return newSelectedWorkArrangements;
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (selectedWorkArrangements.length === 0) {
      setError('Debes seleccionar al menos una modalidad de trabajo.');
      return;
    }
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/worker-profile/${userData.profile_id}/work-arrangements/update/`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${authTokens?.access}`,
        },
        body: JSON.stringify({ work_arrangement_ids: selectedWorkArrangements }),
      });
      if (response.ok) {
        const data = await response.json();
        onUpdate(data.updated_work_arrangements);
        onClose();
      } else {
        console.error('Error al actualizar modalidad de trabajo:', response.statusText);
      }
    } catch (error) {
      console.error('Error al actualizar modalidad de trabajo:', error.message);
    }
  };

  return (
    <div className="popup-background">
      <div className="popup">
        <div className="popup-content">
          <div className="popup-intro">
            <h3 className="popup-title">Modalidad de Trabajo</h3>
            <div className="popup-intro-description">
              <p>Marca las modalidades de trabajo que estás dispuesto/a a realizar</p>
            </div>
          </div>

          {isLoading ? (
            <LoadingSpinner />
          ) : (
            <form className="popup-form" onSubmit={handleSubmit}>
              <div className="popup-form-rows">
                {workArrangementChoices.map(workArrangement => (
                  <div className="popup-select-form-row" key={workArrangement.id} onClick={() => handleWorkArrangementChange(workArrangement.id)}>
                    <div className="popup-form-label">
                      <label>{workArrangement.value_display}</label>
                    </div>
                    {selectedWorkArrangements.includes(workArrangement.id) ? (
                      <div className="popup-form-icon"><img src={checkIcon} alt="Check Icon" className="icon-svg" /></div>
                    ) : (
                      <div className="popup-form-icon"><img src={crossIcon} alt="Cross Icon" className="icon-svg" /></div>
                    )}
                  </div>
                ))}
              </div>
              <p className="error-message">{error || '\u00A0'}</p>
              <div className="buttons-container">
                <button className="primary-btn" type="submit" disabled={!isChanged}>Guardar</button>
                <button className="secondary-btn" onClick={onClose}>Cancelar</button>
              </div>
            </form>
          )}
        </div>
      </div>
    </div>
  );
};

export default WorkerProfileWorkArrangementsUpdate;


