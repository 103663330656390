import React, { useState, useEffect, useContext } from 'react';
import AuthContext from '../../AuthProvider';
import WorkerProfileShiftUpdate from './WorkerProfileShiftUpdate';
import checkIcon from '../../../../images/Vectors/HappyHome_CheckIcon.svg';
import crossIcon from '../../../../images/Vectors/HappyHome_CrossIcon.svg';
import LoadingSpinner from '../../../../common/components/LoadingSpinner';

const WorkerProfileShiftsList = () => {
  const { userData, authTokens } = useContext(AuthContext);
  const [shifts, setShifts] = useState([]);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [loading, setLoading] = useState(true);

  const generateMatrix = () => {
    const matrix = [];
    const daysOfWeek = ['Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado', 'Domingo'];
    const shiftsOfDay = ['Mañana', 'Tarde', 'Noche'];

    daysOfWeek.forEach(day => {
      const row = [];
      shiftsOfDay.forEach(shift => {
        const hasShift = shifts.some(s => s.day === day && s.shift === shift);
        row.push({ hasShift, activated: hasShift });
      });
      matrix.push(row);
    });
    return matrix;
  };

  useEffect(() => {
    const fetchShifts = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/worker-profile/${userData.profile_id}/shifts/get-all/`, {
          headers: {
            'Authorization': `Bearer ${authTokens?.access}`
          }
        });
        if (response.ok) {
          const data = await response.json();
          setShifts(data);
          setDataLoaded(true);
        } else {
          console.error('Failed to fetch shifts');
        }
      } catch (error) {
        console.error('Error fetching shifts:', error);
      } finally {
        setLoading(false);
      }
    };
    fetchShifts();
  }, [userData.profile_id, authTokens?.access]);

  const handleUpdateClick = () => {
    setShowUpdateModal(true);
  };

  const handleCloseUpdateModal = () => {
    setShowUpdateModal(false);
  };

  const handleUpdate = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/worker-profile/${userData.profile_id}/shifts/get-all/`, {
        headers: {
          'Authorization': `Bearer ${authTokens?.access}`
        }
      });
      if (response.ok) {
        const data = await response.json();
        setShifts(data);
      } else {
        console.error('Failed to fetch updated shifts');
      }
    } catch (error) {
      console.error('Error updating shifts:', error);
    }
  };

  return (
    <div className="profile-edit-section">
      <h2 className="profile-edit-section-title">Disponibilidad</h2>
      <div className="profile-edit-section-content calendar">
        {loading ? (
          <LoadingSpinner />
        ) : dataLoaded ? (
          <div className="profile-edit-section-calendar">
            <table className="profile-edit-section-calendar-table">
              <thead>
                <tr>
                  <th></th>
                  {['Mañana', 'Tarde', 'Noche'].map((shift, index) => (
                    <th key={index}>{shift}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                <tr className="spacer-row"></tr>
                {['Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado', 'Domingo'].map((day, dayIndex) => (
                  <tr key={dayIndex}>
                    <td>{day}</td>
                    {generateMatrix()[dayIndex].map((cell, cellIndex) => (
                      <td key={cellIndex}>
                        <div className="update-calendar-row-cell">
                          <div className="update-calendar-row-icon">
                            {cell.hasShift ? (
                              <img src={checkIcon} alt="Check Icon" className="icon-svg" />
                            ) : (
                              <img src={crossIcon} alt="Cross Icon" className="icon-svg" />
                            )}
                          </div>
                        </div>
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <p>Error al obtener datos de los turnos.</p>
        )}
      </div>
      {showUpdateModal && <WorkerProfileShiftUpdate onClose={handleCloseUpdateModal} onUpdate={handleUpdate} shifts={shifts} />}
      {dataLoaded && <button className="primary-btn" onClick={handleUpdateClick}>Modificar</button>}
    </div>
  );
};

export default WorkerProfileShiftsList;
