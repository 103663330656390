import React, { useState, useEffect } from 'react';
import checkIcon from '../../../images/Vectors/HappyHome_CheckIcon.svg';
import crossIcon from '../../../images/Vectors/HappyHome_CrossIcon.svg';

const WorkArrangementFilter = ({ work_arrangements, handleWorkArrangementChange, clearFilter }) => {

  const [selectedWorkArrangements, setSelectedWorkArrangements] = useState([]);
  useEffect(() => {
    if (clearFilter) {
      setSelectedWorkArrangements([]);
    }
  }, [clearFilter]);

  const handleChange = (value) => {
    const isSelected = selectedWorkArrangements.includes(value);
    handleWorkArrangementChange(!isSelected, value);
    setSelectedWorkArrangements(prev => {
      if (isSelected) {
        return prev.filter(work_arrangement => work_arrangement !== value);
      } else {
        return [...prev, value];
      }
    });
  };

  return (
    <div className="filter-container">
      {work_arrangements.map(work_arrangement => (
        <div key={work_arrangement.id} className="filter-option" onClick={() => handleChange(work_arrangement.value_display)}>
          <div className="filter-option-label">
            <label>{work_arrangement.value_display}</label>
          </div>
          <div className="filter-option-icon">
            <img
              src={selectedWorkArrangements.includes(work_arrangement.value_display) ? checkIcon : crossIcon}
              alt={selectedWorkArrangements.includes(work_arrangement.value_display) ? 'Check Icon' : 'Cross Icon'}
              className="icon-svg"
            />
          </div>
        </div>
      ))}
    </div>
  );
};

export default WorkArrangementFilter;
