import React, { useState, useEffect, useContext } from 'react';
import AuthContext from '../../AuthProvider';
import ParentProfileInfoUpdate from './ParentProfileInfoUpdate';
import LoadingSpinner from '../../../../common/components/LoadingSpinner';

const ParentProfileInfo = () => {
  const { authTokens, userData } = useContext(AuthContext);
  const [parentInfo, setParentInfo] = useState(null);
  const [isUpdating, setIsUpdating] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchParentInfo = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/parent-profile/${userData.profile_id}/get-info/`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${authTokens.access}`,
          },
        });

        if (response.ok) {
          const parentInfo = await response.json();
          setParentInfo(parentInfo);
        } else {
          console.error('Failed to fetch parent profile:', response.statusText);
        }
      } catch (error) {
        console.error('Error fetching parent profile:', error.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchParentInfo();
  }, [authTokens.access, userData.profile_id]);

  const handleUpdateData = () => {
    setIsUpdating(true);
  };

  const onUpdate = async (updatedData) => {
    setParentInfo(updatedData);
    setIsUpdating(false);
  };

  return (
    <div className="profile-edit-section">
      <h2 className="profile-edit-section-title">Información personal</h2>
      <div className="profile-edit-section-content">
        {isLoading ? (
          <LoadingSpinner />
        ) : (
          parentInfo ? (
            <>
              <p><strong>Nacimiento:</strong> {parentInfo.date_of_birth || 'Sin asignar'}</p>
              <p><strong>Género:</strong> {parentInfo.gender_display || 'Sin asignar'}</p>
              <p><strong>Nacionalidad:</strong> {parentInfo.nationality || 'Sin asignar'}</p>
            </>
          ) : (
            <p>Error al cargar la información.</p>
          )
        )}
      </div>
      {isUpdating ? (
        <ParentProfileInfoUpdate parentInfo={parentInfo} onCancel={() => setIsUpdating(false)} onUpdate={onUpdate} />
      ) : (
        <button className="primary-btn" onClick={handleUpdateData}>Modificar</button>
      )}
    </div>
  );
};

export default ParentProfileInfo;

