import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { AuthProvider } from './base/components/AuthProvider';
import PageRouter from './router/PageRouter';
import Footer from './base/components/Footer/Footer';
import PageLoader from './common/components/PageLoader';
import InactivityWarning from './common/components/InactivityWarning'; // Asegúrate de importar el componente
import './App.css';

const App = () => {
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const timer = setTimeout(() => {
            setIsLoading(false);
        }, 1000);

        return () => clearTimeout(timer);
    }, []);

    return (
        <AuthProvider>
            <Router>
                {isLoading && <PageLoader />}
                <div className={`app-container ${isLoading ? 'loading' : ''}`}>
                    <PageRouter />
                    <Footer />
                    {/* InactividadWarning debería ir aquí */}
                    <InactivityWarning onRefresh={() => window.location.reload()} />
                </div>
            </Router>
        </AuthProvider>
    );
};

export default App;

