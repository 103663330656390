import React, { useState, useEffect, useContext } from 'react';
import AuthContext from '../../AuthProvider';
import WorkerProfileServicesUpdate from './WorkerProfileServicesUpdate';
import LoadingSpinner from '../../../../common/components/LoadingSpinner';

const WorkerProfileServicesList = () => {
  const [services, setServices] = useState([]);
  const [showUpdateServicesForm, setShowUpdateServicesForm] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const { userData, authTokens } = useContext(AuthContext);

  useEffect(() => {
    const fetchServices = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/worker-profile/${userData.profile_id}/services/get-all/`, {
          headers: {
            'Authorization': `Bearer ${authTokens?.access}`
          }
        });
        if (response.ok) {
          const data = await response.json();
          setServices(data);
          setDataLoaded(true);
        } else {
          console.error('Failed to fetch worker services:', response.statusText);
        }
      } catch (error) {
        console.error('Error fetching worker services:', error.message);
      }
    };
    fetchServices();
    return () => {
      setServices([]);
    };
  }, [userData.profile_id, authTokens?.access]);

  const handleToggleUpdateServicesForm = () => {
    setShowUpdateServicesForm(!showUpdateServicesForm);
  };

  const onAdd = (newServices) => {
    setServices(newServices);
  };

  return (
    <div className="profile-edit-section">
      <h2 className="profile-edit-section-title">Servicios</h2>
      <div className="profile-edit-section-content">
        {!dataLoaded ? (
          <LoadingSpinner />
        ) : (
          <>
            {services.length === 0 ? (
              <p className="empty-message">Debes agregar un <strong>servicio</strong> como mínimo para que tu perfil sea visible.</p>
            ) : (
              <ul className="profile-edit-section-list">
                {services.map(service => (
                  <li key={service.id} className="profile-edit-section-list-item">
                    <p>{service.title_display}</p>
                  </li>
                ))}
              </ul>
            )}
          </>
        )}
      </div>
      {showUpdateServicesForm && <WorkerProfileServicesUpdate onClose={() => setShowUpdateServicesForm(false)} onAdd={onAdd} preselectedServices={services} />}
      {dataLoaded && <button className="primary-btn" onClick={handleToggleUpdateServicesForm}>Modificar</button>}
    </div>
  );
};

export default WorkerProfileServicesList;
