import React, { useState, useEffect, useContext } from 'react';
import AuthContext from '../../../../AuthProvider';

const JobOfferLocationUpdate = ({ locationOptions, onUpdate, error }) => {
  const { authTokens } = useContext(AuthContext);
  const [formData, setFormData] = useState({
    neighborhood: locationOptions?.neighborhood || '',
    municipality: locationOptions?.municipality || null,
    province: locationOptions?.province || null,
  });
  const [provinces, setProvinces] = useState([]);
  const [municipalities, setMunicipalities] = useState([]);
  const [filteredMunicipalities, setFilteredMunicipalities] = useState([]);

  useEffect(() => {
    const fetchLocationChoices = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/provinces-and-municipalities/`, {
          headers: {
            'Authorization': `Bearer ${authTokens.access}`,
          },
        });
        if (response.ok) {
          const data = await response.json();
          setProvinces(data.provinces);
          setMunicipalities(data.municipalities);
          if (formData.province) {
            const filteredMunicipalities = data.municipalities.filter(municipality => municipality.province.id === formData.province);
            setFilteredMunicipalities(filteredMunicipalities);
          }
        } else {
          console.error('Error fetching location choices:', response.statusText);
        }
      } catch (error) {
        console.error('Error fetching location choices:', error.message);
      }
    };
    fetchLocationChoices();
  }, [formData.province, authTokens]);

  useEffect(() => {
    if (formData.province) {
      const filteredMunicipalities = municipalities.filter(municipality => municipality.province.id === formData.province);
      setFilteredMunicipalities(filteredMunicipalities);
    } else {
      setFilteredMunicipalities([]);
      setFormData(prevData => ({
        ...prevData,
        municipality: '',
      }));
    }
  }, [formData.province, municipalities]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    const parsedValue = name === 'province' || name === 'municipality' ? parseInt(value, 10) : value;

    let updatedLocation = {
      ...formData,
      [name]: parsedValue,
    };

    if (name === 'province') {
      updatedLocation.municipality = '';
      setFilteredMunicipalities(municipalities.filter(municipality => municipality.province.id === parsedValue));
    }

    setFormData(updatedLocation);
    onUpdate({ location: updatedLocation });
  };

  return (
    <div className="edit-job-offer-section">
      <div className="edit-job-offer-intro">
        <h3 className="edit-job-offer-title">Ubicación</h3>
        <div className="edit-job-offer-description">
          <p>¿Dónde se encuentra el hogar ubicado?</p>
        </div>
      </div>
      
      <div className="popup-form-rows">
        <div className="popup-select-form-row">
          <select name="province" value={formData.province} onChange={handleChange}>
            <option value="">Selecciona una provincia</option>
            {provinces.map((province) => (
              <option key={province.id} value={province.id}>
                {province.name}
              </option>
            ))}
          </select>
        </div>
        <div className="popup-select-form-row">
          <select name="municipality" value={formData.municipality} onChange={handleChange} disabled={!formData.province}>
            <option value="">Selecciona un partido</option>
            {filteredMunicipalities.map((municipality) => (
              <option key={municipality.id} value={municipality.id}>
                {municipality.name}
              </option>
            ))}
          </select>
        </div>
        <div className="popup-select-form-row">
          <input type="text" placeholder="Barrio" name="neighborhood" value={formData.neighborhood} onChange={handleChange}/> 
        </div>
        <p className="error-message">{error || '\u00A0'}</p>
      </div>
    </div>
  );
};

export default JobOfferLocationUpdate;
