import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import DefaultProfileImage from '../../../images/ProfilePicture/HH_ProfileDefaultImage.png';

const ConversationsSidebar = ({ onSelectConversation, conversations, currentUser, isSidebarOpen, toggleSidebar, activeConversationId }) => {
  return (
    <div className={`chat-sidebar ${isSidebarOpen ? 'open' : 'closed'}`}>
      <button className="toggle-sidebar-button" onClick={toggleSidebar}>
        <FontAwesomeIcon icon={isSidebarOpen ? faChevronLeft : faChevronRight} />
      </button>
      <div className="chat-sidebar-header">
        <h3>Chats</h3>
      </div>
      <ul className="conversation-items">
        {conversations.map(conversation => (
          <li 
            key={conversation.id} 
            className={`conversation-item ${conversation.id === activeConversationId ? 'active' : ''}`} 
            onClick={() => onSelectConversation(conversation.id)}
          >
            <img src={getConversationPartnerPhoto(conversation, currentUser)} alt="Profile" className="profile-pic" />
            <div className="conversation-info">
              <div className="conversation-partner">
                <p>{getConversationPartner(conversation, currentUser)}</p>
              </div>
              <p className="last-message">{getLastMessage(conversation)}</p>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

const getConversationPartner = (conversation, currentUser) => {
  if (conversation.worker.user.id === currentUser.id) {
    return conversation.parent.user.first_name;
  } else if (conversation.parent.user.id === currentUser.id) {
    return conversation.worker.user.first_name;
  }
  return 'Unknown';
};

const getConversationPartnerPhoto = (conversation, currentUser) => {
  if (conversation.worker.user.id === currentUser.id) {
    return conversation.parent.user.profile_image || DefaultProfileImage;
  } else if (conversation.parent.user.id === currentUser.id) {
    return conversation.worker.user.profile_image || DefaultProfileImage;
  }
  return DefaultProfileImage;
};

const getLastMessage = (conversation) => {
  const messages = conversation.messages;
  if (messages && messages.length > 0) {
    const lastMessage = messages[messages.length - 1].content;
    return lastMessage.length > 10 ? lastMessage.substring(0, 15) + "..." : lastMessage;
  }
  return 'Sin mensajes';
};


export default ConversationsSidebar;
