import React from 'react';
import { Helmet } from 'react-helmet';
import HeroSectionImage from '../../images/HomePage/HeroSection/HappyHome_HeroSectionImage.png'


const NotFoundPage = () => {
    return (
        <div className="memberships-page-container">
            <Helmet>
                <title>HappyHome - Sin resultados</title>
                <meta name="description" content="La forma más fácil, rápida y económica de encontrar ayuda integral para tu hogar en Argentina. Ofrecemos servicios de empleada doméstica, niñeras, cuidadores de personas mayores, cuidadores de mascotas y cocineros." />
                <meta name="keywords" content="ayuda en el hogar, empleada doméstica, niñera, cuidador de personas mayores, cuidador de mascotas, cocinera" />
                <meta property="og:title" content="HappyHome - Sin resultados" />
                <meta property="og:description" content="La forma más fácil, rápida y económica de encontrar ayuda integral para tu hogar." />
                <meta property="og:image" content={HeroSectionImage} />
                <meta property="og:url" content="http://www.happyhome.com.ar" />
            </Helmet>
            <div className="section-title">
            <h2>Sin resultados</h2>
            <p>El contenido que estas buscando no existe.</p>
            </div>
        </div>
    );
};

export default NotFoundPage;
