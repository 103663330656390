import React, { useState, useContext } from 'react';
import AuthContext from '../../AuthProvider';

const WorkerProfileHourPriceUpdate = ({ hourPrice, onUpdate, onCancel }) => {
  const [formData, setFormData] = useState(hourPrice);
  const [error, setError] = useState('');
  const { userData, authTokens } = useContext(AuthContext);

  const handleChange = (e) => {
    const value = e.target.value;
    if (/^\d*$/.test(value)) { 
      if (parseInt(value, 10) > 100000) {
        setError('El valor por hora no puede superar los 100000.');
      } else {
        setFormData(value);
        setError('');
      }
    } else {
      setError('Por favor, ingrese un número entero válido.');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formDataString = String(formData);

    if (formDataString.trim() === '') {
      setError('El campo de valor por hora no puede estar vacío.');
      return;
    }
    if (isNaN(formDataString)) {
      setError('El valor por hora debe ser un número.');
      return;
    }
    if (parseFloat(formDataString) <= 0) {
      setError('El valor por hora debe ser mayor que cero.');
      return;
    }
    if (parseFloat(formDataString) > 100000) {
      setError('El valor por hora no puede superar los 100000.');
      return;
    }

    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/worker-profile/${userData.profile_id}/hour-price/update/`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${authTokens?.access}`
        },
        body: JSON.stringify({ hour_price: formDataString }),
      });
      
      if (response.ok) {
        onUpdate(formDataString);
      } else {
        console.error('Error updating hour price:', response.statusText);
      }
    } catch (error) {
      console.error('Error updating hour price:', error.message);
    }
  };

  return (
    <div className="popup-background">
      <div className="popup">
        <div className="popup-content">
          <h3 className="popup-title">Modificar valor por Hora</h3>
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label>¿Cuál es el valor por hora de tu trabajo?</label>
              <input 
                type="text" 
                value={formData} 
                onChange={handleChange} 
                placeholder="$" 
              />
            </div>
            <p className="error-message">{error || '\u00A0'}</p>
            <div className="buttons-container">
              <button className="primary-btn" type="submit">Guardar Cambios</button>
              <button className="secondary-btn" onClick={onCancel}>Cancelar</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default WorkerProfileHourPriceUpdate;
