class ProfileVisibilityWebSocketService {
    static instance = null;
  
    constructor() {
      this.socketRef = null;
    }
  
    static getInstance() {
      if (!ProfileVisibilityWebSocketService.instance) {
        ProfileVisibilityWebSocketService.instance = new ProfileVisibilityWebSocketService();
      }
      return ProfileVisibilityWebSocketService.instance;
    }
  
    connect(onMessage, onClose, onError) {
      const authTokens = JSON.parse(localStorage.getItem('authTokens'));
      if (!authTokens || !authTokens.access) {
        console.error('No access token found');
        return;
      }
      const accessToken = authTokens.access;
      const wsBaseUrl = process.env.REACT_APP_WS_BASE_URL;
      this.socketRef = new WebSocket(`${wsBaseUrl}/profile_visibility/?token=${accessToken}`);
  
      this.socketRef.onopen = () => {
        console.log('Profile Visibility WebSocket connected');
      };
  
      this.socketRef.onmessage = (event) => {
        console.log('Profile Visibility WebSocket message received:', event.data);
        const data = JSON.parse(event.data);
        onMessage(data);
      };
  
      this.socketRef.onclose = () => {
        console.log('Profile Visibility WebSocket closed');
        if (onClose) onClose();
      };
  
      this.socketRef.onerror = (error) => {
        console.error('Profile Visibility WebSocket error:', error);
        if (onError) onError(error);
      };
    }
  
    sendMessage(data) {
      if (this.socketRef && this.socketRef.readyState === WebSocket.OPEN) {
        this.socketRef.send(JSON.stringify(data));
      } else {
        console.error('Profile Visibility WebSocket is not open.');
      }
    }
  
    disconnect() {
      if (this.socketRef) {
        this.socketRef.close();
      }
    }
  }
  
  export default ProfileVisibilityWebSocketService.getInstance();
  