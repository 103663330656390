import React, { useState, useContext } from 'react';
import AuthContext from '../../AuthProvider';

const WorkerProfileExperienceUpdate = ({ experience, onUpdate, onCancel }) => {
  const { authTokens } = useContext(AuthContext);
  const [formData, setFormData] = useState({ ...experience });
  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    const newValue = type === 'checkbox' ? checked : value;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: newValue,
    }));
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.title.trim()) {
      newErrors.title = 'Este campo es obligatorio';
    } else if (formData.title.trim().length < 3 || formData.title.trim().length > 80) {
      newErrors.title = 'El título debe tener entre 3 y 80 caracteres';
    }
    if (!formData.description.trim()) {
      newErrors.description = 'Este campo es obligatorio';
    } else if (formData.description.trim().length < 10 || formData.description.trim().length > 500) {
      newErrors.description = 'La descripción debe tener entre 10 y 500 caracteres';
    }
    return newErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const newErrors = validateForm();

    if (Object.keys(newErrors).length === 0) {
      try {
        const finalFormData = { ...formData };
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/worker-profile/experience/${formData.id}/update/`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authTokens?.access}`
          },
          body: JSON.stringify(finalFormData),
        });

        if (response.ok) {
          const updatedExperience = await response.json();
          onUpdate(updatedExperience);
        } else {
          console.error('Error al actualizar la experiencia:', response.statusText);
        }
      } catch (error) {
        console.error('Error al actualizar la experiencia:', error.message);
      }
    } else {
      setErrors(newErrors);
    }
  };

  return (
    <div className="popup-background">
      <div className="popup">
        <div className="popup-content">
          <div className="popup-intro">
            <h3 className="popup-title">Modificar experiencia laboral</h3>
            <div className="popup-intro-description">
              <p>Tener experiencia laboral comprobable te ayudará en el proceso de selección</p>
            </div>
          </div>

          <form className="popup-form" onSubmit={handleSubmit}>
            <div className="popup-form-rows">
              <div className="popup-form-double-col">
                <div className={`popup-basic-form-row ${errors.currently_working ? 'missing-field' : ''}`}>
                  <label>¿Estás trabajando aquí actualmente?</label>
                  <select name="currently_working" value={formData.currently_working} onChange={handleChange}>
                    <option value={true}>Sí</option>
                    <option value={false}>No</option>
                  </select>
                </div>

                <div className={`popup-basic-form-row ${errors.title ? 'missing-field' : ''}`}>
                  <label>Título</label>
                  <input type="text" name="title" value={formData.title} onChange={handleChange} />
                  <p className="error-message">{errors.title || '\u00A0'}</p>
                </div>
              </div>

              <div className="popup-basic-form-row">
                <label>Referencia (opcional)</label>
                <input type="text" name="reference" value={formData.reference} onChange={handleChange} />
              </div>

              <div className={`popup-basic-form-row ${errors.description ? 'missing-field' : ''}`}>
                <label>¿Qué tareas realizas o realizabas en el trabajo?</label>
                <textarea name="description" value={formData.description} onChange={handleChange} />
                <p className="error-message">{errors.description || '\u00A0'}</p>
              </div>

              <div className="buttons-container">
                <button className="primary-btn" type="submit">Guardar Cambios</button>
                <button className="secondary-btn" onClick={onCancel}>Cancelar</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default WorkerProfileExperienceUpdate;
