import React, { useState, useContext, useEffect } from 'react';
import AuthContext from '../AuthProvider';
import { useNavigate } from 'react-router-dom';
import UserAccountDetailsUpdate from './UserAccountDetailsUpdate';
import LoadingSpinner from '../../../common/components/LoadingSpinner';
import ResendVerificationButton from '../../components/ResendVerificationButton';

const UserAccountDetails = () => {
  const { authTokens, logoutUser } = useContext(AuthContext);
  const [isUpdating, setIsUpdating] = useState(false);
  const [userData, setUserData] = useState(null);
  const [loadingData, setLoadingData] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        if (authTokens && authTokens.access) {
          const userResponse = await fetch(`${process.env.REACT_APP_API_BASE_URL}/user/get-data/`, {
            method: 'GET',
            headers: {
              'Authorization': `Bearer ${authTokens.access}`,
            },
          });

          if (userResponse.ok) {
            const userData = await userResponse.json();
            setUserData(userData);
          } else {
            throw new Error('Failed to fetch user details');
          }
        } else {
          throw new Error('Access token is missing');
        }
      } catch (error) {
        console.error('Error fetching user details:', error);
        setError('Error al cargar los detalles del usuario');
      } finally {
        setLoadingData(false);
      }
    };

    fetchUserData();
  }, [authTokens]);

  const handleModifyProfile = () => {
    setIsUpdating(true);
  };

  const handleDeleteProfile = async () => {
    const confirmDelete = window.confirm('¿Estás seguro de que quieres eliminar tu cuenta?');
    if (confirmDelete) {
      try {
        if (authTokens && authTokens.access) {
          const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/user/${userData.id}/delete/`, {
            method: 'DELETE',
            headers: {
              'Authorization': `Bearer ${authTokens.access}`,
            },
          });
          if (response.ok) {
            logoutUser();
            navigate('/');
          } else {
            const errorData = await response.json();
            const errorText = errorData.error;
            console.error('Error:', errorText);
            setError(`Error: ${errorText}`);
          }
        } else {
          throw new Error('Access token is missing');
        }
      } catch (error) {
        console.error('Error:', error.message);
        setError(`Error: ${error.message}`);
      }
    }
  };

  const onUpdate = async (updatedUser) => {
    setUserData(updatedUser);
  };

  return (
    <div className="profile-edit-section">
      <h2 className="profile-edit-section-title">Datos de la cuenta</h2>
      {loadingData ? (
        <LoadingSpinner />
      ) : (
        <div>
          <div className="profile-edit-section-content">
            <div className="profile-edit-section-info">
              <p><strong>Nombre:</strong> {userData ? `${userData?.first_name}` : 'No disponible'}</p>
              <p><strong>Apellido:</strong> {userData ? `${userData?.last_name} (Privado)` : 'No disponible'}</p>
              <p><strong>Email:</strong> {userData ? userData?.email : 'No disponible'}</p>
              <div>
                {userData && !userData.email_verified && (
                  <div className="email-verification-content">
                    <strong>Email sin verificar: </strong>
                    <ResendVerificationButton email={userData.email} />
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="buttons-container">
            <button className="primary-btn" onClick={handleModifyProfile}>Modificar</button>
            <button className="secondary-btn" onClick={handleDeleteProfile}>Eliminar cuenta</button>
          </div>
        </div>
      )}

      {error && <p className="error-message">{error}</p>}
      {isUpdating && <UserAccountDetailsUpdate userData={userData} onUpdate={onUpdate} onClose={() => setIsUpdating(false)} />}
    </div>
  );
};

export default UserAccountDetails;

