import React, { useState, useEffect, useContext } from 'react';
import checkIcon from '../../../../../../images/Vectors/HappyHome_CheckIcon.svg';
import crossIcon from '../../../../../../images/Vectors/HappyHome_CrossIcon.svg';
import AuthContext from '../../../../AuthProvider';

const JobOfferTasksAdd = ({ tasks, setTasks }) => {
  const { authTokens } = useContext(AuthContext);
  const [taskChoices, setTaskChoices] = useState([]);

  useEffect(() => {
    const fetchTaskChoices = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/tasks/get-choices/`, {
          headers: {
            'Authorization': `Bearer ${authTokens.access}`,
          },
        });
        if (response.ok) {
          const data = await response.json();
          setTaskChoices(data);
        } else {
          console.error('Error fetching task choices:', response.statusText);
        }
      } catch (error) {
        console.error('Error fetching task choices:', error.message);
      }
    };
    fetchTaskChoices();
  }, [authTokens]);

  const handleTaskChange = (taskId) => {
    const updatedTasks = [...tasks];
    const isSelected = updatedTasks.includes(taskId);
    if (isSelected) {
      setTasks(updatedTasks.filter(id => id !== taskId));
    } else {
      setTasks([...updatedTasks, taskId]);
    }
  };

  return (
    <div className="edit-job-offer-section">
      <div className="edit-job-offer-intro">
        <h3 className="edit-job-offer-title">Tareas</h3>
        <div className="edit-job-offer-description">
          <p>Selecciona las tareas que necesitas que la persona contratada realice</p>
        </div>
      </div>
      <div className="popup-form-rows">
        {taskChoices.map(task => (
          <div key={task.id} className="popup-select-form-row" onClick={() => handleTaskChange(task.id)}>
            <div className="popup-form-label">
              <label>{task.title_display}</label>
            </div>
            <div className="popup-form-icon">
              <img
                src={tasks.includes(task.id) ? checkIcon : crossIcon}
                alt="Icon"
                className="icon-svg"
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default JobOfferTasksAdd;
