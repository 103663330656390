import React, { useState, useEffect } from 'react';
import LocationProvinceFilter from './LocationProvinceFilter';
import LocationMunicipalityFilter from './LocationMunicipalityFilter';

const LocationFilter = ({ provinces, municipalities, handleProvinceChange, handleMunicipalityChange, clearFilter }) => {
  const [selectedProvince, setSelectedProvince] = useState(null);
  const [selectedMunicipality, setSelectedMunicipality] = useState('');

  useEffect(() => {
    if (clearFilter) {
      setSelectedProvince(null);
      setSelectedMunicipality('');
    }
  }, [clearFilter]);

  const handleProvinceSelect = (provinceId) => {
    setSelectedProvince(provinceId);
    handleProvinceChange(provinceId);
    setSelectedMunicipality('');
    handleMunicipalityChange('');
  };

  const handleMunicipalitySelect = (municipalityId) => {
    setSelectedMunicipality(municipalityId);
    handleMunicipalityChange(municipalityId);
  };

  return (
    <div className="filter-container">
      <div className="location-filter">
        <LocationProvinceFilter
          provinces={provinces}
          handleProvinceChange={handleProvinceSelect}
          selectedProvince={selectedProvince}
          setSelectedProvince={setSelectedProvince}
          clearFilter={clearFilter}
        />
        <LocationMunicipalityFilter
          municipalities={municipalities}
          handleMunicipalityChange={handleMunicipalitySelect}
          selectedProvince={parseInt(selectedProvince)}
          selectedMunicipality={selectedMunicipality}
          setSelectedMunicipality={setSelectedMunicipality}
        />
      </div>
    </div>
  );
};

export default LocationFilter;
