import React, { useState, useContext, useEffect } from 'react';
import AuthContext from '../../AuthProvider';
import JobOfferTitleAdd from './JobOffer/JobOfferTitle/JobOfferTitleAdd';
import JobOfferDescriptionAdd from './JobOffer/JobOfferDescription/JobOfferDescriptionAdd';
import JobOfferServicesAdd from './JobOffer/JobOfferService/JobOfferServicesAdd';
import JobOfferTasksAdd from './JobOffer/JobOfferTask/JobOfferTasksAdd';
import JobOfferWorkArrangementsAdd from './JobOffer/JobOfferWorkArrangement/JobOfferWorkArrangementsAdd';
import JobOfferLanguagesAdd from './JobOffer/JobOfferLanguage/JobOfferLanguagesAdd';
import JobOfferEducationTypesAdd from './JobOffer/JobOfferEducationType/JobOfferEducationTypesAdd';
import JobOfferShiftsAdd from './JobOffer/JobOfferShift/JobOfferShiftsAdd';
import JobOfferMonthlyPriceAdd from './JobOffer/JobOfferMonthlyPrice/JobOfferMonthlyPriceAdd';
import JobOfferExperienceAdd from './JobOffer/JobOfferExperience/JobOfferExperienceAdd';
import JobOfferLocationAdd from './JobOffer/JobOfferLocation/JobOfferLocationAdd';
import LoadingSpinner from '../../../../common/components/LoadingSpinner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

const ParentProfileJobOfferAdd = ({ onClose, onAdd }) => {
  const { userData, authTokens } = useContext(AuthContext);
  const [formData, setFormData] = useState({
    title: '',
    description: '',
    work_experience: {
      experience_required: false,
      references_required: false,
    },
    services: [],
    tasks: [],
    work_arrangements: [],
    languages: [],
    native_language: null,
    education_types: [],
    work_shifts: [],
    location: {
      neighborhood: '',
      province: null,
      municipality: null,
    },
    monthly_price: null,
  });

  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);

  useEffect(() => {
    const validateForm = () => {
      const newErrors = {};
      const maxTitleLength = 35;
      const maxDescriptionLength = 500;

      if (!formData.title) {
        newErrors.title = 'El título es obligatorio.';
      } else if (formData.title.length > maxTitleLength) {
        newErrors.title = `El título no puede exceder los ${maxTitleLength} caracteres.`;
      }
      if (!formData.description) {
        newErrors.description = 'La presentación es obligatoria.';
      } else if (formData.description.length > maxDescriptionLength) {
        newErrors.description = `La presentación no puede exceder los ${maxDescriptionLength} caracteres.`;
      }

      if (!formData.location.province) newErrors.province = 'Todos los campos de ubicación son obligatorios';
      if (!formData.location.municipality) newErrors.municipality = 'Todos los campos de ubicación son obligatorios';
      if (!formData.location.neighborhood) newErrors.neighborhood = 'Todos los campos de ubicación son obligatorios';
      if (formData.services.length === 0) newErrors.services = 'Debe seleccionar al menos un servicio';
      if (formData.work_arrangements.length === 0) newErrors.work_arrangements = 'Debe seleccionar al menos una modalidad de trabajo';

      setErrors(newErrors);
      setIsFormValid(Object.keys(newErrors).length === 0);
    };

    validateForm();
  }, [formData]);

  const handleAddFormData = (field, value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [field]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!isFormValid) {
      return;
    }

    setLoading(true);

    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/parent-profile/${userData.profile_id}/job-offers/add/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authTokens?.access}`,
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        const data = await response.json();
        onAdd(data);
        onClose();
      } else {
        console.error('Error al agregar oferta de trabajo:', response.statusText);
      }
    } catch (error) {
      console.error('Error al agregar oferta de trabajo:', error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="popup-background">
      <div className="popup job-offer">
        {!loading && (
          <div className="popup-header">
            <div className="popup-header-close">
              <button className="close-button" onClick={onClose}>
                <FontAwesomeIcon icon={faTimes} />
              </button>
            </div>
          </div>
        )}
        <div className="popup-content">
          {loading ? (
            <LoadingSpinner />
          ) : (
            <form className="popup-form" onSubmit={handleSubmit}>
              <div className="popup-job-offer-form">
                <div className="popup-form-col">
                  <JobOfferTitleAdd
                    title={formData.title}
                    setTitle={(value) => handleAddFormData('title', value)}
                    error={errors.title}
                  />
                  <JobOfferServicesAdd
                    services={formData.services}
                    setServices={(value) => handleAddFormData('services', value)}
                    error={errors.services}
                  />
                  <JobOfferLocationAdd
                    location={formData.location}
                    setLocation={(value) => handleAddFormData('location', value)}
                    error={errors.province || errors.municipality || errors.neighborhood}
                  />
                  <JobOfferWorkArrangementsAdd
                    workArrangements={formData.work_arrangements}
                    setWorkArrangements={(value) => handleAddFormData('work_arrangements', value)}
                    error={errors.work_arrangements}
                  />
                  <JobOfferExperienceAdd
                    workExperience={formData.work_experience}
                    setWorkExperience={(value) => handleAddFormData('work_experience', value)}
                  />
                  <JobOfferEducationTypesAdd
                    educationTypes={formData.education_types}
                    setEducationTypes={(value) => handleAddFormData('education_types', value)}
                  />
                  <JobOfferLanguagesAdd
                    languages={formData.languages}
                    setLanguages={(value) => handleAddFormData('languages', value)}
                    nativeLanguage={formData.native_language}
                    setNativeLanguage={(value) =>
                      setFormData((prevFormData) => ({ ...prevFormData, native_language: value }))
                    }
                  />
                </div>
                <div className="popup-form-col">
                  <JobOfferTasksAdd tasks={formData.tasks} setTasks={(value) => handleAddFormData('tasks', value)} />
                  <JobOfferDescriptionAdd
                    description={formData.description}
                    setDescription={(value) => handleAddFormData('description', value)}
                    error={errors.description}
                  />
                  <JobOfferShiftsAdd
                    workShifts={formData.work_shifts}
                    setWorkShifts={(value) => handleAddFormData('work_shifts', value)}
                  />
                  <JobOfferMonthlyPriceAdd
                    monthlyPrice={formData.monthly_price}
                    setMonthlyPrice={(value) => handleAddFormData('monthly_price', value)}
                  />
                </div>
              </div>
              <div className="popup-footer">
                <p className="error-message">{Object.keys(errors).length > 0 ? 'Completa los campos obligatorios' : '\u00A0'}</p>
              </div>
              <div className="buttons-container">
                <button className="primary-btn" type="submit" disabled={!isFormValid}>
                  Crear
                </button>
                <button className="secondary-btn" onClick={onClose}>
                  Cancelar
                </button>
              </div>
            </form>
          )}
        </div>
      </div>
    </div>
  );
};

export default ParentProfileJobOfferAdd;
