import React, { useState, useEffect, useContext } from 'react';
import AdminPanelMembershipTypeUpdate from './AdminPanelMembershipTypeUpdate';
import AuthContext from '../../../base/components/AuthProvider';

const AdminPanelMembershipTypesList = () => {
  const { authTokens } = useContext(AuthContext);
  const [membership_types, setMembershipTypes] = useState([]);
  const [selectedMembershipType, setSelectedMembershipType] = useState(null);
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    const fetchMembershipTypes = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/admin/membership-types/get-all/`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${authTokens.access}`,
          },
        });
        if (response.ok) {
          const data = await response.json();
          setMembershipTypes(data);
        } else {
          console.error('Error al obtener los tipos de membresía:', response.statusText);
        }
      } catch (error) {
        console.error('Error al obtener los tipos de membresía:', error.message);
      }
    };

    fetchMembershipTypes();
  }, [authTokens.access]);

  const handleButtonClick = (membership_type) => {
    setSelectedMembershipType(membership_type);
    setShowPopup(true);
  };

  const handleClosePopup = () => {
    setShowPopup(false);
    setSelectedMembershipType(null);
  };

  const onUpdate = (updated_membership_type) => {
    const updatedMembershipTypes = membership_types.map((type) => {
      if (type.id === updated_membership_type.id) {
        return updated_membership_type;
      }
      return type;
    });
    setMembershipTypes(updatedMembershipTypes);
    setSelectedMembershipType(null);
    setShowPopup(false);
  };

  return (
    <div className="membership-types-container">

      <div className="membership-types-buttons">
        {membership_types.map(membership_type => (
          <button 
            key={membership_type.id} 
            className="primary-btn"
            onClick={() => handleButtonClick(membership_type)}
          >
            Editar {membership_type.name}
          </button>
        ))}
      </div>
      {showPopup && (
        <AdminPanelMembershipTypeUpdate 
          membershipType={selectedMembershipType} 
          onClose={handleClosePopup}
          onUpdate={onUpdate}
        />
      )}
    </div>
  );
};

export default AdminPanelMembershipTypesList;
