import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';
import HappyHomeLogo from '../../../images/Logo/HappyHomeLogo.png';
import '../../AdminApp.css';

function AdminNavbar({ logoutUser }) {
  const [isResponsive, setIsResponsive] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  const toggleResponsiveMenu = () => {
    setIsResponsive(!isResponsive);
  };

  const handleLogout = () => {
    logoutUser();
    navigate('/', { replace: true });
    window.location.reload();
  };  

  useEffect(() => {
    setIsResponsive(false);
    window.scrollTo(0, 0);
  }, [location]);

  const isActive = (path) => {
    return location.pathname === path ? 'active' : '';
  };

  return (
    <div className={`navbar ${isResponsive ? 'responsive' : ''}`}>
      <div className="navbar-advice">
        <p>PANEL ADMINISTRADOR</p>
      </div>
      <div className="navbar-content">
        <div className="navbar-left">
          <div className="navbar-left-logo">
            <Link to="/">
              <img alt="HHLogo" src={HappyHomeLogo} />
            </Link>
          </div>
        </div>
        <div className="navbar-right-background">
          <div className="navbar-right">
            <Link to="/admin/workers" className={`navbar-link ${isActive('/admin/workers')}`}>
              Perfiles
            </Link>
            <Link to="/admin/parents" className={`navbar-link ${isActive('/admin/parents')}`}>
              Padres
            </Link>
            <Link to="/admin/job-offers" className={`navbar-link ${isActive('/admin/job-offers')}`}>
              Empleos
            </Link>
            <Link to="/admin/memberships" className={`navbar-link ${isActive('/admin/memberships')}`}>
              Membresias
            </Link>
            <Link to="/admin/payments" className={`navbar-link ${isActive('/admin/payments')}`}>
              Pagos
            </Link>
            <Link to="/admin/stats" className={`navbar-link ${isActive('/admin/stats')}`}>
              Estadísticas
            </Link>
            <button className="navbar-button" onClick={handleLogout}>
              Cerrar sesión
            </button>
          </div>
        </div>
        <button className="navbar-toggle" onClick={toggleResponsiveMenu}>
          <FontAwesomeIcon icon={isResponsive ? faTimes : faBars} />
        </button>
      </div>
    </div>
  );
}

export default AdminNavbar;
