import React from 'react';

const NoConversationsMessage = ({ message, link, linkText }) => (
  <div className="no-conversations-message">
    <h2>Aun no tienes chats</h2>
    <p>{message}<a href={link}>{linkText}</a>.</p>
  </div>
);

export default NoConversationsMessage;
