import React, { useState, useEffect, useContext } from 'react';
import AuthContext from '../../AuthProvider';
import checkIcon from '../../../../images/Vectors/HappyHome_CheckIcon.svg';
import crossIcon from '../../../../images/Vectors/HappyHome_CrossIcon.svg';

const WorkerProfileShiftUpdate = ({ onClose, shifts, onUpdate }) => {
  const { userData, authTokens } = useContext(AuthContext);
  const daysOfWeek = ['Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado', 'Domingo'];
  const shiftsOfDay = ['Mañana', 'Tarde', 'Noche'];
  const [selectedCells, setSelectedCells] = useState([]);
  const [initialSelectedCells, setInitialSelectedCells] = useState([]);

  useEffect(() => {
    const initialCells = shifts.map(shift => ({
      day: shift.day.toLowerCase(),
      shift: shift.shift.toLowerCase()
    }));
    setSelectedCells(initialCells);
    setInitialSelectedCells(initialCells);
  }, [shifts]);

  const isCellSelected = (day, shift) => {
    return selectedCells.some(cell => cell.day === day.toLowerCase() && cell.shift === shift.toLowerCase());
  };

  const toggleRowSelection = (day) => {
    const allDayShifts = shiftsOfDay.map(shift => ({ day: day.toLowerCase(), shift: shift.toLowerCase() }));
    const isRowSelected = allDayShifts.every(cell => isCellSelected(cell.day, cell.shift));
    if (isRowSelected) {
      setSelectedCells(prevCells => prevCells.filter(cell => !allDayShifts.some(selected => selected.day === cell.day && selected.shift === cell.shift)));
    } else {
      setSelectedCells(prevCells => [...prevCells, ...allDayShifts.filter(cell => !prevCells.some(selected => selected.day === cell.day && selected.shift === cell.shift))]);
    }
  };

  const toggleColumnSelection = (shift) => {
    const allShiftDayCells = daysOfWeek.map(day => ({ day: day.toLowerCase(), shift: shift.toLowerCase() }));
    const isColumnSelected = allShiftDayCells.every(cell => isCellSelected(cell.day, cell.shift));
    if (isColumnSelected) {
      setSelectedCells(prevCells => prevCells.filter(cell => !allShiftDayCells.some(selected => selected.day === cell.day && selected.shift === cell.shift)));
    } else {
      setSelectedCells(prevCells => [...prevCells, ...allShiftDayCells.filter(cell => !prevCells.some(selected => selected.day === cell.day && selected.shift === cell.shift))]);
    }
  };

  const handleCellClick = (day, shift) => {
    const isSelected = isCellSelected(day, shift);
    if (isSelected) {
      setSelectedCells(prevCells =>
        prevCells.filter(cell => !(cell.day === day.toLowerCase() && cell.shift === shift.toLowerCase()))
      );
    } else {
      setSelectedCells(prevCells => [...prevCells, { day: day.toLowerCase(), shift: shift.toLowerCase() }]);
    }
  };

  const handleSubmit = async () => {
    try {
      if (selectedCells.length === 0) {
        console.error('No se han seleccionado celdas.');
        return;
      }

      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/worker-profile/${userData.profile_id}/shifts/update/`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${authTokens?.access}`
        },
        body: JSON.stringify(selectedCells),
      });

      if (response.ok) {
        onUpdate();
        onClose();
      } else {
        console.error('Error updating shifts:', response.statusText);
      }
    } catch (error) {
      console.error('Error updating shifts:', error);
    }
  };

  const hasChanges = () => {
    return (
      selectedCells.length !== initialSelectedCells.length ||
      selectedCells.some(cell => !initialSelectedCells.some(initial => initial.day === cell.day && initial.shift === cell.shift)) ||
      initialSelectedCells.some(cell => !selectedCells.some(selected => selected.day === cell.day && selected.shift === cell.shift))
    );
  };

  return (
    <div className="popup-background">
      <div className="popup">
        <div className="popup-content">
          <div className="popup-intro">
            <h3 className="popup-title">Modificar disponibilidad</h3>
            <div className="popup-intro-description">
              <p>¿Qué días y qué horarios estás disponibles?</p>
            </div>
          </div>
          <div className="worker-profile-shift-update">
            <div className="update-calendar-container">
              <table className="update-calendar">
                <thead>
                  <tr>
                    <th></th>
                    <th className="update-calendar-col" onClick={() => toggleColumnSelection('Mañana')}>Mañana</th>
                    <th className="update-calendar-col" onClick={() => toggleColumnSelection('Tarde')}>Tarde</th>
                    <th className="update-calendar-col" onClick={() => toggleColumnSelection('Noche')}>Noche</th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="spacer-row"></tr>
                  {daysOfWeek.map(day => (
                    <tr className="update-calendar-tr" key={day}>
                      <td className="update-calendar-row-day" onClick={() => toggleRowSelection(day)}>
                        {day}
                      </td>
                      {shiftsOfDay.map(shift => (
                        <td className="update-calendar-row" key={`${day}-${shift}`} onClick={() => handleCellClick(day, shift)}>
                          <div className="update-calendar-row-cell">
                            <div className="update-calendar-row-icon"> 
                              {isCellSelected(day, shift) ? (
                                <img src={checkIcon} alt="Check Icon" className="icon-svg" />
                              ) : (
                                <img src={crossIcon} alt="Cross Icon" className="icon-svg" />
                              )}
                            </div>
                          </div>
                        </td>
                      ))}
                        
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="buttons-container">
              <button className="primary-btn" onClick={handleSubmit} disabled={!hasChanges()}>Guardar</button>
              <button className="secondary-btn" onClick={onClose}>Cancelar</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WorkerProfileShiftUpdate;
