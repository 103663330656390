import React, { useState, useEffect, useContext } from 'react';
import checkIcon from '../../../../../../images/Vectors/HappyHome_CheckIcon.svg';
import crossIcon from '../../../../../../images/Vectors/HappyHome_CrossIcon.svg';
import AuthContext from '../../../../AuthProvider';

const JobOfferWorkArrangementsAdd = ({ workArrangements, setWorkArrangements, error }) => {
  const { authTokens } = useContext(AuthContext);
  const [workArrangementChoices, setWorkArrangementChoices] = useState([]);

  useEffect(() => {
    const fetchWorkArrangementChoices = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/work-arrangements/get-choices/`, {
          headers: {
            'Authorization': `Bearer ${authTokens.access}`, 
          },
        });
        if (response.ok) {
          const data = await response.json();
          setWorkArrangementChoices(data);
        } else {
          console.error('Error fetching work arrangement choices:', response.statusText);
        }
      } catch (error) {
        console.error('Error fetching work arrangement choices:', error.message);
      }
    };
    fetchWorkArrangementChoices();
  }, [authTokens]);

  const handleWorkArrangementChange = (workArrangementId) => {
    const updatedWorkArrangements = [...workArrangements];
    const isSelected = updatedWorkArrangements.includes(workArrangementId);
    if (isSelected) {
      const index = updatedWorkArrangements.indexOf(workArrangementId);
      updatedWorkArrangements.splice(index, 1);
    } else {
      updatedWorkArrangements.push(workArrangementId);
    }
    setWorkArrangements(updatedWorkArrangements);
  };

  return (
    <div className="edit-job-offer-section">
      <div className="edit-job-offer-intro">
        <h3 className="edit-job-offer-title">Modalidad de trabajo</h3>
        <div className="edit-job-offer-description">
          <p>Selecciona el o los formatos en los que necesitas que la persona contratada esté disponible para trabajar</p>
        </div>
      </div>

      <div className="popup-form-rows">
        {workArrangementChoices.map(workArrangement => (
          <div key={workArrangement.id} className="popup-select-form-row" onClick={() => handleWorkArrangementChange(workArrangement.id)}>
            <div className="popup-form-label">
              <label>{workArrangement.value_display}</label>
            </div>
            <div className="popup-form-icon">
              <img
                src={workArrangements.includes(workArrangement.id) ? checkIcon : crossIcon}
                alt="Icon"
                className="icon-svg"
              />
            </div>
          </div>
        ))}
        <p className="error-message">{error || '\u00A0'}</p>
      </div>
    </div>
  );
};

export default JobOfferWorkArrangementsAdd;
