import React, { useState, useEffect, useContext } from 'react';
import AuthContext from '../../AuthProvider';
import checkIcon from '../../../../images/Vectors/HappyHome_CheckIcon.svg';
import crossIcon from '../../../../images/Vectors/HappyHome_CrossIcon.svg';
import LoadingSpinner from '../../../../common/components/LoadingSpinner';

const WorkerProfileTasksUpdate = ({ onClose, onAdd, preselectedTasks }) => {
  const { authTokens, userData } = useContext(AuthContext);
  const [selectedTasks, setSelectedTasks] = useState(preselectedTasks.map(task => task.id));
  const [taskChoices, setTaskChoices] = useState([]);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isChanged, setIsChanged] = useState(false);

  useEffect(() => {
    const fetchTaskChoices = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/worker-profile/tasks/get-choices/`, {
          headers: {
            'Authorization': `Bearer ${authTokens?.access}`,
          },
        });
        if (response.ok) {
          const data = await response.json();
          setTaskChoices(data.tasks);
        } else {
          console.error('Error fetching task choices:', response.statusText);
        }
      } catch (error) {
        console.error('Error fetching task choices:', error.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchTaskChoices();
  }, [authTokens]);

  const handleTaskChange = (taskId) => {
    setSelectedTasks(prevSelectedTasks => {
      const newSelectedTasks = prevSelectedTasks.includes(taskId)
        ? prevSelectedTasks.filter(id => id !== taskId)
        : [...prevSelectedTasks, taskId];

      setIsChanged(
        newSelectedTasks.length !== preselectedTasks.length ||
        newSelectedTasks.some(id => !preselectedTasks.some(task => task.id === id))
      );

      return newSelectedTasks;
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (selectedTasks.length === 0) {
      setError('Debes seleccionar al menos una tarea.');
      return;
    }
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/worker-profile/${userData.profile_id}/tasks/update/`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${authTokens?.access}`,
        },
        body: JSON.stringify({ task_ids: selectedTasks }),
      });
      if (response.ok) {
        const data = await response.json();
        onAdd(data.tasks);
        onClose();
      } else {
        console.error('Error al actualizar tarea:', response.statusText);
      }
    } catch (error) {
      console.error('Error al actualizar tarea:', error.message);
    }
  };

  return (
    <div className="popup-background">
      <div className="popup">
        <div className="popup-content">
          <div className="popup-intro">
            <h3 className="popup-title">¿Qué tareas puedes realizar?</h3>
            <div className="popup-intro-description">
              <p>Marca todas las tareas que quieres ofrecer</p>
            </div>
          </div>

          {isLoading ? (
            <LoadingSpinner />
          ) : (
            <form className="popup-form" onSubmit={handleSubmit}>
              <div className="popup-form-rows">
                {taskChoices.map(task => (
                  <div className="popup-select-form-row" key={task.id} onClick={() => handleTaskChange(task.id)}>
                    <div className="popup-form-label">
                      <label>{task.title_display}</label>
                    </div>
                    {selectedTasks.includes(task.id) ? (
                      <div className="popup-form-icon"><img src={checkIcon} alt="Check Icon" className="icon-svg" /></div>
                    ) : (
                      <div className="popup-form-icon"><img src={crossIcon} alt="Cross Icon" className="icon-svg" /></div>
                    )}
                  </div>
                ))}
              </div>
              <p className="error-message">{error || '\u00A0'}</p>
              <div className="buttons-container">
                <button className="primary-btn" type="submit" disabled={!isChanged}>Guardar</button>
                <button className="secondary-btn" onClick={onClose}>Cancelar</button>
              </div>
            </form>
          )}
        </div>
      </div>
    </div>
  );
};

export default WorkerProfileTasksUpdate;
