import React, { useContext, useEffect, useState, useCallback } from 'react';
import AuthContext from '../../AuthProvider';
import DefaultProfileImage from '../../../../images/ProfilePicture/HH_ProfileDefaultImage.png';
import LoadingSpinner from '../../../../common/components/LoadingSpinner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faPlus, faSyncAlt } from '@fortawesome/free-solid-svg-icons';

const ParentProfileImage = ({ parentProfileId }) => {
    const { userData, authTokens } = useContext(AuthContext);
    const [image, setImage] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [isProcessing, setIsProcessing] = useState(false);

    const fetchImage = useCallback(async () => {
        setIsLoading(true);
        try {
            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/user/${userData.user_id}/profile-image/get/`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${authTokens?.access}`
                },
            });

            if (response.ok) {
                const data = await response.json();
                setImage(data.image);
            } else {
                console.error('Failed to fetch parent profile image:', response.statusText);
            }
        } catch (error) {
            console.error('Error fetching parent profile image:', error.message);
        } finally {
            setIsLoading(false);
        }
    }, [authTokens?.access, userData.user_id]);

    useEffect(() => {
        fetchImage();
    }, [fetchImage]);

    const handleImageDelete = async () => {
        if (!image) return;

        setIsProcessing(true);
        try {
            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/user/${userData.user_id}/profile-image/delete/`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${authTokens?.access}`
                },
            });

            if (response.ok) {
                setImage(null);
            } else {
                console.error('Failed to delete parent profile image:', response.statusText);
            }
        } catch (error) {
            console.error('Error deleting parent profile image:', error.message);
        } finally {
            setIsProcessing(false);
        }
    };

    const handleImageChange = async (e) => {
        const selectedImage = e.target.files[0];
        if (!selectedImage) return;

        const maxSizeInMB = 1;
        const maxSizeInBytes = maxSizeInMB * 1024 * 1024;
        if (selectedImage.size > maxSizeInBytes) {
            alert('El tamaño de la imagen no debe exceder 1MB de peso.');
            return;
        }

        const img = new Image();
        const reader = new FileReader();
        reader.onload = (event) => {
            img.src = event.target.result;
        };
        reader.readAsDataURL(selectedImage);

        img.onload = () => {
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');
            const size = Math.min(img.width, img.height);
            canvas.width = size;
            canvas.height = size;
            ctx.drawImage(img, 0, 0, size, size);
            canvas.toBlob(async (blob) => {
                const formData = new FormData();
                formData.append('image', blob, selectedImage.name);

                setIsProcessing(true);
                try {
                    const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/user/${userData.user_id}/profile-image/upload/`, {
                        method: 'POST',
                        headers: {
                            'Authorization': `Bearer ${authTokens?.access}`
                        },
                        body: formData
                    });

                    if (response.ok) {
                        fetchImage();
                    } else {
                        console.error('Failed to upload parent profile image:', response.statusText);
                    }
                } catch (error) {
                    console.error('Error uploading parent profile image:', error.message);
                } finally {
                    setIsProcessing(false);
                }
            }, 'image/jpeg');
        };
    };

    const triggerFileInput = () => {
        document.getElementById('imageUpload').click();
    };

    return (
        <div className="profile-edit-section-image">
            <div className="profile-edit-section-content-image">
                {isLoading || isProcessing ? (
                    <LoadingSpinner className="loading-spinner" />
                ) : (
                    <div className="profile-edit-section-main-image">
                        <img src={image || DefaultProfileImage} alt="Profile" className="parent-profile-image" />
                        <div className="actions">
                            {image ? (
                                <>
                                    <FontAwesomeIcon icon={faSyncAlt} className="replace-icon" onClick={triggerFileInput} />
                                    <FontAwesomeIcon icon={faTrash} className="trash-icon" onClick={handleImageDelete} />
                                </>
                            ) : (
                                <label htmlFor="imageUpload">
                                    <FontAwesomeIcon icon={faPlus} />
                                </label>
                            )}
                        </div>
                    </div>
                )}
                <div className="profile-edit-section-upload">
                    <input type="file" accept="image/*" id="imageUpload" onChange={handleImageChange} />
                </div>
            </div>
        </div>
    );
};

export default ParentProfileImage;
