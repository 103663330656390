import React, { useState, useEffect, useContext } from 'react';
import checkIcon from '../../../../../../images/Vectors/HappyHome_CheckIcon.svg';
import crossIcon from '../../../../../../images/Vectors/HappyHome_CrossIcon.svg';
import AuthContext from '../../../../AuthProvider';

const JobOfferShiftsAdd = ({ workShifts, setWorkShifts }) => {
  const { authTokens } = useContext(AuthContext);
  const [shiftChoices, setShiftChoices] = useState([]);
  const daysOfWeek = ['Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado', 'Domingo'];
  const shiftsOfDay = ['Mañana', 'Tarde', 'Noche'];
  const [selectedCells, setSelectedCells] = useState([]);

  useEffect(() => {
    const fetchShiftChoices = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/shifts/get-choices/`, {
          headers: {
            'Authorization': `Bearer ${authTokens.access}`,
          },
        });
        if (response.ok) {
          const data = await response.json();
          setShiftChoices(data);
        } else {
          console.error('Error fetching shift choices:', response.statusText);
        }
      } catch (error) {
        console.error('Error fetching shift choices:', error.message);
      }
    };
    fetchShiftChoices();
  }, [authTokens]);

  const isCellSelected = (day, shift) => {
    return selectedCells.some(cell => cell.day === day.toLowerCase() && cell.shift === shift.toLowerCase());
  };

  const handleCellClick = (day, shift) => {
    const isSelected = isCellSelected(day, shift);
    let newSelectedCells = [];
    if (isSelected) {
      newSelectedCells = selectedCells.filter(cell => !(cell.day === day.toLowerCase() && cell.shift === shift.toLowerCase()));
    } else {
      newSelectedCells = [...selectedCells, { day: day.toLowerCase(), shift: shift.toLowerCase() }];
    }
    setSelectedCells(newSelectedCells);
    updateWorkShifts(newSelectedCells);
  };

  const updateWorkShifts = (selectedCells) => {
    const updatedWorkShifts = selectedCells.map(cell => {
      const shift = shiftChoices.find(shift => shift.day === cell.day && shift.shift === cell.shift);
      return shift ? shift.id : null;
    }).filter(id => id !== null);
    setWorkShifts(updatedWorkShifts);
  };

  return (
    <div className="edit-job-offer-section">
      <div className="edit-job-offer-intro">
        <h3 className="edit-job-offer-title">Disponibilidad</h3>
        <div className="edit-job-offer-description">
          <p>¿En qué días y horarios debería estar disponible?</p>
        </div>
      </div>
      <div className="worker-profile-shift-update">
        <div className="update-calendar-container">
          <table className="update-calendar">
            <thead>
              <tr>
                <th></th>
                {shiftsOfDay.map((shift, index) => (
                  <th className="update-calendar-col" key={index}>
                    {shift}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              <tr className="spacer-row"></tr>
              {daysOfWeek.map((day, dayIndex) => (
                <tr className="update-calendar-tr" key={dayIndex}>
                  <td className="update-calendar-row-day">
                    {day}
                  </td>
                  {shiftsOfDay.map((shift, shiftIndex) => (
                    <td className="update-calendar-row" key={`${day}-${shift}`} onClick={() => handleCellClick(day, shift)}>
                      <div className="update-calendar-row-cell">
                        <div className="update-calendar-row-icon">
                          {isCellSelected(day, shift) ? (
                            <img src={checkIcon} alt="Check Icon" className="icon-svg" />
                          ) : (
                            <img src={crossIcon} alt="Cross Icon" className="icon-svg" />
                          )}
                        </div>
                      </div>
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default JobOfferShiftsAdd;
