import React, { useState, useEffect } from 'react';
import checkIcon from '../../../images/Vectors/HappyHome_CheckIcon.svg';
import crossIcon from '../../../images/Vectors/HappyHome_CrossIcon.svg';

const LanguageFilter = ({ languages, handleLanguageChange, clearFilter }) => {
  const [selectedLanguages, setSelectedLanguages] = useState([]);

  useEffect(() => {
    if (clearFilter) {
      setSelectedLanguages([]);
    }
  }, [clearFilter]);

  const handleChange = (value) => {
    const isSelected = selectedLanguages.includes(value);
    handleLanguageChange(!isSelected, value);
    setSelectedLanguages(prev => {
      if (isSelected) {
        return prev.filter(language => language !== value);
      } else {
        return [...prev, value];
      }
    });
  };

  return (
    <div className="filter-container">
      {languages.map(language => (
        <div key={language.id} className="filter-option" onClick={() => handleChange(language.name_display)}>
          <div className="filter-option-label">
            <label>{language.name_display}</label>
          </div>
          <div className="filter-option-icon">
            <img
              src={selectedLanguages.includes(language.name_display) ? checkIcon : crossIcon}
              alt={selectedLanguages.includes(language.name_display) ? 'Check Icon' : 'Cross Icon'}
              className="icon-svg"
            />
          </div>
        </div>
      ))}
    </div>
  );
};

export default LanguageFilter;

