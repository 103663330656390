import React, { useEffect, useState } from 'react';
import LoadingSpinner from '../../../common/components/LoadingSpinner';

const WorkerProfileIntroductionView = ({ profileId }) => {
  const [introduction, setIntroduction] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchIntroduction = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/worker-profile/${profileId}/introduction/get/`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json'
          },
        });

        if (response.ok) {
          const data = await response.json();
          setIntroduction(data.introduction);
        } else {
          console.error('Failed to fetch worker introduction:', response.statusText);
        }
      } catch (error) {
        console.error('Error fetching worker introduction:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchIntroduction();
  }, [profileId]);

  return (
    <div className='profile-view-data-section'>
      <div className="profile-view-data-section introduction">
        <div className="profile-view-data-section-intro">
          <h2>Sobre mí</h2>
        </div>
        {isLoading ? (
          <LoadingSpinner />
        ) : introduction ? (
          <p>{introduction}</p>
        ) : (
          <p>No hay introducción registrada.</p>
        )}
      </div>
    </div>
  );
};

export default WorkerProfileIntroductionView;
