import React, { useEffect, useState } from 'react';
import DefaultProfileImage from '../../../images/ProfilePicture/HH_ProfileDefaultImage.png';
import LoadingSpinner from '../../../common/components/LoadingSpinner';

const ParentProfileImageView = ({ profileId }) => {
  const [imageSrc, setImageSrc] = useState(DefaultProfileImage);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchProfileImage = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/user/${profileId}/profile-image/get/`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json'
          },
        });

        if (response.ok) {
          const data = await response.json();
          setImageSrc(data.image || DefaultProfileImage);
        } else {
          console.error('Failed to fetch parent profile image:', response.statusText);
        }
      } catch (error) {
        console.error('Error fetching parent profile image:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchProfileImage();
  }, [profileId]);

  return (
    <div className='profile-view-data-section'>
      <div className="profile-view-data-section image">
        {isLoading ? (
          <LoadingSpinner />
        ) : (
          <img src={imageSrc} alt="Profile" className="profile-image" />
        )}
      </div>
    </div>
  );
};

export default ParentProfileImageView;
