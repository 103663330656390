import React, { useState, useEffect } from 'react';
import checkIcon from '../../../../../../images/Vectors/HappyHome_CheckIcon.svg';
import crossIcon from '../../../../../../images/Vectors/HappyHome_CrossIcon.svg';

const JobOfferExperienceUpdate = ({ experienceOptions, onUpdate }) => {
  const [experienceRequired, setExperienceRequired] = useState(experienceOptions.experience_required);
  const [referencesRequired, setReferencesRequired] = useState(experienceOptions.references_required);

  useEffect(() => {
    setExperienceRequired(experienceOptions.experience_required);
    setReferencesRequired(experienceOptions.references_required);
  }, [experienceOptions]);

  const handleExperienceChange = () => {
    const updatedExperienceRequired = !experienceRequired;
    setExperienceRequired(updatedExperienceRequired);
    onUpdate({
      work_experience: {
        experience_required: updatedExperienceRequired,
        references_required: referencesRequired,
      }
    });
  };

  const handleReferencesChange = () => {
    const updatedReferencesRequired = !referencesRequired;
    setReferencesRequired(updatedReferencesRequired);
    onUpdate({
      work_experience: {
        experience_required: experienceRequired,
        references_required: updatedReferencesRequired,
      }
    });
  };

  return (
    <div className="edit-job-offer-section">
      <div className="edit-job-offer-intro">
        <h3 className="edit-job-offer-title">Experiencia laboral</h3>
        <div className="edit-job-offer-description">
          <p>¿Es importante que tenga experiencia laboral?</p>
        </div>
      </div>
      
      <div className="popup-form-rows">
        <div className="popup-select-form-row" onClick={handleExperienceChange}>
          <div className="popup-form-label">
            <label>¿Es necesario que tenga experiencia laboral?</label>
          </div>
          <div className="popup-form-icon">
            <img src={experienceRequired ? checkIcon : crossIcon} alt="Icon" className="icon-svg" />
          </div>
          
        </div>
        <div className="popup-select-form-row" onClick={handleReferencesChange}>
          <div className="popup-form-label">
            <label>¿Es necesario que tenga referencias laborales?</label>
          </div>
          <div className="popup-form-icon">
            <img src={referencesRequired ? checkIcon : crossIcon} alt="Icon" className="icon-svg" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default JobOfferExperienceUpdate;
