import React from 'react';

const JobOfferMonthlyPriceView = ({ monthlyPrice }) => {
  return (
    <div className='profile-view-data-section'>
      <div className="profile-view-data-section hour-price">
        <div className="profile-view-data-section-intro">
          <h2>Sueldo mensual</h2>
        </div>
        {monthlyPrice ? (
          <p>${monthlyPrice}</p>
        ) : (
          <p>No hay precio mensual registrado.</p>
        )}
      </div>
    </div>
  );
};

export default JobOfferMonthlyPriceView;

