import React, { useState, useEffect, useContext } from 'react';
import AuthContext from '../../AuthProvider';
import checkIcon from '../../../../images/Vectors/HappyHome_CheckIcon.svg';
import crossIcon from '../../../../images/Vectors/HappyHome_CrossIcon.svg';
import LoadingSpinner from '../../../../common/components/LoadingSpinner';

const WorkerProfileServicesUpdate = ({ onClose, onAdd, preselectedServices }) => {
  const { userData, authTokens } = useContext(AuthContext);
  const [selectedServices, setSelectedServices] = useState(preselectedServices.map(service => service.id));
  const [servicesChoices, setServicesChoices] = useState([]);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isChanged, setIsChanged] = useState(false);

  useEffect(() => {
    const fetchServicesChoices = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/worker-profile/services/get-choices/`, {
          headers: {
            'Authorization': `Bearer ${authTokens?.access}`
          }
        });
        if (response.ok) {
          const data = await response.json();
          setServicesChoices(data);
        } else {
          console.error('Error fetching services choices:', response.statusText);
        }
      } catch (error) {
        console.error('Error fetching services choices:', error.message);
      } finally {
        setIsLoading(false);
      }
    };
    fetchServicesChoices();
  }, [authTokens?.access]);

  const handleServiceChange = (serviceId) => {
    setSelectedServices(prevSelectedServices => {
      const newSelectedServices = prevSelectedServices.includes(serviceId)
        ? prevSelectedServices.filter(id => id !== serviceId)
        : [...prevSelectedServices, serviceId];

      setIsChanged(
        newSelectedServices.length !== preselectedServices.length ||
        newSelectedServices.some(id => !preselectedServices.some(service => service.id === id))
      );

      return newSelectedServices;
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (selectedServices.length === 0) {
      setError('Debes seleccionar al menos un servicio.');
      return;
    }

    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/worker-profile/${userData.profile_id}/services/update/`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${authTokens?.access}`
        },
        body: JSON.stringify({ services_ids: selectedServices }),
      });
      if (response.ok) {
        const data = await response.json();
        onAdd(data.services);
        onClose();
      } else {
        console.error('Error al actualizar servicio:', response.statusText);
      }
    } catch (error) {
      console.error('Error al actualizar servicio:', error.message);
    }
  };

  return (
    <div className="popup-background">
      <div className="popup">
        <div className="popup-content">
          <div className="popup-intro">
            <h3 className="popup-title">¿Qué servicios te sentís capacitada/o a ofrecer?</h3>
            <div className="popup-intro-description">
              <p>Marca todos los servicios que querés ofrecer</p>
            </div>
          </div>
          {isLoading ? (
            <LoadingSpinner />
          ) : (
            <form className="popup-form" onSubmit={handleSubmit}>
              <div className="popup-form-rows">
                {servicesChoices.map(service => (
                  <div className="popup-select-form-row" key={service.id} onClick={() => handleServiceChange(service.id)}>
                    <div className="popup-form-label">
                      <label>{service.title_display}</label>
                    </div>
                    <div className="popup-form-icon">
                      <img
                        src={selectedServices.includes(service.id) ? checkIcon : crossIcon}
                        alt={selectedServices.includes(service.id) ? 'Check Icon' : 'Cross Icon'}
                        className="icon-svg"
                      />
                    </div>
                  </div>
                ))}
                <p className="error-message">{error || '\u00A0'}</p>
              </div>
              <div className="buttons-container">
                <button className="primary-btn" type="submit" disabled={!isChanged}>Guardar</button>
                <button className="secondary-btn" onClick={onClose}>Cancelar</button>
              </div>
            </form>
          )}
        </div>
      </div>
    </div>
  );
};

export default WorkerProfileServicesUpdate;
