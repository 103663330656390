import React, { useState } from 'react';
import AdminPanelPaymentsList from '../components/AdminPanelPayment/AdminPanelPaymentsList';
import AdminPanelPaymentsSearchBar from '../components/AdminPanelPayment/AdminPanelPaymentsSearchBar';

const AdminPanelPaymentsPage = () => {
  const [searchTerm, setSearchTerm] = useState('');

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  return (
    <div className="admin-panel-payments-page">
      <div className="admin-panel-intro">
        <h2>Pagos</h2>
      </div>
      <div className="admin-panel-data">
        <AdminPanelPaymentsSearchBar searchTerm={searchTerm} handleSearchChange={handleSearchChange} />
        <AdminPanelPaymentsList searchTerm={searchTerm} />
      </div>
    </div>
  );
};

export default AdminPanelPaymentsPage;
