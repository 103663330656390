import React from 'react';


const JobOfferIntroductionView = ({ description }) => {
  return (
    <div className='profile-view-data-section'>
      <div className="profile-view-data-section introduction">
        <div className="profile-view-data-section-intro">
          <h2>Presentación</h2>
        </div>
        {description ? (
          <p>{description}</p>
        ) : (
          <p>No hay descripción registrada.</p>
        )}
      </div>
    </div>
  );
};

export default JobOfferIntroductionView;
