import React, { useState, useEffect, useContext } from 'react';
import checkIcon from '../../../../../../images/Vectors/HappyHome_CheckIcon.svg';
import crossIcon from '../../../../../../images/Vectors/HappyHome_CrossIcon.svg';
import AuthContext from '../../../../AuthProvider';

const JobOfferLanguagesUpdate = ({ languagesOptions, onUpdate, preselectedNativeLanguageId }) => {
  const { authTokens } = useContext(AuthContext);
  const [allLanguages, setAllLanguages] = useState([]);
  const [native_language, setNativeLanguage] = useState(preselectedNativeLanguageId || null);

  useEffect(() => {
    const fetchAllLanguages = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/languages/get-choices/`, {
          headers: {
            'Authorization': `Bearer ${authTokens.access}`,
          },
        });
        if (response.ok) {
          const data = await response.json();
          setAllLanguages(data);
        } else {
          console.error('Error fetching all languages:', response.statusText);
        }
      } catch (error) {
        console.error('Error fetching all languages:', error.message);
      }
    };
    fetchAllLanguages();
  }, [authTokens]);

  const handleLanguageChange = (languageId) => {
    const updatedLanguages = languagesOptions.some(option => option.id === languageId)
      ? languagesOptions.filter(option => option.id !== languageId)
      : [...languagesOptions, allLanguages.find(language => language.id === languageId)];

    onUpdate({ languages: updatedLanguages, native_language });
  };

  const handleNativeLanguageChange = (e) => {
    const selectedNativeLanguage = parseInt(e.target.value, 10);
    setNativeLanguage(selectedNativeLanguage);
    onUpdate({ languages: languagesOptions, native_language: selectedNativeLanguage });
  };

  return (
    <div className="edit-job-offer-section">
      <div className="edit-job-offer-intro">
        <h3 className="edit-job-offer-title">Idiomas</h3>
        <div className="edit-job-offer-description">
          <p>Selecciona los idiomas deseados</p>
        </div>
      </div>
      <div className="popup-form-rows">
        {allLanguages.map(language => (
          <div key={language.id} className="popup-select-form-row" onClick={() => handleLanguageChange(language.id)}>
            <div className="popup-form-label">
              <label>{language.name_display}</label>
            </div>
            <div className="popup-form-icon">
              <img
                src={languagesOptions.some(option => option.id === language.id) ? checkIcon : crossIcon}
                alt="Icon"
                className="icon-svg"
              />
            </div>

          </div>
        ))}
      </div>
      <div className="edit-job-offer-native-language">
        <label htmlFor="nativeLanguage">¿Cual es tu idioma nativo?</label>
        <div className="popup-select-form-row">
          <select id="nativeLanguage" value={native_language || ''} onChange={handleNativeLanguageChange}>
            <option value="">Selecciona...</option>
            {languagesOptions.map(language => (
              <option key={language.id} value={language.id}>{language.name_display}</option>
            ))}
          </select>
        </div>
      </div>
    </div>
  );
};

export default JobOfferLanguagesUpdate;
