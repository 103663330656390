import React from 'react';


const JobOfferServicesView = ({ services }) => {
  return (
    <div className='profile-view-data-section'>
      <div className="profile-view-data-section services">
        <div className="profile-view-data-section-intro">
          <h2>Servicios requeridos</h2>
        </div>
        {services.length > 0 ? (
          <ul>
            {services.map((service, index) => (
              <li key={index}>
                <p>{service.title_display}</p>
              </li>
            ))}
          </ul>
        ) : (
          <p>No hay servicios registrados.</p>
        )}
      </div>
    </div>
  );
};

export default JobOfferServicesView;
