import React, { useEffect, useMemo, useState, useRef, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import Message from '../Message/Message';
import MessageInput from '../Message/MessageInput';
import ChatWebSocketService from './ChatWebSocketService';
import AuthContext from '../../../base/components/AuthProvider';
import { DateSeparator, formatDate, getConversationPartner, scrollToBottom, handleProfileClick } from './ConversationUtilities';

const Conversation = ({ conversation, messages, currentUser }) => {
  const { authTokens } = useContext(AuthContext);
  const [sortedMessages, setSortedMessages] = useState([]);
  const [isWebSocketOpen, setIsWebSocketOpen] = useState(false);
  const messagesEndRef = useRef(null);
  const messagesContainerRef = useRef(null);
  let lastMessageDate = null;
  const navigate = useNavigate();
  const conversationPartner = useMemo(() => getConversationPartner(conversation, currentUser), [conversation, currentUser]);
  
  const connectWebSocket = (conversationId, accessToken) => {
    if (!isWebSocketOpen) {
      ChatWebSocketService.connect(conversationId, accessToken, (data) => {
        if (data.id) {
          console.log('New message received:', data);
          addMessage(data);
        }
      });
      setIsWebSocketOpen(true);
    }
  };

  const disconnectWebSocket = () => {
    ChatWebSocketService.disconnect();
    setIsWebSocketOpen(false);
  };

  useEffect(() => {
    const accessToken = authTokens.access;

    if (conversation) {
      connectWebSocket(conversation.id, accessToken);
    }

    return () => {
      disconnectWebSocket();
    };
  }, [conversation.id]);

  useEffect(() => {
    if (messages && messages.length > 0) {
      const sortedMessages = [...messages].sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp));
      setSortedMessages(sortedMessages);
    } else {
      setSortedMessages([]);
    }
  }, [messages]);

  const addMessage = (newMessage) => {
    setSortedMessages((prevMessages) => {
      const updatedMessages = [...prevMessages, newMessage].sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp));
      return updatedMessages;
    });
    scrollToBottom(messagesContainerRef);
  };

  useEffect(() => {
    scrollToBottom(messagesContainerRef);
  }, [sortedMessages]);

  if (!conversation) {
    return <div className="conversation">No conversation selected</div>;
  }
  
  return (
    <div className="chat-conversation">
      <div className="conversation-header" onClick={() => handleProfileClick(conversationPartner, navigate)} style={{ cursor: 'pointer' }}>
        <img src={conversationPartner.profileImage} alt={conversationPartner.name} className="profile-pic" />
        <h3>{conversationPartner.name}</h3>
      </div>


      <div className="conversation-messages" ref={messagesContainerRef}>
        <div className="chat-advice-fixed-message">
          <p>HappyHome no se hace responsable de lo conversado en este chat.</p>
        </div>
        <div>
        {sortedMessages.map((message, index) => {
          const messageDate = new Date(message.timestamp).toDateString();
          const showDateSeparator = messageDate !== lastMessageDate;
          lastMessageDate = messageDate;

          return (
            <React.Fragment key={index}>
              {showDateSeparator && <DateSeparator date={formatDate(message.timestamp)} />}
              <Message message={message} currentUser={currentUser} />
            </React.Fragment>
          );
        })}
        </div>
        <div ref={messagesEndRef} />
      </div>
      <div className="conversation-footer">
        {isWebSocketOpen && (
          <MessageInput
            conversationId={conversation.id}
            currentUser={currentUser}
            chatSocket={ChatWebSocketService}
            addMessage={addMessage}
          />
        )}
      </div>
    </div>
  );
};

export default Conversation;
