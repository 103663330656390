import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import AuthContext from '../components/AuthProvider';

const Login = ({ onClose, onForgotPassword }) => {
  const { loginUser } = useContext(AuthContext);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [formSubmitted, setFormSubmitted] = useState(false);
  const navigate = useNavigate();

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleLogin = async () => {
    setLoading(true);
    setFormSubmitted(true);

    if (!email || !password) {
      setErrorMessage('Completar campos obligatorios.');
      setLoading(false);
      return;
    }

    if (!validateEmail(email)) {
      setErrorMessage('Por favor ingresa un correo electrónico válido.');
      setLoading(false);
      return;
    }

    const data = {
      email: email,
      password: password,
    };

    try {
      const loginResult = await loginUser(data);

      if (!loginResult.success) {
        setErrorMessage(loginResult.message);
      } else {
        if (loginResult.is_admin) {
          navigate('/');
        } else {
          navigate('/user-account');
        }
        onClose();
      }
    } catch (error) {
      console.error('Error during login:', error);
      setErrorMessage('Error logging in: ' + error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleForgotPassword = () => {
    onClose();
    onForgotPassword();
  };

  return (
    <div className="popup-background">
      <div className="popup">
        <div className="popup-content">
          <div className="popup-intro">
            <h2>Iniciar sesión</h2>
            <div className="popup-intro-description">
              <p>Si ya tienes una cuenta creada, puedes ingresar con tu correo electrónico y contraseña con la cual te registraste.</p>
            </div>
          </div>
          <div className="popup-auth-message">
            <p className="error-message">{errorMessage || '\u00A0'}</p>
          </div>
          <form className="popup-form">
            
            <div className="popup-form-rows">
              
              <div className={`form-group ${formSubmitted && !email ? 'missing-field' : ''}`}>
                <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Correo electrónico" />
              </div>
              <div className={`form-group ${formSubmitted && !password ? 'missing-field' : ''}`}>
                <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} placeholder="Contraseña" />
              </div>
            </div>
            <div className="buttons-container">
              <button className="primary-btn" type="button" onClick={handleLogin} disabled={loading}>
                {loading ? 'Cargando...' : 'Ingresar'}
              </button>
              <button className="secondary-btn" type="button" onClick={onClose} disabled={loading}>
                Cancelar
              </button>
            </div>
          </form>
          <div className="popup-footer">
          <p>¿Olvidaste tu contraseña? <button type="button" onClick={handleForgotPassword} className="forgot-password-link">Haz clic aquí para recuperarla</button></p>
            
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
