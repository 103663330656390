import React, { useState, useEffect, useContext } from 'react';
import checkIcon from '../../../../../../images/Vectors/HappyHome_CheckIcon.svg';
import crossIcon from '../../../../../../images/Vectors/HappyHome_CrossIcon.svg';
import AuthContext from '../../../../AuthProvider';

const JobOfferEducationTypeUpdate = ({ educationTypeOptions, onUpdate }) => {
  const { authTokens } = useContext(AuthContext);
  const [allEducationTypes, setAllEducationTypes] = useState([]);

  useEffect(() => {
    const fetchAllEducationTypes = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/education-types/get-choices/`, {
          headers: {
            'Authorization': `Bearer ${authTokens.access}`,
          },
        });
        if (response.ok) {
          const data = await response.json();
          setAllEducationTypes(data);
        } else {
          console.error('Error fetching all education types:', response.statusText);
        }
      } catch (error) {
        console.error('Error fetching all education types:', error.message);
      }
    };
    fetchAllEducationTypes();
  }, [authTokens]);

  const handleEducationTypeChange = (educationTypeId) => {
    const updatedEducationTypes = educationTypeOptions.some(option => option.id === educationTypeId)
      ? educationTypeOptions.filter(option => option.id !== educationTypeId)
      : [...educationTypeOptions, allEducationTypes.find(type => type.id === educationTypeId)];

    onUpdate({ education_types: updatedEducationTypes });
  };

  return (
    <div className="edit-job-offer-section">
      <div className="edit-job-offer-intro">
        <h3 className="edit-job-offer-title">Estudios</h3>
        <div className="edit-job-offer-description">
          <p>¿Es importante que tenga estudios en alguna de estas áreas?</p>
        </div>
      </div>

      <div className="popup-form-rows">
        {allEducationTypes.map(educationType => (
          <div key={educationType.id} className="popup-select-form-row" onClick={() => handleEducationTypeChange(educationType.id)}>
            <div className="popup-form-label">
              <label>{educationType.name}</label>
            </div>
            <div className="popup-form-icon">
              <img
                src={educationTypeOptions.some(option => option.id === educationType.id) ? checkIcon : crossIcon}
                alt="Icon"
                className="icon-svg"
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default JobOfferEducationTypeUpdate;
