import React from 'react';
import checkIcon from '../../../images/Vectors/HappyHome_CheckIcon.svg';
import crossIcon from '../../../images/Vectors/HappyHome_CrossIcon.svg';

const JobOfferShiftsView = ({ workShifts }) => {
  const generateMatrix = () => {
    const matrix = [];
    const daysOfWeek = ['Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado', 'Domingo'];
    const shiftsOfDay = ['Mañana', 'Tarde', 'Noche'];

    daysOfWeek.forEach(day => {
      const row = [];
      shiftsOfDay.forEach(shift => {
        const hasShift = workShifts.some(s => s.day_display === day && s.shift_display === shift);
        row.push({ hasShift, activated: hasShift });
      });
      matrix.push(row);
    });
    return matrix;
  };

  return (
    <div className='profile-view-data-section'>
      <div className="profile-view-data-section shifts">
        <div className="profile-view-data-section-intro">
          <h2>Disponibilidad</h2>
        </div>
        <div className="calendar">
          <table className="availability">
            <thead>
              <tr>
                <th></th>
                {['Mañana', 'Tarde', 'Noche'].map((shift, index) => (
                  <th key={index}>{shift}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              <tr className="space-row">{'\u00A0'}</tr>
              {['Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado', 'Domingo'].map((day, dayIndex) => (
                <tr key={dayIndex}>
                  <td className="calendar-view-day">{day}</td>
                  {generateMatrix()[dayIndex].map((cell, cellIndex) => (
                    <td className="" key={cellIndex}>
                      <div className="update-calendar-row-cell">
                        <div className="update-calendar-row-icon">
                          {cell.hasShift ? (
                            <img src={checkIcon} alt="Check Icon" className="icon-svg" />
                          ) : (
                            <img src={crossIcon} alt="Cross Icon" className="icon-svg" />
                          )}
                        </div>
                      </div>
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default JobOfferShiftsView;
