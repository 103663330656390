import React, { useEffect, useState,  } from 'react';
import LoadingSpinner from '../../../common/components/LoadingSpinner';

const WorkerProfileInfoView = ({ profileId }) => {
  const [workerInfo, setWorkerInfo] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const calculateAge = (dateOfBirth) => {
    const today = new Date();
    const birthDate = new Date(dateOfBirth);
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();
    
    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    
    return age;
  };

  useEffect(() => {
    const fetchWorkerInfo = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/worker-profile/${profileId}/get-info/`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json'
          },
        });

        if (response.ok) {
          const workerProfileData = await response.json();
          setWorkerInfo(workerProfileData);
        } else {
          console.error('Failed to fetch worker data:', response.statusText);
        }
      } catch (error) {
        console.error('Error fetching worker data:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchWorkerInfo();
  }, [profileId]);

  return (
    <div className='profile-view-data-section'>
      <div className="profile-view-data-section info">
        {isLoading ? (
          <LoadingSpinner />
        ) : workerInfo ? (
          <ul>
            <li className="profile-view-data-section-name">
              <h2>{workerInfo.user.first_name || 'Sin asignar'}</h2>
            </li>
            <li>
              <p><strong>Edad: </strong>{calculateAge(workerInfo.date_of_birth) ? `${calculateAge(workerInfo.date_of_birth)} años` : 'Sin asignar'}</p>
            </li>
            <li>
              <p><strong>Género: </strong>{workerInfo.gender_display || 'Sin asignar'}</p>
            </li>
            {workerInfo.location ? (
              <>
                <li>
                  <p><strong>Barrio: </strong>{workerInfo.location.neighborhood || 'Sin asignar'}</p>
                </li>
                <li>
                  <p><strong>Partido: </strong>{workerInfo.location.municipality?.name || 'Sin asignar'}</p>
                </li>
                <li>
                  <p><strong>Provincia: </strong>{workerInfo.location.province?.name || 'Sin asignar'}</p>
                </li>
              </>
            ) : (
              <>
                <li>
                  <p><strong>Barrio: </strong>Sin asignar</p>
                </li>
                <li>
                  <p><strong>Partido: </strong>Sin asignar</p>
                </li>
                <li>
                  <p><strong>Provincia: </strong>Sin asignar</p>
                </li>
              </>
            )}
            <li>
              <p><strong>Nacionalidad: </strong>{workerInfo.nationality || 'Sin asignar'}</p>
            </li>
          </ul>
        ) : (
          <p>Error al obtener datos.</p>
        )}
      </div>
    </div>
  );
};

export default WorkerProfileInfoView;

