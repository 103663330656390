class ChatWebSocketService {
  static instance = null;

  constructor() {
    this.socketRef = null;
  }

  static getInstance() {
    if (!ChatWebSocketService.instance) {
      ChatWebSocketService.instance = new ChatWebSocketService();
    }
    return ChatWebSocketService.instance;
  }

  connect(roomId, accessToken, onMessage) {
    const wsBaseUrl = process.env.REACT_APP_WS_BASE_URL;
    this.socketRef = new WebSocket(`${wsBaseUrl}/chat/${roomId}/?token=${accessToken}`);

    this.socketRef.onopen = () => {
      console.log('WebSocket connected');
    };

    this.socketRef.onmessage = (event) => {
      console.log('WebSocket message received:', event.data);
      const data = JSON.parse(event.data);
      onMessage(data);
    };

    this.socketRef.onclose = () => {
      console.log('Chat WebSocket closed');
    };

    this.socketRef.onerror = (error) => {
      console.error('Chat WebSocket error:', error);
    };
  }

  sendMessage(data) {
    if (this.socketRef && this.socketRef.readyState === WebSocket.OPEN) {
      this.socketRef.send(JSON.stringify(data));
    } else {
      console.error('WebSocket is not open.');
    }
  }

  disconnect() {
    if (this.socketRef) {
      this.socketRef.close();
    }
  }
}

export default ChatWebSocketService.getInstance();

  