import React, { useState, useContext } from 'react';
import AuthContext from '../../AuthProvider';

const WorkerProfileExperienceAdd = ({ onClose, onAdd }) => {
  const { authTokens, userData } = useContext(AuthContext);

  const [formData, setFormData] = useState({
    title: '',
    description: '',
    reference: '',
    currently_working: false,
  });

  const [requiredFields] = useState(['title', 'description']);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    const newValue = name === 'currently_working' ? value === 'true' : value;
    setFormData((prevData) => ({
      ...prevData,
      [name]: newValue,
    }));
  };

  const validateForm = () => {
    const newErrors = {};
    for (const field of requiredFields) {
      const value = formData[field].trim();
      if (!value) {
        newErrors[field] = 'Este campo es obligatorio';
      } else {
        switch (field) {
          case 'title':
            if (value.length < 3) {
              newErrors[field] = 'El título debe tener al menos 3 caracteres';
            } else if (value.length > 80) {
              newErrors[field] = 'El título debe tener menos de 80 caracteres';
            }
            break;
          case 'description':
            if (value.length < 10) {
              newErrors[field] = 'La descripción debe tener al menos 10 caracteres';
            } else if (value.length > 400) {
              newErrors[field] = 'La descripción debe tener menos de 400 caracteres';
            }
            break;
          default:
            break;
        }
      }
    }
    return newErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setFormSubmitted(true);

    const newErrors = validateForm();

    if (Object.keys(newErrors).length === 0) {
      try {
        const finalFormData = { ...formData };
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/worker-profile/${userData.profile_id}/experience/add/`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authTokens?.access}`,
          },
          body: JSON.stringify(finalFormData),
        });
        
        if (response.ok) {
          const data = await response.json();
          onAdd(data);
          onClose();
        } else {
          const responseData = await response.json();
          setErrors(responseData);
        }
      } catch (error) {
        console.error('Error al agregar experiencia:', error.message);
      }
    } else {
      setErrors(newErrors);
    }
  };

  return (
    <div className="popup-background">
      <div className="popup">
        <div className="popup-content">
          <div className="popup-intro">
            <h3 className="popup-title">Agregar experiencia laboral</h3>
            <div className="popup-intro-description">
              <p>Tener experiencia laboral comprobable te ayudara en el proceso de seleccion</p>
            </div>
          </div>
          <form className="popup-form" onSubmit={handleSubmit}>
            <div className="popup-form-rows">
              <div className="popup-form-double-col">
                <div className="popup-basic-form-row">
                  <label>¿Estás trabajando aquí actualmente?</label>
                  <select name="currently_working" value={formData.currently_working.toString()} onChange={handleChange}>
                    <option value="true">Sí</option>
                    <option value="false">No</option>
                  </select>
                </div>

                <div className={`popup-basic-form-row ${formSubmitted && errors.title ? 'missing-field' : ''}`}>
                  <label>Título del trabajo</label>
                  <input type="text" name="title" value={formData.title} onChange={handleChange} />
                  <p className="error-message">{errors.title || '\u00A0'}</p>
                </div>
              </div>
              <div className="popup-basic-form-row">
                <label>Referencia (opcional):</label>
                <input type="text" name="reference" value={formData.reference} onChange={handleChange} />
              </div>
              <div className={`popup-basic-form-row ${formSubmitted && errors.description ? 'missing-field' : ''}`}>
                <label>¿Qué tareas realizas o realizabas en el trabajo?</label>
                <textarea name="description" value={formData.description} onChange={handleChange} />
                <p className="error-message">{errors.description || '\u00A0'}</p>
              </div>


            </div>
            <div className="buttons-container">
              <button className="primary-btn" type="submit">Guardar</button>
              <button className="secondary-btn" onClick={onClose}>Cancelar</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default WorkerProfileExperienceAdd;
