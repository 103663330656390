import React, { useState, useEffect, useContext } from 'react';
import AuthContext from '../../AuthProvider';
import WorkerProfileLanguageUpdate from './WorkerProfileLanguageUpdate';
import LoadingSpinner from '../../../../common/components/LoadingSpinner';

const WorkerProfileLanguagesList = () => {
  const [workerLanguages, setWorkerLanguages] = useState([]);
  const [showUpdateLanguagesForm, setShowUpdateLanguagesForm] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const { userData, authTokens } = useContext(AuthContext);

  useEffect(() => {
    const fetchLanguages = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/worker-profile/${userData.profile_id}/languages/get-all/`, {
          headers: {
            'Authorization': `Bearer ${authTokens?.access}`
          }
        });
        if (response.ok) {
          const data = await response.json();
          setWorkerLanguages(data);
          setDataLoaded(true);
        } else {
          console.error('Failed to fetch worker languages');
        }
      } catch (error) {
        console.error('Error fetching worker languages:', error);
      }
    };

    fetchLanguages();

    return () => {
      setWorkerLanguages([]);
    };
  }, [userData.profile_id, authTokens?.access]);

  const handleToggleUpdateLanguagesForm = () => {
    setShowUpdateLanguagesForm(!showUpdateLanguagesForm);
  };

  const onAdd = (updatedLanguages) => {
    setWorkerLanguages(updatedLanguages);
  };

  const languageIds = workerLanguages.map(workerLanguage => workerLanguage.language);

  return (
    <div className="profile-edit-section">
      <h2 className="profile-edit-section-title">Idiomas</h2>
      <div className="profile-edit-section-content">
        {!dataLoaded ? (
          <LoadingSpinner />
        ) : (
          <>
            {workerLanguages.length === 0 ? (
              <p className="empty-message">Aún no has agregado ningún idioma</p>
            ) : (
              <ul className="profile-edit-section-list">
                {workerLanguages.map(language => (
                  <li key={language.id} className="profile-edit-section-list-item">
                    <p>{language.language_name_display} {language.native ? "(Nativo)" : ""}</p>
                  </li>
                ))}
              </ul>
            )}
          </>
        )}
      </div>
      {showUpdateLanguagesForm && <WorkerProfileLanguageUpdate onClose={() => setShowUpdateLanguagesForm(false)} onAdd={onAdd} preselectedLanguages={languageIds} nativeLanguageId={workerLanguages.find(language => language.native)?.language} />}
      {dataLoaded && <button className="primary-btn" onClick={handleToggleUpdateLanguagesForm}>Modificar</button>}
    </div>
  );
};

export default WorkerProfileLanguagesList;
