import React, { useState, useEffect, useContext } from 'react';
import checkIcon from '../../../../../../images/Vectors/HappyHome_CheckIcon.svg';
import crossIcon from '../../../../../../images/Vectors/HappyHome_CrossIcon.svg';
import AuthContext from '../../../../AuthProvider';

const JobOfferEducationTypeAdd = ({ educationTypes, setEducationTypes }) => {
  const { authTokens } = useContext(AuthContext);
  const [educationTypeChoices, setEducationTypeChoices] = useState([]);

  useEffect(() => {
    const fetchEducationTypeChoices = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/education-types/get-choices/`, {
          headers: {
            'Authorization': `Bearer ${authTokens.access}`,
          },
        });
        if (response.ok) {
          const data = await response.json();
          setEducationTypeChoices(data);
        } else {
          console.error('Error fetching education type choices:', response.statusText);
        }
      } catch (error) {
        console.error('Error fetching education type choices:', error.message);
      }
    };
    fetchEducationTypeChoices();
  }, [authTokens]);

  const handleEducationTypeChange = (educationTypeId) => {
    const updatedEducationTypes = [...educationTypes];
    const isSelected = updatedEducationTypes.includes(educationTypeId);

    if (isSelected) {
      const index = updatedEducationTypes.indexOf(educationTypeId);
      updatedEducationTypes.splice(index, 1);
    } else {
      updatedEducationTypes.push(educationTypeId);
    }
    setEducationTypes(updatedEducationTypes);
  };

  return (
    <div className="edit-job-offer-section">
      <div className="edit-job-offer-intro">
        <h3 className="edit-job-offer-title">Estudios</h3>
        <div className="edit-job-offer-description">
          <p>¿Es importante que tenga estudios en alguna de estas áreas?</p>
        </div>
      </div>
      <div className="popup-form-rows">
        {educationTypeChoices.map(educationType => (
          <div key={educationType.id} className="popup-select-form-row" onClick={() => handleEducationTypeChange(educationType.id)}>
            <div className="popup-form-label">
              <label>{educationType.name}</label>
            </div>
            <div className="popup-form-icon">
              <img
                src={educationTypes.includes(educationType.id) ? checkIcon : crossIcon}
                alt="Icon"
                className="icon-svg"
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default JobOfferEducationTypeAdd;
