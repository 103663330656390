import React, { useEffect, useState } from 'react';
import LoadingSpinner from '../../../common/components/LoadingSpinner';

const WorkerProfileTasksView = ({ profileId }) => {
  const [tasks, setTasks] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchTasks = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/worker-profile/${profileId}/tasks/get-all/`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json'
          },
        });

        if (response.ok) {
          const workerTasks = await response.json();
          setTasks(workerTasks);
        } else {
          console.error('Failed to fetch worker tasks:', response.statusText);
        }
      } catch (error) {
        console.error('Error fetching worker tasks:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchTasks();
  }, [profileId]);

  return (
    <div className='profile-view-data-section'>
      <div className="profile-view-data-section tasks">
        <div className="profile-view-data-section-intro">
          <h2>Tareas a realizar</h2>
        </div>
        {isLoading ? (
          <LoadingSpinner />
        ) : tasks.length > 0 ? (
          <ul>
            {tasks.map((task, index) => (
              <li key={index}>
                <p>{task.title_display}</p>
              </li>
            ))}
          </ul>
        ) : (
          <p>No hay tareas registradas.</p>
        )}
      </div>
    </div>
  );
};

export default WorkerProfileTasksView;
