import React from 'react';

const JobOfferEducationView = ({ educationTypes }) => {

  return (
    <div className='profile-view-data-section'>
      <div className="profile-view-data-section education">
        <div className="profile-view-data-section-intro">
          <h2>Educación</h2>
        </div>
        {educationTypes.length > 0 ? (
          <ul>
            {educationTypes.map((education, index) => (
              <li key={index}>
                <p>{education.name}</p>
              </li>
            ))}
          </ul>
        ) : (
          <p>No hay educación registrada.</p>
        )}
      </div>
    </div>
  );
};

export default JobOfferEducationView;

