import React, { useState, useEffect, useContext } from 'react';
import checkIcon from '../../../../../../images/Vectors/HappyHome_CheckIcon.svg';
import crossIcon from '../../../../../../images/Vectors/HappyHome_CrossIcon.svg';
import AuthContext from '../../../../AuthProvider';

const JobOfferServicesAdd = ({ services, setServices, error }) => {
  const { authTokens } = useContext(AuthContext);
  const [servicesChoices, setServicesChoices] = useState([]);

  useEffect(() => {
    const fetchServicesChoices = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/services/get-choices/`, {
          headers: {
            'Authorization': `Bearer ${authTokens.access}`, 
          },
        });
        if (response.ok) {
          const data = await response.json();
          setServicesChoices(data);
        } else {
          console.error('Error fetching services choices:', response.statusText);
        }
      } catch (error) {
        console.error('Error fetching services choices:', error.message);
      }
    };
    fetchServicesChoices();
  }, [authTokens]);

  const handleServiceChange = (serviceId) => {
    let updatedServices = [...services];
    const isSelected = updatedServices.includes(serviceId);
    if (isSelected) {
      updatedServices = updatedServices.filter(id => id !== serviceId);
    } else {
      updatedServices.push(serviceId);
    }
    setServices(updatedServices);
  };

  return (
    <div className="edit-job-offer-section">
      <div className="edit-job-offer-intro">
        <h3 className="edit-job-offer-title">Servicios</h3>
        <div className="edit-job-offer-description">
          <p>Marca los servicios que necesitas contratar.</p>
        </div>
      </div>
      <div className="popup-form-rows">
        {servicesChoices.map(service => (
          <div key={service.id} className="popup-select-form-row" onClick={() => handleServiceChange(service.id)}>
            <div className="popup-form-label">
              <label>{service.title_display}</label>
            </div>
            <div className="popup-form-icon">
              <img
                src={services.includes(service.id) ? checkIcon : crossIcon}
                alt="Icon"
                className="icon-svg"
              />
            </div>
          </div>
        ))}
      </div>
      <p className="error-message">{error || '\u00A0'}</p>
    </div>
  );
};

export default JobOfferServicesAdd;
