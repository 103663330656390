import React, { useState } from 'react';
import AdminPanelParentsList from '../components/AdminPanelParent/AdminPanelParentsList';
import AdminPanelParentsSearchBar from '../components/AdminPanelParent/AdminPanelParentsSearchBar';

const AdminPanelParentsPage = () => {
  const [searchTerm, setSearchTerm] = useState('');

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  return (
    <div className="admin-panel-parents-page">
      <div className="admin-panel-intro">
        <h2>Empleadores</h2>
      </div>
      <div className="admin-panel-data">
        <AdminPanelParentsSearchBar searchTerm={searchTerm} handleSearchChange={handleSearchChange} />
        <AdminPanelParentsList searchTerm={searchTerm} />
      </div>
    </div>
  );
};

export default AdminPanelParentsPage;
