import React, { useState, useContext } from 'react';
import checkIcon from '../../../images/Vectors/HappyHome_CheckIcon.svg';
import crossIcon from '../../../images/Vectors/HappyHome_CrossIcon.svg';
import AuthContext from '../../../base/components/AuthProvider';

const JobOfferStatusToggle = ({ jobId, initialValue }) => {
  const [status, setStatus] = useState(initialValue);
  const { authTokens } = useContext(AuthContext);

  const toggleStatus = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/admin/job-offer/${jobId}/toggle-status/`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${authTokens.access}`,
        },
        body: JSON.stringify({ status: !status }),
      });
      if (response.ok) {
        setStatus(!status);
      } else {
        console.error('Error al cambiar el estado de la oferta de trabajo:', response.statusText);
      }
    } catch (error) {
      console.error('Error al cambiar el estado de la oferta de trabajo:', error.message);
    }
  };

  return (
    <div className="job-offer-status-toggle">
      <div className="filter-option-icon" onClick={toggleStatus} style={{ cursor: 'pointer' }}>
        {status ? (
          <img src={checkIcon} alt="Check Icon" className="icon-svg" />
        ) : (
          <img src={crossIcon} alt="Cross Icon" className="icon-svg" />
        )}
      </div>
    </div>
  );
};

export default JobOfferStatusToggle;
