import React, { useState, useEffect, useContext } from 'react';
import AuthContext from '../../AuthProvider';
import checkIcon from '../../../../images/Vectors/HappyHome_CheckIcon.svg';
import crossIcon from '../../../../images/Vectors/HappyHome_CrossIcon.svg';
import LoadingSpinner from '../../../../common/components/LoadingSpinner';

const WorkerProfileLanguageUpdate = ({ onClose, onAdd, preselectedLanguages, nativeLanguageId }) => {
  const { userData, authTokens } = useContext(AuthContext);
  const [selectedLanguages, setSelectedLanguages] = useState(preselectedLanguages);
  const [initialLanguages, setInitialLanguages] = useState(preselectedLanguages);
  const [languageChoices, setLanguageChoices] = useState([]);
  const [nativeLanguage, setNativeLanguage] = useState(nativeLanguageId || '');
  const [initialNativeLanguage, setInitialNativeLanguage] = useState(nativeLanguageId || '');
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchLanguageChoices = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/worker-profile/languages/get-choices/`, {
          headers: {
            'Authorization': `Bearer ${authTokens?.access}`
          }
        });
        if (response.ok) {
          const data = await response.json();
          setLanguageChoices(data);
        } else {
          console.error('Error fetching language choices:', response.statusText);
        }
      } catch (error) {
        console.error('Error fetching language choices:', error.message);
      } finally {
        setIsLoading(false);
      }
    };
    fetchLanguageChoices();
  }, [authTokens?.access]);

  const handleLanguageChange = (languageId) => {
    setSelectedLanguages(prevSelectedLanguages => {
      if (prevSelectedLanguages.includes(languageId)) {
        return prevSelectedLanguages.filter(id => id !== languageId);
      } else {
        return [...prevSelectedLanguages, languageId];
      }
    });
  };

  const handleNativeLanguageChange = (e) => {
    setNativeLanguage(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/worker-profile/${userData.profile_id}/languages/update/`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${authTokens?.access}`
        },
        body: JSON.stringify({ 
          languages_ids: selectedLanguages,
          native_language_id: nativeLanguage
        }),
      });
      if (response.ok) {
        const data = await response.json();
        onAdd(data.languages);
        onClose();
      } else {
        console.error('Error updating languages:', response.statusText);
      }
    } catch (error) {
      console.error('Error updating languages:', error.message);
    }
  };

  const hasChanges = () => {
    return (
      initialLanguages.length !== selectedLanguages.length ||
      initialLanguages.some(id => !selectedLanguages.includes(id)) ||
      initialNativeLanguage !== nativeLanguage
    );
  };

  return (
    <div className="popup-background">
      <div className="popup">
        <div className="popup-content">
          <div className="popup-intro">
            <h3 className="popup-title">Modificar Idiomas</h3>
            <div className="popup-intro-description">
              <p>Marca los idiomas que hables y selecciona tu idioma nativo</p>
            </div>
          </div>

          {isLoading ? (
            <LoadingSpinner />
          ) : (
            <form className="popup-form" onSubmit={handleSubmit}>
              <div className="popup-form-rows">
                {languageChoices.map(language => (
                  <div className="popup-select-form-row" key={language.id} onClick={() => handleLanguageChange(language.id)}>
                    <div className="popup-form-label">
                      <label>{language.name_display}</label>
                    </div>
                    {selectedLanguages.includes(language.id) ? (
                      <div className="popup-form-icon"><img src={checkIcon} alt="Check Icon" className="icon-svg" /></div>
                    ) : (
                      <div className="popup-form-icon"><img src={crossIcon} alt="Cross Icon" className="icon-svg" /></div>
                    )}
                  </div>
                ))}
              </div>
              <div className="popup-form-select">
                <label htmlFor="nativeLanguage">¿Cual es tu idioma nativo?</label>
                <select id="nativeLanguage" value={nativeLanguage} onChange={handleNativeLanguageChange} disabled={selectedLanguages.length === 0}>
                  <option value="">Selecciona...</option>
                  {selectedLanguages.map(languageId => (
                    <option key={languageId} value={languageId}>{languageChoices.find(language => language.id === languageId)?.name_display}</option>
                  ))}
                </select>
              </div>
              <div className="buttons-container">
                <button className="primary-btn" type="submit" disabled={!hasChanges()}>Guardar</button>
                <button className="secondary-btn" onClick={onClose}>Cancelar</button>
              </div>
            </form>
          )}
        </div>
      </div>
    </div>
  );
};

export default WorkerProfileLanguageUpdate;

