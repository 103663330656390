import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { Helmet } from 'react-helmet';
import HeroSectionImage from '../../images/HomePage/HeroSection/HappyHome_HeroSectionImage.png'

const FAQsPage = () => {
  const [openTab, setOpenTab] = useState(null);

  const handleToggle = (index) => {
    setOpenTab(openTab === index ? null : index);
  };

  const faqs = [
    { question: '¿Cómo uso el sitio web?', answer: 'Usar Happy Home es muy sencillo. Primero, registrate en nuestra plataforma y creá tu perfil. Si sos empleado, podés buscar ofertas laborales gratuitas utilizando la barra de búsqueda y los filtros disponibles para encontrar trabajos como niñera, empleada doméstica, cocinero, cuidador de mascotas o cuidador de personas mayores. Si sos empleador, podés publicar ofertas laborales detallando las necesidades específicas de tu hogar o negocio. Utilizá nuestras herramientas para comunicarte directamente con los candidatos o empleadores y gestionar todas las interacciones desde tu cuenta.' },
    { question: '¿Cuál es la diferencia entre empleada/o y empleador/a?', answer: 'En Happy Home, los empleados pueden registrarse y utilizar la plataforma de manera gratuita. Esto incluye la creación de un perfil profesional, la búsqueda de ofertas laborales y la postulación a las mismas sin ningún costo. Por otro lado, los empleadores tienen acceso a diferentes tipos de membresias que ofrecen beneficios adicionales según sus necesidades, como acceso a una base de datos completa de candidatos, comunicación ilimitada y servicios de verificación y selección de personal.' },
    { question: '¿Cuál es el costo de usar la plataforma?', answer: 'Para los empleados, el uso de la plataforma es completamente GRATUITO. Podés registrarte, crear tu perfil y postularte a las ofertas laborales sin costo alguno. Para los empleadores, ofrecemos diferentes tipos de membresias con beneficios adicionales. Por ejemplo, la Membresía PLUS te da acceso a nuestra base de datos completa y chats ilimitados, la Membresía PREMIUM incluye entrevistas finales y verificaciones de antecedentes, y la Membresía ELITE ofrece un servicio completo de selección y verificación. Consultá nuestras opciones de membresía para más detalles.' },
    { question: '¿Dónde puedo encontrar trabajo?', answer: 'Primero, registrate gratis en nuestra plataforma. Es recomendable que completes toda la información de tu perfil por única vez para estar en nuestra base de datos. Podés encontrar trabajo utilizando la barra de búsqueda y los filtros disponibles. Ingresá tu ubicación y el tipo de trabajo que buscás, ya sea niñera, empleada doméstica, cocinero, cuidador de mascotas o cuidador de personas mayores, y te mostraremos las ofertas disponibles en tu área.Cada oferta incluye una descripción detallada de las responsabilidades y requisitos, así como la posibilidad de contactar directamente al empleador para más información. A través del sistema de chat integrado, podés hacer preguntas, enviar información adicional relevante, y coordinar entrevistas. Después de postularte, el empleador revisará tu perfil y, si está interesado, te contactará para coordinar una entrevista. Podrás acordar la entrevista a través del chat, por teléfono o en persona. Mantené tu perfil actualizado y seguí aplicando a otras ofertas que te interesen mientras esperás respuestas.' },
    { question: '¿Cómo me registro en el sitio?', answer: 'Registrarse en Happy Home es fácil y rápido. Primero, tenés que elegir si sos empleador o empleada. Hacé clic en el botón "Registrarse" en la página de inicio y seleccioná la opción correspondiente. Completá el formulario con tus datos personales como nombre, dirección de correo electrónico y contraseña. Luego, verificá tu cuenta a través del correo electrónico que recibirás. Una vez registrado, podés crear tu perfil, incluyendo tu experiencia laboral, habilidades y referencias. Asegurate de completar toda la información de tu perfil para estar en nuestra base de datos y maximizar tus oportunidades de empleo. Una vez que tu perfil esté listo, podés comenzar a usar la plataforma para buscar y postularte a ofertas laborales. Actualización del perfil: Podés actualizar la información de tu perfil en cualquier momento. Simplemente iniciá sesión en tu cuenta, dirigite a la sección de perfil y realizá los cambios necesarios. Mantener tu perfil actualizado es importante para reflejar tu experiencia y habilidades actuales, y así aumentar tus posibilidades de ser contratado.' },
    { question: '¿Qué hago si olvidé mi contraseña?', answer: 'Si olvidaste tu contraseña, podés recuperarla fácilmente. En la página de inicio de sesión, hacé clic en "¿Olvidaste tu contraseña?". Ingresá tu dirección de correo electrónico y recibirás un correo con instrucciones para restablecer tu contraseña. Asegurate de revisar tu carpeta de spam o correo no deseado si no ves el correo en tu bandeja de entrada.' },
    { question: '¿Cómo contacto al soporte?', answer: 'Podés contactar a nuestro equipo de soporte de varias maneras. En nuestra página web, encontrarás un formulario de contacto en la sección "Contacto". También podés enviarnos un correo electrónico a info@happyhome.com.ar o llamarnos al 011-6048-9640. Nuestro equipo está disponible para ayudarte con cualquier duda o problema que tengas, asegurando que tu experiencia en Happy Home sea la mejor posible.' },
    { question: '¿Cómo puedo adquirir una membresía?', answer: 'Para adquirir una membresía, ingresá a tu cuenta y dirigite a la sección de membresias. Allí encontrarás las diferentes opciones disponibles: PLUS, PREMIUM o ELITE. Elegí la que mejor se adapte a tus necesidades y seguí las instrucciones para completar la compra. Podés pagar con tarjeta de crédito u otros métodos de pago disponibles en nuestra plataforma. Las membresias se renuevan cada 30 días. Además, podés cambiar tu membresía en cualquier momento. Para hacerlo, accedé a la sección de configuración de tu cuenta y seleccioná la opción "Cambiar membresía". Los cargos adicionales se ajustarán según la nueva membresía seleccionada. Para atraer a los mejores candidatos, asegurate de que tu oferta laboral sea detallada y clara. Incluí información sobre las responsabilidades del puesto, requisitos específicos, salario ofrecido y cualquier otro beneficio. Una descripción completa y bien redactada puede ayudar a captar la atención de candidatos calificados. Las entrevistas a los candidatos pueden ser realizadas a través de nuestra plataforma de chat o por el método que el empleador prefiera. Para los usuarios de la membresía PREMIUM, nuestras terapeutas también pueden realizar una entrevista final al candidato elegido. Este servicio incluye una revisión exhaustiva del perfil del candidato para asegurar que cumple con los requisitos del empleador.' },
    { question: '¿Dónde puedo crear mi perfil?', answer: 'Podés crear tu perfil una vez que te hayas registrado en Happy Home. Ingresá a tu cuenta y dirigite a la sección "Mi Perfil". Completá la información necesaria, como tu experiencia laboral, habilidades, certificaciones y referencias. Una vez completado, tu perfil estará visible para los empleadores que buscan candidatos con tus características.' },
    { question: '¿Cómo puedo modificar mi perfil?', answer: 'Para modificar tu perfil, ingresá a tu cuenta y dirigite a la sección "Mi Perfil". Allí podés actualizar cualquier información que desees, como tu experiencia laboral, habilidades, disponibilidad y referencias. Asegurate de guardar los cambios para que tu perfil refleje siempre tu información más actualizada, lo que aumentará tus posibilidades de ser contratado.' },
    { question: '¿Qué beneficios tiene el sitio web?', answer: 'Happy Home ofrece múltiples beneficios para ambos lados del proceso de contratación. Para los empleados, ofrecemos acceso gratuito a oportunidades laborales, la posibilidad de crear un perfil profesional detallado, comunicación directa e ilimitada con empleadores y un entorno seguro con verificación de antecedentes y referencias. Para los empleadores, brindamos acceso a una base de datos completa de candidatos, herramientas de comunicación eficientes, y opciones de membresía que incluyen servicios de selección y verificación exhaustivos, todo diseñado para simplificar y asegurar el proceso de contratación.' }
  ];

  return (
    <div className="faqs-page-container">
      <Helmet>
        <title>HappyHome - Preguntas más frecuentes</title>
        <meta name="description" content="Respondemos a las preguntas más frecuentes sobre cómo utilizar Happy Home, registrarse, buscar empleo, contratar personal doméstico y más." />
        <meta name="keywords" content="preguntas frecuentes, FAQ, ayuda, cómo usar Happy Home, registro, empleo doméstico, contratar personal doméstico" />
        <meta property="og:title" content="HappyHome - Preguntas más frecuentes" />
        <meta property="og:description" content="La forma más fácil, rápida y económica de encontrar ayuda integral para tu hogar." />
        <meta property="og:image" content={HeroSectionImage} />
        <meta property="og:url" content="http://www.happyhome.com.ar" />
      </Helmet>
      <div className="section-title">
        <h2>Preguntas más frecuentes</h2>
        <p>Despeja tus dudas con las consultas más frecuentes de nuestros usuarios.</p>
      </div>
      <div className="faqs-list">
        {faqs.map((faq, index) => (
          <div key={index} className="faq-item">
            <div className="faq-question" onClick={() => handleToggle(index)}>
              <h3>{faq.question}</h3>
              <FontAwesomeIcon
                icon={openTab === index ? faChevronUp : faChevronDown}
                className="faq-icon"
              />
            </div>
            {openTab === index && (
              <div className="faq-answer">
                <p>{faq.answer}</p>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default FAQsPage;
