import React, { useState, useContext, useEffect } from 'react';
import AuthContext from '../../AuthProvider';

const ParentProfileInfoUpdate = ({ parentInfo, onUpdate, onCancel }) => {
  const [formData, setFormData] = useState({
    date_of_birth: parentInfo.date_of_birth || '',
    gender: parentInfo.gender || '',
    nationality: parentInfo.nationality || '',
  });

  const { authTokens, userData } = useContext(AuthContext);
  const [errors, setErrors] = useState({});
  const [isModified, setIsModified] = useState(false);

  useEffect(() => {
    const isModified = JSON.stringify(formData) !== JSON.stringify({
      date_of_birth: parentInfo.date_of_birth || '',
      gender: parentInfo.gender || '',
      nationality: parentInfo.nationality || '',
    });
    setIsModified(isModified);
  }, [formData, parentInfo]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formErrors = {};

    const currentDate = new Date();
    const birthDate = new Date(formData.date_of_birth);
    const age = currentDate.getFullYear() - birthDate.getFullYear();
    const isUnderAge = age < 18;
    const isOverAge = age > 100;

    if (!formData.date_of_birth.trim()) {
      formErrors.date_of_birth = 'La fecha de nacimiento es obligatoria.';
    } else if (isUnderAge) {
      formErrors.date_of_birth = 'Debe ser mayor de 18 años.';
    } else if (isOverAge) {
      formErrors.date_of_birth = 'Debe ser menor de 100 años.';
    }

    if (!formData.gender.trim()) {
      formErrors.gender = 'El género es obligatorio.';
    }

    if (!formData.nationality.trim()) {
      formErrors.nationality = 'La nacionalidad es obligatoria.';
    } else if (formData.nationality.trim().length > 100) {
      formErrors.nationality = 'La nacionalidad debe tener menos de 100 caracteres.';
    } else if (formData.nationality.trim().length < 4) {
      formErrors.nationality = 'La nacionalidad debe tener más de 4 caracteres.';
    }

    setErrors(formErrors);

    if (Object.keys(formErrors).length > 0) {
      return;
    }

    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/parent-profile/${userData.profile_id}/info/update/`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authTokens.access}`,
        },
        body: JSON.stringify(formData),
      });
      if (response.ok) {
        const updatedData = await response.json();
        onUpdate(updatedData);
      } else {
        console.error('Error updating parent profile info:', response.statusText);
      }
    } catch (error) {
      console.error('Error updating parent profile info:', error.message);
    }
  };

  return (
    <div className="popup-background">
      <div className="popup">
        <div className="popup-content">
          <h3 className="popup-title">Modificar información personal</h3>
          <form className="popup-form" onSubmit={handleSubmit}>
            <div className="popup-form-rows">
              <div className={`popup-basic-form-row ${errors.date_of_birth ? 'missing-field' : ''}`}>
                <label>Fecha de Nacimiento:</label>
                <input type="date" name="date_of_birth" value={formData.date_of_birth} onChange={handleChange} />
                {errors.date_of_birth && <p className="error-message">{errors.date_of_birth}</p>}
              </div>
              <div className={`popup-basic-form-row ${errors.gender ? 'missing-field' : ''}`}>
                <label>Género:</label>
                <select name="gender" value={formData.gender} onChange={handleChange}>
                  <option value="">Seleccionar</option>
                  <option value="male">Masculino</option>
                  <option value="female">Femenino</option>
                </select>
                {errors.gender && <p className="error-message">{errors.gender}</p>}
              </div>
              <div className={`popup-basic-form-row ${errors.nationality ? 'missing-field' : ''}`}>
                <label>Nacionalidad:</label>
                <input type="text" name="nationality" value={formData.nationality} onChange={handleChange} />
                {errors.nationality && <p className="error-message">{errors.nationality}</p>}
              </div>
            </div>
            <div className="buttons-container">
              <button className="primary-btn" type="submit" disabled={!isModified}>Guardar</button>
              <button className="secondary-btn" onClick={onCancel}>Cerrar</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ParentProfileInfoUpdate;
