import React, { useState, useContext } from 'react';
import AuthContext from '../../AuthProvider';
import JobOfferTitleUpdate from './JobOffer/JobOfferTitle/JobOfferTitleUpdate';
import JobOfferDescriptionUpdate from './JobOffer/JobOfferDescription/JobOfferDescriptionUpdate';
import JobOfferServicesUpdate from './JobOffer/JobOfferService/JobOfferServicesUpdate';
import JobOfferLocationUpdate from './JobOffer/JobOfferLocation/JobOfferLocationUpdate';
import JobOfferTasksUpdate from './JobOffer/JobOfferTask/JobOfferTasksUpdate';
import JobOfferWorkArrangementsUpdate from './JobOffer/JobOfferWorkArrangement/JobOfferWorkArrangementsUpdate';
import JobOfferLanguagesUpdate from './JobOffer/JobOfferLanguage/JobOfferLanguagesUpdate';
import JobOfferEducationTypeUpdate from './JobOffer/JobOfferEducationType/JobOfferEducationTypesUpdate';
import JobOfferShiftsUpdate from './JobOffer/JobOfferShift/JobOfferShiftsUpdate';
import JobOfferExperienceUpdate from './JobOffer/JobOfferExperience/JobOfferExperienceUpdate';
import JobOfferMonthlyPriceUpdate from './JobOffer/JobOfferMonthlyPrice/JobOfferMonthlyPriceUpdate';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

const ParentProfileJobOfferUpdate = ({ jobOffer, onCancel, onUpdate }) => {
  const { authTokens, userData } = useContext(AuthContext);

  const [formData, setFormData] = useState({
    title: jobOffer.title || '',
    description: jobOffer.description || '',
    work_experience: {
      experience_required: jobOffer.work_experience_required || false,
      references_required: jobOffer.work_references_required || false,
    },
    services: jobOffer.services || [],
    tasks: jobOffer.tasks || [],
    work_arrangements: jobOffer.work_arrangements || [],
    languages: jobOffer.languages || [],
    native_language: jobOffer.native_language || null,
    education_types: jobOffer.education_types || [],
    work_shifts: jobOffer.work_shifts || [],
    location: {
      neighborhood: jobOffer.location?.neighborhood || '',
      province: jobOffer.location?.province?.id || null,
      municipality: jobOffer.location?.municipality?.id || null,
    },
    monthly_price: jobOffer.monthly_price || 0,
  });

  const [initialFormData, setInitialFormData] = useState(formData);
  const [isModified, setIsModified] = useState(false);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) {
      return;
    }

    setLoading(true);

    const formDataWithIdsOnly = {
      ...formData,
      services: formData.services.map(service => service.id),
      tasks: formData.tasks.map(task => task.id),
      work_arrangements: formData.work_arrangements.map(arrangement => arrangement.id),
      education_types: formData.education_types.map(education_type => education_type.id),
      languages: formData.languages.map(language => language.id),
      native_language: formData.native_language,
      work_shifts: formData.work_shifts.map(work_shift => work_shift.id),
      location: {
        neighborhood: jobOffer.location?.neighborhood || '',
        province: formData.location.province,
        municipality: formData.location.municipality,
      },
    };

    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/parent-profile/${userData.profile_id}/job-offer/${jobOffer.id}/update/`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${authTokens.access}`,
        },
        body: JSON.stringify(formDataWithIdsOnly),
      });

      if (response.ok) {
        const updatedJobOffer = await response.json();
        onUpdate(updatedJobOffer);
        onCancel();
      } else {
        console.error('Error al actualizar oferta de trabajo:', response.statusText);
      }
    } catch (error) {
      console.error('Error al actualizar oferta de trabajo:', error.message);
    } finally {
      setLoading(false);
    }
  };

  const validateForm = () => {
    const newErrors = {};
    const maxTitleLength = 35;
    const maxDescriptionLength = 500;
    if (!formData.title) {
      newErrors.title = 'El título es obligatorio.';
    } else if (formData.title.length > maxTitleLength) {
      newErrors.title = `El título no puede exceder los ${maxTitleLength} caracteres.`;
    }
    if (!formData.description) {
      newErrors.description = 'La presentación es obligatoria.';
    } else if (formData.description.length > maxDescriptionLength) {
      newErrors.description = `La presentación no puede exceder los ${maxDescriptionLength} caracteres.`;
    }
    if (!formData.location.province) newErrors.province = 'Todos los campos de ubicación son obligatorios';
    if (!formData.location.municipality) newErrors.municipality = 'Todos los campos de ubicación son obligatorios';
    if (!formData.location.neighborhood) newErrors.neighborhood = 'Todos los campos de ubicación son obligatorios';
    if (formData.services.length === 0) newErrors.services = 'Debe seleccionar al menos un servicio';
    if (formData.work_arrangements.length === 0) newErrors.work_arrangements = 'Debe seleccionar al menos una modalidad de trabajo';
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleUpdateFormData = (updatedData) => {
    setFormData(prevData => {
      const newFormData = { ...prevData, ...updatedData };
      setIsModified(JSON.stringify(newFormData) !== JSON.stringify(initialFormData));
      return newFormData;
    });
  };

  const hasErrors = Object.keys(errors).length > 0;

  return (
    <div className="popup-background">
      <div className="popup job-offer">
        {!loading && (
          <div className="popup-header">
            <div className="popup-header-close">
              <button className="close-button" onClick={onCancel}>
                <FontAwesomeIcon icon={faTimes} />
              </button>
            </div>
          </div>
        )}
        <div className="popup-content">
          <form className="popup-form" onSubmit={handleSubmit}>
            <div className="popup-job-offer-form">
              <div className="popup-form-col">
                <JobOfferTitleUpdate
                  titleOptions={formData.title}
                  onUpdate={handleUpdateFormData}
                  error={errors.title}
                />
                <JobOfferServicesUpdate
                  servicesOptions={formData.services}
                  onUpdate={handleUpdateFormData}
                  error={errors.services}
                />
                <JobOfferLocationUpdate
                  locationOptions={formData.location}
                  onUpdate={handleUpdateFormData}
                  error={errors.province || errors.municipality || errors.neighborhood}
                />
                <JobOfferWorkArrangementsUpdate
                  workArrangementsOptions={formData.work_arrangements}
                  onUpdate={handleUpdateFormData}
                  error={errors.work_arrangements}
                />
                <JobOfferExperienceUpdate
                  experienceOptions={formData.work_experience}
                  onUpdate={handleUpdateFormData}
                />
                <JobOfferEducationTypeUpdate
                  educationTypeOptions={formData.education_types}
                  onUpdate={handleUpdateFormData}
                />
                <JobOfferLanguagesUpdate
                  languagesOptions={formData.languages}
                  onUpdate={handleUpdateFormData}
                  preselectedNativeLanguageId={formData.native_language}
                />
              </div>
              <div className="popup-form-col">
                <JobOfferTasksUpdate
                  tasksOptions={formData.tasks}
                  onUpdate={handleUpdateFormData}
                />
                <JobOfferDescriptionUpdate
                  descriptionOptions={formData.description}
                  onUpdate={handleUpdateFormData}
                  error={errors.description}
                />
                <JobOfferShiftsUpdate
                  initialWorkShifts={formData.work_shifts}
                  onUpdate={handleUpdateFormData}
                />
                <JobOfferMonthlyPriceUpdate
                  monthlyPriceOptions={parseInt(formData.monthly_price)}
                  onUpdate={handleUpdateFormData}
                />
              </div>
            </div>
            <div className="popup-footer">
              <p className="error-message">{hasErrors ? 'Completa los campos obligatorios e intenta nuevamente' : '\u00A0'}</p>
            </div>
            <div className="buttons-container">
              {loading ? (
                <div className="loading-message"><p>Actualizando....</p></div>
              ) : (
                <>
                  <button className="primary-btn" type="submit" disabled={!isModified}>Guardar</button>
                  <button className="secondary-btn" onClick={onCancel}>Cancelar</button>
                </>
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ParentProfileJobOfferUpdate;
