import { Helmet } from 'react-helmet';
import WhatWeOfferCocineroCircleImage from '../../images/HomePage/WhatWeOffer/HappyHome_Cocinero_Circle.png';
import WhatWeOfferCuidadorMascotaCircleImage from '../../images/HomePage/WhatWeOffer/HappyHome_CuidadorMascota_Circle.png';
import WhatWeOfferCuidadorPersonasMayoresCircleImage from '../../images/HomePage/WhatWeOffer/HappyHome_CuidadorPersonasMayores_Circle.png';
import WhatWeOfferEmpleadaDomesticaCircleImage from '../../images/HomePage/WhatWeOffer/HappyHome_EmpleadaDomestica_Circle.png';
import WhatWeOfferNineraCircleImage from '../../images/HomePage/WhatWeOffer/HappyHome_Ninera_Circle.png';
import WhatDoYouNeedBuscasEmpleadaImage from '../../images/HomePage/WhatDoYouNeed/WhatDoYouNeedBuscasEmpleadaImage.png';
import WhatDoYouNeedBuscasTrabajoImage from '../../images/HomePage/WhatDoYouNeed/WhatDoYouNeedBuscasTrabajoImage.png';
import AboutHappyHomeImage from '../../images/HomePage/AboutHappyHome/HappyHome_AboutHappyHome.png';
import WhyChooseUsVector1 from '../../images/HomePage/WhyChooseUs/WhyChooseUsVector1.png';
import WhyChooseUsVector2 from '../../images/HomePage/WhyChooseUs/WhyChooseUsVector2.png';
import WhyChooseUsVector3 from '../../images/HomePage/WhyChooseUs/WhyChooseUsVector3.png';
import WhyChooseUsVector4 from '../../images/HomePage/WhyChooseUs/WhyChooseUsVector4.png';
import HeroSectionImage from '../../images/HomePage/HeroSection/HappyHome_HeroSectionImage.png'
import HeroShape1 from '../../images/Vectors/HH_HeroShape1.svg'
import { useEffect } from 'react';

const HomePage = ({ setSignUpVisible }) => {

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            entry.target.classList.add('show');
          }
        });
      },
      { threshold: 0.2 }
    );

    const sections = document.querySelectorAll('.fade-in');
    sections.forEach(section => {
      observer.observe(section);
    });

    return () => observer.disconnect();
  }, []);

  return (
    <div>
      <Helmet>
        <title>HappyHome - Tu Solución Integral para el Hogar</title>
        <meta name="description" content="La forma más fácil, rápida y económica de encontrar ayuda integral para tu hogar en Argentina. Ofrecemos servicios de empleada doméstica, niñeras, cuidadores de personas mayores, cuidadores de mascotas y cocineros." />
        <meta name="keywords" content="ayuda en el hogar, empleada doméstica, niñera, cuidador de personas mayores, cuidador de mascotas, cocinera" />
        <meta property="og:title" content="HappyHome - Tu Solución Integral para el Hogar" />
        <meta property="og:description" content="La forma más fácil, rápida y económica de encontrar ayuda integral para tu hogar." />
        <meta property="og:image" content={HeroSectionImage} />
        <meta property="og:url" content="http://www.happyhome.com.ar" />
      </Helmet>

      <div className="hero-section fade-in">
        
        <div className="hero-left-col">
          <div className="hero-image">
              <img src={HeroShape1} alt="HappyHome HeroShape1" />
          </div>

          <div>
            <div className="hero-title">
              <h1>Tu tiempo vale</h1>
            </div>
            <div className="hero-image-background">
              <div className="hero-subtitle">
                <h3>Nosotras ¡lo cuidamos!</h3>
              </div>
            </div>

          </div>

          
        </div>

        <div className="hero-right-col">
          <img src={HeroSectionImage} alt="HappyHome HeroImage" />
          
        </div>

      </div>

      <div className="rotative-banner-section fade-in">
        <div className="rotative-banner">
          <div className="line line1">
            <span className="phrase color1">La forma más fácil, rápida y económica de encontrar AYUDA INTEGRAL para tu HOGAR</span>
            <span className="phrase color2">La forma más fácil, rápida y económica de encontrar AYUDA INTEGRAL para tu HOGAR</span>
            <span className="phrase color3">La forma más fácil, rápida y económica de encontrar AYUDA INTEGRAL para tu HOGAR</span>
            <span className="phrase color4">La forma más fácil, rápida y económica de encontrar AYUDA INTEGRAL para tu HOGAR</span>
          </div>
          <div className="line line2">
            <span className="phrase color5">La forma más fácil, rápida y económica de encontrar AYUDA INTEGRAL para tu HOGAR</span>
            <span className="phrase color4">La forma más fácil, rápida y económica de encontrar AYUDA INTEGRAL para tu HOGAR</span>
            <span className="phrase color1">La forma más fácil, rápida y económica de encontrar AYUDA INTEGRAL para tu HOGAR</span>
            <span className="phrase color6">La forma más fácil, rápida y económica de encontrar AYUDA INTEGRAL para tu HOGAR</span>
          </div>
          <div className="line line3">
            <span className="phrase color4">La forma más fácil, rápida y económica de encontrar AYUDA INTEGRAL para tu HOGAR</span>
            <span className="phrase color5">La forma más fácil, rápida y económica de encontrar AYUDA INTEGRAL para tu HOGAR</span>
            <span className="phrase color2">La forma más fácil, rápida y económica de encontrar AYUDA INTEGRAL para tu HOGAR</span>
            <span className="phrase color6">La forma más fácil, rápida y económica de encontrar AYUDA INTEGRAL para tu HOGAR</span>
          </div>
        </div>
      </div>

      <div className="what-we-offer-section fade-in">
        <div className="section-title">
            <h2>¿Qué te ofrecemos?</h2>
            <p>Tenemos la solución y la ayuda integral para el hogar</p>
        </div>
        <div className="what-we-offer">
          <div className="what-we-offer-cards">

            <div className="what-we-offer-card">
              <div>
                <img src={WhatWeOfferEmpleadaDomesticaCircleImage} alt="HappyHomeWhatWeOfferIcon" />
              </div>
              <div className="what-we-offer-card-description">
                <h5>Empleada doméstica</h5>
                <p>Encontrá ayuda en el hogar para realizar tareas como limpieza, lavandería y organización del hogar</p>
              </div>
            </div>

            <div className="what-we-offer-card">
              <div>
                <img src={WhatWeOfferNineraCircleImage} alt="HappyHomeWhatWeOfferIcon" />
              </div>
              <div className="what-we-offer-card-description">
                <h5>Niñera</h5>
                <p>Descubrí cuidadores dedicados y confiables para cuidar de tus hijos con dedicación y atención</p>
              </div>
            </div>

            <div className="what-we-offer-card">
              <div>
                <img src={WhatWeOfferCuidadorPersonasMayoresCircleImage} alt="HappyHomeWhatWeOfferIcon" />
              </div>
              <div className="what-we-offer-card-description">
                <h5>Cuidador/a de personas mayores</h5>
                <p>Encontrá cuidadores profesionales y compasivos para brindar apoyo y compañía a tus seres queridos mayores, garantizando su comodidad y seguridad</p>
              </div>
            </div>

            <div className="what-we-offer-card">
              <div>
                <img src={WhatWeOfferCuidadorMascotaCircleImage} alt="HappyHomeWhatWeOfferIcon" />
              </div>
              <div className="what-we-offer-card-description">
                <h5>Cuidador/a de mascotas</h5>
                <p>Encontrá amantes de los animales que cuidarán de tus mascotas con cariño y atención, proporcionándoles paseos, alimentación y cuidados generales.</p>
              </div>
            </div>

            <div className="what-we-offer-card">
              <div>
                <img src={WhatWeOfferCocineroCircleImage} alt="HappyHomeWhatWeOfferIcon" />
              </div>
              <div className="what-we-offer-card-description">
                <h5>Cocinera/o</h5>
                <p>Descubrí personas talentosas que preparan deliciosas comidas para toda la familia, adaptadas a tus preferencias y necesidades dietéticas</p>
              </div>
            </div>

          </div>
        </div>

      </div>

      <div className="what-do-you-need-section fade-in">
        <div className="section-title">
          <h2>¿Qué estas necesitando?</h2>
          <p>Tu solución a tus necesidades: ¡Estamos listos para ayudarte!</p>
        </div>
        <div className="what-do-you-need-cards">
         
          <div className="what-do-you-need-card">
      
              <div className="what-do-you-need-card-image">
                <img src={WhatDoYouNeedBuscasEmpleadaImage} alt="HappyHomeWhatDoYouNeedImage" />
              </div>
              <div className="what-do-you-need-card-detail">
                <h5>Buscas empleada/o?</h5>
                <p>INSCRIBITÉ y por $35,000 accede a toda la base y CONVERSÁ con todos los perfiles que coincidan con tus necesidades.</p>
                <button onClick={() => setSignUpVisible(true)} className="primary-btn">Registrarme</button>
              </div>

          </div>

          <div className="what-do-you-need-card">
      
            <div className="what-do-you-need-card-image">
              <img src={WhatDoYouNeedBuscasTrabajoImage} alt="HappyHomeWhatDoYouNeedIcon" />
            </div>
            <div className="what-do-you-need-card-detail">
              <h5>Buscas trabajo?</h5>
              <p>Regístrate y accede GRATUITAMENTE a todas las ofertas laborales del país. Crea tu CV y chatea con las familias para postularte.</p>
              <button onClick={() => setSignUpVisible(true)} className="primary-btn">Registrarme</button>
            </div>
          </div>

        </div>
      </div>

      <div className="about-happy-home fade-in">

        <div className="about-happy-home-col">
          <div className="about-happy-home-image">
            <img src={AboutHappyHomeImage} alt="HappyHomeWhatDoYouNeedIcon" />
          </div>
        </div>
        
        <div className="about-happy-home-col">
          <div className="about-happy-home-text">
            <h2>SOBRE HAPPY HOME</h2>
            <p>En HAPPY HOME, entendemos las complejidades de la búsqueda laboral, especialmente cuando se trata de cuidado de niños, empleadas domésticas y cuidadores de personas mayores. Nuestra historia se origina en la experiencia personal de una de nuestras fundadoras, quien enfrentó meses de búsqueda para encontrar una niñera después de dar la bienvenida a su bebé. En ese proceso, identificamos una necesidad crucial en el mercado laboral y decidimos crear HAPPY HOME, una plataforma web diseñada para conectar de manera eficiente a empleadores y empleados en el ámbito del cuidado y asistencia.</p>
          </div>
        </div>
        
      </div>

      <div className="why-choose-happy-home fade-in">
        <div className="section-title">
          <h2>¿Por qué elegirnos?</h2>
          <p>El lugar donde las oportunidades laborales se hacen realidad</p>
        </div>

        <div className="why-choose-happy-home-cards">
          <div className="why-choose-happy-home-card">
            <div className="why-choose-us-image">
              <img src={WhyChooseUsVector1} alt="HappyHomeWhyChooseUsVector" />
            </div>
            <div className="why-choose-us-description">
              <p>Cero costo para el empleado. HH es completamente gratuito para los empleadores.</p>
            </div>
          </div>

          <div className="why-choose-happy-home-card">
            <div className="why-choose-us-image">
              <img src={WhyChooseUsVector2} alt="HappyHomeWhyChooseUsVector" />
            </div>
            <div className="why-choose-us-description">
            <p>Interacción directa entre empleadores y los candidatos.</p>
            </div>
          </div>

          <div className="why-choose-happy-home-card">
            <div className="why-choose-us-image">
              <img src={WhyChooseUsVector3} alt="HappyHomeWhyChooseUsVector" />
            </div>
            <div className="why-choose-us-description">
            <p>Plataforma para la solución integral para la busqueda laboral en el hogar.</p>
            </div>
          </div>

          <div className="why-choose-happy-home-card">
            <div className="why-choose-us-image">
              <img src={WhyChooseUsVector4} alt="HappyHomeWhyChooseUsVector" />
            </div>
            <div className="why-choose-us-description">
            <p>Económica, fácil de usar, rápida y  eficiente: No necesitas pagar sumas excesivas a consultoras.</p>
            </div>
          </div>

        </div>

      
      </div>

    </div>
  );
};

export default HomePage;
