import React, { useState, useContext } from 'react';
import checkIcon from '../../../images/Vectors/HappyHome_CheckIcon.svg';
import crossIcon from '../../../images/Vectors/HappyHome_CrossIcon.svg';
import AuthContext from '../../../base/components/AuthProvider';

const UserProfileVisibilityToggle = ({ userId, initialValue, onUpdateVisibility }) => {
  const { authTokens } = useContext(AuthContext);
  const [visible, setVisible] = useState(initialValue);

  const toggleVisibility = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/admin/user/${userId}/toggle-profile-visibility/`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authTokens.access}`, 
        },
        body: JSON.stringify({ visible: !visible }),
      });
      if (response.ok) {
        setVisible(!visible);
        onUpdateVisibility(userId, !visible);
      } else {
        console.error('Error al cambiar la visibilidad del usuario:', response.statusText);
      }
    } catch (error) {
      console.error('Error al cambiar la visibilidad del usuario:', error.message);
    }
  };

  return (
    <div className="user-profile-visibility-toggle">
      <div className="filter-option-icon" onClick={toggleVisibility} style={{ cursor: 'pointer' }}>
        {visible ? (
          <img src={checkIcon} alt="Check Icon" className="icon-svg" />
        ) : (
          <img src={crossIcon} alt="Cross Icon" className="icon-svg" />
        )}
      </div>
    </div>
  );
};

export default UserProfileVisibilityToggle;
