import React, { useState, useEffect, useContext } from 'react';
import AuthContext from '../../AuthProvider';
import WorkerProfileIntroductionUpdate from './WorkerProfileIntroductionUpdate';
import LoadingSpinner from '../../../../common/components/LoadingSpinner';

const WorkerProfileIntroduction = () => {
  const { userData, authTokens } = useContext(AuthContext);
  const [introduction, setIntroduction] = useState('');
  const [isUpdating, setIsUpdating] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);

  useEffect(() => {
    const fetchIntroduction = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/worker-profile/${userData.profile_id}/introduction/get/`, {
          headers: {
            'Authorization': `Bearer ${authTokens?.access}`
          }
        });
        if (response.ok) {
          const data = await response.json();
          setIntroduction(data.introduction);
          setDataLoaded(true);
        } else {
          console.error('Error fetching introduction:', response.statusText);
        }
      } catch (error) {
        console.error('Error fetching introduction:', error.message);
      }
    };

    fetchIntroduction();
  }, [userData.profile_id, authTokens?.access]);

  const onUpdate = (updatedIntroduction) => {
    setIntroduction(updatedIntroduction);
    setIsUpdating(false);
  };

  const handleUpdateClick = () => {
    setIsUpdating(true);
  };

  return (
    <div className="profile-edit-section">
      <h2 className="profile-edit-section-title">Preséntate</h2>
      <div className="profile-edit-section-content">
        {!dataLoaded ? (
          <LoadingSpinner />
        ) : introduction ? (
          <p>{introduction}</p>
        ) : (
          <p className="empty-message">La <strong>presentación</strong> es obligatoria para que tu perfil sea visible y los empleadores te contacten.</p>
        )}
      </div>
      {isUpdating && <WorkerProfileIntroductionUpdate introduction={introduction} onUpdate={onUpdate} onCancel={() => setIsUpdating(false)} />}
      {dataLoaded && <button className="primary-btn" onClick={handleUpdateClick}>Modificar</button>}
    </div>
  );
};

export default WorkerProfileIntroduction;
