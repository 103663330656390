import React, { useState } from 'react';
import AdminPanelMembershipsList from '../components/AdminPanelMembership/AdminPanelMembershipsList';
import AdminPanelMembershipsSearchBar from '../components/AdminPanelMembership/AdminPanelMembershipsSearchBar';
import AdminPanelMembershipTypesList from '../components/AdminPanelMembership/AdminPanelMembershipTypesList';

const AdminPanelMembershipsPage = () => {
  const [searchTerm, setSearchTerm] = useState('');

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  return (
    <div className="admin-panel-memberships-page">
      <div className="admin-panel-intro">
        <h2>Membresias</h2>
      </div>
      <div className="admin-panel-data">
        <AdminPanelMembershipTypesList />
        <AdminPanelMembershipsSearchBar searchTerm={searchTerm} handleSearchChange={handleSearchChange} />
        <AdminPanelMembershipsList searchTerm={searchTerm} />
      </div>
    </div>
  );
};

export default AdminPanelMembershipsPage;
