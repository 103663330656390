import React from 'react';
import DefaultProfileImage from '../../../images/ProfilePicture/HH_ProfileDefaultImage.png';

export const DateSeparator = ({ date }) => (
  <div className="date-separator">
    <span>{date}</span>
  </div>
);

export const formatDate = (date) => {
  const today = new Date();
  const messageDate = new Date(date);

  if (
    messageDate.getDate() === today.getDate() &&
    messageDate.getMonth() === today.getMonth() &&
    messageDate.getFullYear() === today.getFullYear()
  ) {
    return 'Hoy';
  }

  const yesterday = new Date();
  yesterday.setDate(today.getDate() - 1);

  if (
    messageDate.getDate() === yesterday.getDate() &&
    messageDate.getMonth() === yesterday.getMonth() &&
    messageDate.getFullYear() === yesterday.getFullYear()
  ) {
    return 'Ayer';
  }

  const day = messageDate.getDate().toString().padStart(2, '0');
  const month = (messageDate.getMonth() + 1).toString().padStart(2, '0');
  const year = messageDate.getFullYear();

  return `${day}/${month}/${year}`;
};

export const getConversationPartner = (conversation, currentUser) => {
  if (!conversation) return { name: 'Unknown', profileImage: DefaultProfileImage, profileType: '', profileId: '' };

  if (conversation.worker.user.id === currentUser.id) {
    return {
      name: conversation.parent.user.first_name,
      profileImage: conversation.parent.user.profile_image || DefaultProfileImage,
      profileType: 'parent-profile',
      profileId: conversation.parent.user.id,
    };
  } else if (conversation.parent.user.id === currentUser.id) {
    return {
      name: conversation.worker.user.first_name,
      profileImage: conversation.worker.user.profile_image || DefaultProfileImage,
      profileType: 'worker-profile',
      profileId: conversation.worker.user.id,
    };
  }

  return { name: 'Unknown', profileImage: DefaultProfileImage, profileType: '', profileId: '' };
};

export const scrollToBottom = (messagesContainerRef) => {
  if (messagesContainerRef.current) {
    messagesContainerRef.current.scrollTo({
      top: messagesContainerRef.current.scrollHeight,
      behavior: 'smooth',
    });
  }
};

export const handleProfileClick = (getConversationPartner, navigate) => {
  if (getConversationPartner.profileType === 'worker-profile') {
    navigate(`/${getConversationPartner.profileType}/${getConversationPartner.profileId}/`);
  }
};
