import React from 'react';
import { useNavigate } from 'react-router-dom';

const ActiveMembershipRequiredPopup = () => {
  const navigate = useNavigate();
  return (
    <div className="empty-page">
      <div className="popup-background">
        <div className="popup">
          <div className="popup-content">
            <div className="popup-intro">
              <h2>Membresía inactiva</h2>
              <div className="popup-intro-description">
                <p>Para acceder a esta funcionalidad, necesitas tener una membresía activa. Puedes visitar la página de "Membresias" y adquirir una. En caso de que ya tengas pero se encuentra inactiva, puedes visitar la página de "Mi Cuenta" para reactivarla.</p>
              </div>
            </div>
            <div className="buttons-container">
              <button className="primary-btn" type="button" onClick={() => navigate('/memberships')}>Membresias</button>
              <button className="secondary-btn" type="button" onClick={() => navigate('/user-account')}>Mi cuenta</button>
            </div>
          </div>
        </div>
      </div>
    </div>

  );
};

export default ActiveMembershipRequiredPopup;
