import React, { useEffect, useState } from 'react';
import LoadingSpinner from '../../../common/components/LoadingSpinner';


const WorkerProfileExperienceView = ({ profileId }) => {
  const [experiences, setExperiences] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchExperiences = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/worker-profile/${profileId}/experiences/get-all/`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json'
          },
        });

        if (response.ok) {
          const workerExperiences = await response.json();
          setExperiences(workerExperiences);
        } else {
          console.error('Failed to fetch worker experiences:', response.statusText);
        }
      } catch (error) {
        console.error('Error fetching worker experiences:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchExperiences();
  }, [profileId]);

  return (
    <div className='profile-view-data-section'>
      <div className="profile-view-data-section experience">
        <div className="profile-view-data-section-intro">
          <h2>Experiencia laboral</h2>
        </div>
        {isLoading ? (
          <LoadingSpinner />
        ) : experiences.length > 0 ? (
          <ul>
            {experiences.map((experience, index) => (
              <li key={index}>
                <p><strong>Título: </strong>{experience.title}</p>
                <p><strong>Tareas: </strong>{experience.description}</p>
                <p><strong>Referencia: </strong>{experience.reference}</p>
                <p><strong>Trabajando actualmente:</strong> {experience.currently_working ? 'Sí' : 'No'}</p>
              </li>
            ))}
          </ul>
        ) : (
          <p>No hay experiencias registradas.</p>
        )}
      </div>
    </div>
  );
};

export default WorkerProfileExperienceView;
